import React from 'react';
import { Coms } from '../../../../../../../types/Types';
import toast from 'react-hot-toast';

interface ComsTileModalProps {
  com: Coms;
  comModal: boolean;
  handleComTileClick: () => void;
}

const ComsTileModal: React.FC<ComsTileModalProps> = ({ com, comModal, handleComTileClick }) => {
  if (!comModal) return null;


  return (
    <div className=" smallModal">

      <div className="modalHeader">
        <h2 className="modaltext">{com.com_subject}</h2>
      </div>

      <div className="modalContent">
        <p className="text-gray-700 mt-2">From: {com.com_sender}</p>
        <p className="text-gray-700 mt-2">Date: {new Date(com.com_date).toDateString()}</p>
        <p className="text-gray-700 mt-2">Message: {com.com_message}</p>
        <p className="text-gray-700 mt-2">Type: {com.com_type}</p>
      </div>

      <div className="modalBtnFooter">
        <button onClick={handleComTileClick} className="pageBtncancel">
          Close
        </button>
      </div>

    </div>
  );
};

export default ComsTileModal;
