import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SubscriptionUtils from "../../../../../../utils/SubscriptionUtils";
import { Collections } from "../../../../../../types/Types";
import SubscriptionModal from '../modals/subscriptionModal';

import CreateSubscriptionModal from "../modals/CreateSubscriptionModal";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../stores/AuthStore";
import useSubscriptionsStore from "../../../../../../stores/SubscribersStore";
import { useFetchData } from "../../../../../../hooks/useFetchData";

interface Subscription {
  subscription_id: number;
  subscription_name: string;
  subscription_desc: string;
  subscription_period: string;
}

const SubscriptionTable: React.FC = () => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { subscriptionsData, fetchSubscriptionsData, updateSubscriptionsData } = useSubscriptionsStore();
  // Stores
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Subscription | undefined>(undefined);
  const [createSubscriptionModal, setCreateSubscriptionModal] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Subscription | null>(null);
  // Data Fetch
  useFetchData(fetchSubscriptionsData);

  const handleCreateSubscriptionModal = () => {
    setCreateSubscriptionModal(!createSubscriptionModal)
  }

  const handleCollectionModal = (rowData: Subscription) => {
    setSelectedRow(rowData);
    setSubscriptionModal(!subscriptionModal);
  };

  // Open delete dialog
  const openDeleteDialog = (rowData: Subscription) => {
    setRowToDelete(rowData);
    setDeleteDialogOpen(true);
  };

  const handleSubscriptionDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (rowToDelete && sessionUser) {
      try {
        await SubscriptionUtils.DeleteSubscription(sessionUser, rowToDelete.subscription_id);
        updateSubscriptionsData(sessionUser);
        toast.success("Subscription deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete subscription");
      }
    }
  };

  const columns = useMemo<MRT_ColumnDef<Collections>[]>(
    () => [
      {
        accessorKey: "subscription_name",
        header: "Subscription Name",
        size: 100,
      },
      {
        accessorKey: "subscription_desc",
        header: "Description",
        size: 200,
      },
      {
        accessorKey: "subscription_period",
        header: "Subscription Period",
        size: 100,
      },
    ],
    []
  );

  const handleExportRows = (rows: MRT_Row<Collections>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('mrt-pdf-example.pdf');
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={subscriptionsData}
        enableRowActions
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleCollectionModal(row.original),
          sx: {
            cursor: "pointer",
          },
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="flex">
            <Button
              disabled={[].length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}>
              Export As PDF
            </Button>
            <div className="flex">
              <button className="pageBtngood" onClick={handleCreateSubscriptionModal}>
                Add Subscription
              </button>
            </div>

          </div>
        )}
      />

      {selectedRow && (
        <div
          className={`${subscriptionModal ? "modalBackground" : "hidden"}`}>
          <SubscriptionModal
            setSubscriptionModal={setSubscriptionModal}
            selectedRow={selectedRow}
          />
        </div>
      )}

      {createSubscriptionModal && (
        <div className="absolute top-0 flex items-center justify-center left-0 z-[100] w-full h-full bg-black bg-opacity-50"> { /* Background overlay */}
          <CreateSubscriptionModal setCreateSubscriptionModal={setCreateSubscriptionModal} />
        </div>
      )}

      {/* Render Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleSubscriptionDelete}
        title="Delete Marshal?"
        description={`Are you sure you want to delete ${rowToDelete?.subscription_name}?`}
      />

    </>
  );
};

export default SubscriptionTable;
