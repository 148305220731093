import { create } from "zustand";
import { SessionUser, Staff } from "../types/Types";
import StaffUtils from "../utils/StaffUtils";

interface UserStoreType {
  staffData: Staff[];
  loading: boolean;
  error: string | null;
  fetchStaffData: (sessionUser: SessionUser) => Promise<void>;
  updateStaffData(sessionUser: SessionUser): unknown;
}

// ----- Staff Store ----- //

const useStaffStore = create<UserStoreType>((set, get) => ({
  staffData: [],
  loading: false,
  error: null,

  fetchStaffData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateStaffData(sessionUser);
  },

  updateStaffData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await StaffUtils.GetStaff(sessionUser);
      set({ staffData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch staff data",
        loading: false
      });
    }
  }

}));

export default useStaffStore;