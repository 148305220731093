import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Collections, Collectors, Coms, Driver, DriverDocs, Duty, Owner, RankData, Staff, Vehicle } from '../../../types/Types';
import { useSession } from '../../../components/SessionContext';
import { useNavigate } from 'react-router-dom';
// UTILS
import OwnerUtils from '../../../utils/OwnerUtils';
import EventsUtils from '../../../utils/EventsUtils';
import VehicleUtils from '../../../utils/VehicleUtils';
import CollectionUtils from '../../../utils/CollectionUtils';
import SubscriptionUtils from '../../../utils/SubscriptionUtils';
import DriverUtils from '../../../utils/DriverUtils';
import SquadUtils from '../../../utils/SquadUtils';
import MarshalUtils from '../../../utils/MarshalUtils';
import ReportUtils from '../../../utils/ReportUtils';
import FileUtils from '../../../utils/FileUtils';
import RankUtils from '../../../utils/RankUtils';
import CommunicationUtils from '../../../utils/CommunicationUtils';
import DutyUtils from '../../../utils/DutyUtils';
import DcUtils from '../../../utils/DcUtils';
import NotificationUtils from '../../../utils/NotificationUtils';

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  association: number;
  accessToken: string;
}

export interface OwnerContextType {
  sessionUser: any;
  ownerData: Owner[]; fetchOwnerData: () => void;
  vehicleData: Vehicle[]; fetchVehicleData: () => void;
  driverData: Driver[]; fetchDriverData: () => void;
  eventData: Event[]; fetchEventData: () => void;
  collectionsData: Collections[]; fetchCollectionsData: () => void;
  collectorsData: Collectors[]; fetchCollectorsData: () => void;
  rankData: RankData[]; fetchRankData: () => void;
  dutyData: Duty[]; fetchDutyData: () => void;
  comsData: Coms[]; fetchComsData: () => void;
  userData: Owner[]; fetchUserData: () => void;
  marshalData: any[]; fetchMarshalData: () => void;
  squadCarData: any[]; fetchSquadCarData: () => void;
  squadMembersData: any[]; fetchSquadMembersData: () => void;
  ownerVehicles: any[]; fetchOwnerVehicleCountData: () => void;
  subscriptionData: any[]; fetchSubscriptionData: () => void;
  subscribersData: any[]; fetchSubscribersData: () => void;
  subscriptionPlan: any; fetchSubscriptionPlan: () => void;
  contactData: any[]; fetchContactData: () => void;
  isCollapsed: boolean; setIsCollapsed: (value: boolean) => void;
  dcData: any[]; fetchDcData: () => void;
  driverDocs: any[]; fetchDriverDocs: () => void;
  vehicleDocs: any[]; fetchVehicleDocs: () => void;
  ownerDocs: any[]; fetchOwnerDocs: () => void;
  userDocs: any[]; fetchUserDocs: () => void;
  notificationData: any[]; fetchNotificationData: () => void;
  isDarkMode: boolean; toggleDarkMode: () => void;
  activeVehicles: number; activeDrivers: number;
  shceduledComs: any, fetchScheduledComs: () => void;
  dutyHistory: any, fetchDutyHistory: () => void;
  refresh: () => void;
}

const OwnerContext = createContext<OwnerContextType | undefined>(undefined);

export const useOwner = () => {
  const context = useContext(OwnerContext);
  if (!context) {
    throw new Error('useOwner must be used within a OwnerProvider');
  }
  return context;
}

export const OwnerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ownerData, setOwnerData] = useState<Owner[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [vehicleData, setVehicleData] = useState<Vehicle[]>([]);
  const [driverData, setDriverData] = useState<Driver[]>([]);
  const [eventData, setEventData] = useState<Event[]>([]);
  const [collectionsData, setCollectionsData] = useState<Collections[]>([]);
  const [collectorsData, setCollectorsData] = useState<Collectors[]>([]);
  const [rankData, setRankData] = useState<RankData[]>([]);
  const [marshalData, setMarshalData] = useState<any[]>([]);
  const [dcData, setDcData] = useState<any[]>([]);
  const [squadCarData, setSquadCarData] = useState<any[]>([]);
  const [squadMembersData, setSquadMembersData] = useState<any[]>([]);
  const [ownerVehicles, setOwnerVehicles] = useState<any[]>([]);
  const [subscriptionData, setSubscriptionData] = useState<any[]>([]);
  const [subscribersData, setSubscribersData] = useState<any[]>([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>([]);
  const [activeVehicles, setActiveVehicles] = useState<number>(0);
  const [activeDrivers, setActiveDrivers] = useState<number>(0);
  const [contactData, setContactData] = useState<any[]>([]);
  const [dutyData, setDutyData] = useState<Duty[]>([]);
  const [comsData, setComsData] = useState<Coms[]>([]);
  const [userData, setUserData] = useState<Owner[]>([]);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [driverDocs, setDriverDocs] = useState<DriverDocs[]>([]);
  const [vehicleDocs, setVehicleDocs] = useState<any[]>([]);
  const [ownerDocs, setOwnerDocs] = useState<any[]>([]);
  const [staffData, setStaffData] = useState<Staff[]>([])
  const [shceduledComs, setShceduledComs] = useState<any[]>([])
  const [userDocs, setUserDocs] = useState<any[]>([])
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [dutyHistory, setDutyHistory] = useState<any[]>([]);

  // Access session data
  const { user: sessionUser } = useSession(); // Extract session user data
  const navigate = useNavigate();

  const toggleDarkMode = () => {
    // Toggle the current dark mode state
    setIsDarkMode(prev => {
      const newMode = !prev;

      // Toggle the 'dark' class on the HTML element
      document.documentElement.classList.toggle('dark', newMode);

      // Save the new mode to localStorage for persistence
      localStorage.setItem('isDarkMode', newMode?.toString());

      return newMode;
    });
  };

  // API FETCHES //
  const fetchOwnerData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await OwnerUtils.FetchOwnerData(sessionUser);
        console.log('owner Data fetched ')
        setOwnerData(data);
        localStorage.setItem('ownerData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching owner data:', error);
      }
    }
  }, [sessionUser]);

  const fetchEventData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await EventsUtils.FetchEventsData(sessionUser);
        setEventData(data);
        localStorage.setItem('eventData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    }
  }, [sessionUser]);

  const fetchVehicleData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await VehicleUtils.FetchVehicles(sessionUser);
        setVehicleData(data);
        localStorage.setItem('vehicleData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    }
  }, [sessionUser]);

  const fetchDriverData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await DriverUtils.FetchDriverData(sessionUser);
        setDriverData(data);
        localStorage.setItem('driverData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching driver data:', error);
      }
    }
  }, [sessionUser]);

  const fetchCollectionsData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await CollectionUtils.FetchCollectionData(sessionUser);
        setCollectionsData(data);
        localStorage.setItem('collectionsData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching collections data:', error);
      }
    }
  }, [sessionUser]);

  const fetchCollectorsData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await CollectionUtils.FetchCollectorsData(sessionUser);
        setCollectorsData(data);
        localStorage.setItem('collectorsData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching collectors data:', error);
      }
    }
  }, [sessionUser]);

  const fetchMarshalData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await MarshalUtils.GetMarshals(sessionUser);
        setMarshalData(data);
        localStorage.setItem('marshalData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching marshals data:', error);
      }
    }
  }, [sessionUser]);

  const fetchSquadCarData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await SquadUtils.GetSquadCars(sessionUser);
        setSquadCarData(data);
        localStorage.setItem('squadCarData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching squad cars data:', error);
      }
    }
  }, [sessionUser]);

  const fetchSquadMembersData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await SquadUtils.GetSquadMembers(sessionUser);
        setSquadMembersData(data);
        localStorage.setItem('squadMemberData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching squad cars data:', error);
      }
    }
  }, [sessionUser]);

  const fetchOwnerVehicleCountData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await OwnerUtils.OwnerVehicleCount(sessionUser);
        setOwnerVehicles(data);
        localStorage.setItem('ownerVehicles', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching owner Vehicles data:', error);
      }
    }
  }, [sessionUser]);

  const fetchSubscriptionData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await SubscriptionUtils.FetchSubscriptionData(sessionUser);
        setSubscriptionData(data);
        localStorage.setItem('subscriptionData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching owner subsription Data:', error);
      }
    }
  }, [sessionUser]);

  const fetchSubscribersData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await SubscriptionUtils.FetchSubscribersData(sessionUser);
        setSubscribersData(data);
        localStorage.setItem('subscribersData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching owner subsription Data:', error);
      }
    }
  }, [sessionUser]);

  const fetchSubscriptionPlan = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await SubscriptionUtils.FetchSubscriptionPlan(sessionUser);
        setSubscriptionPlan(data);
      } catch (error) {
        console.error('Error fetching owner subsription Data:', error);
      }
    }
  }, [sessionUser]);

  const fetchDriverDocs = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await FileUtils.FetchDriverDocs(sessionUser);
        setDriverDocs(data);
      } catch (error) {
        console.error('Error fetching driver Docs:', error);
      }
    }
  }, [sessionUser]);

  const fetchContactData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await CommunicationUtils.findEmails(sessionUser);
        setContactData(data);
        localStorage.setItem('contactData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching email data:', error);
      }
    }
  }, [sessionUser]);

  const fetchRankData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await RankUtils.FetchRankData(sessionUser);
        setRankData(data);
        localStorage.setItem('rankData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching rank data:', error);
      }
    }
  }, [sessionUser]);

  const fetchDutyData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await DutyUtils.FetchDutyData(sessionUser);
        setDutyData(data);
        localStorage.setItem('dutyData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching duty data:', error);
      }
    }
  }, [sessionUser]);

  const fetchDcData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await DcUtils.FetchDcData(sessionUser);
        setDcData(data);
        localStorage.setItem('dcData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching dc data:', error);
      }
    }
  }, [sessionUser]);

  const fetchVehicleDocs = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await FileUtils.FetchVehicelDocs(sessionUser);
        console.log("Vehicle Docs Fetched")
        localStorage.setItem('vehicleDocs', JSON.stringify(data));
        setVehicleDocs(data);
      } catch (error) {
        console.error('Error fetching vehicle docs:', error);
      }
    }
  }, [sessionUser]);


  const fetchComsData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await CommunicationUtils.fetchComs(sessionUser);
        localStorage.setItem('comsData', JSON.stringify(data));
        setComsData(data);
      } catch (error) {
        console.error('Error fetching Coms Data:', error);
      }
    }
  }, [sessionUser]);

  const fetchUserData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await OwnerUtils.FetchUserData(sessionUser);
        setUserData(data);
        localStorage.setItem('userData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  }, [sessionUser]);

  const fetchUserDocs = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await FileUtils.FetchUserDocs(sessionUser);
        setUserDocs(data);
        localStorage.setItem('userDocs', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching owner docs:', error);
      }
    }
  }, [sessionUser]);

  const fetchOwnerDocs = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await FileUtils.FetchOwnerDocs(sessionUser);
        setOwnerDocs(data);
        localStorage.setItem('ownerDocs', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching owner docs:', error);
      }
    }
  }, [sessionUser]);

  const fetchNotificationData = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await NotificationUtils.FetchNotificationData(sessionUser);
        localStorage.setItem('notificationData', JSON.stringify(data));
        setNotificationData(data);
      } catch (error) {
        console.error('Error fetching notification data:', error);
      }
    }
  }, [sessionUser]);



  const fetchScheduledComs = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await CommunicationUtils.fetchScheduledComs(sessionUser);
        localStorage.setItem('scheduledComs', JSON.stringify(data));
        setShceduledComs(data);
      } catch (error) {
        console.log("error fetching scheduled coms", error)
      }
    }
  }, [sessionUser]);

  const fetchDutyHistory = useCallback(async () => {
    if (sessionUser) {
      try {
        const data = await DutyUtils.FetchDutyHistory(sessionUser);
        localStorage.setItem('dutyHistory', JSON.stringify(data));
        setDutyHistory(data);
      } catch (error) {
        console.log("error fetching duty history", error)
      }
    }
  }, [sessionUser]);

  const getActiveVehicles = useCallback(async (vehicleData: Vehicle[]) => {
    if (vehicleData) {
      const activeVehicleCount = ReportUtils.ActiveVehicles(vehicleData);
      setActiveVehicles(activeVehicleCount);
      localStorage.setItem('activeVehicles', JSON.stringify(activeVehicleCount));
    }
  }, []);

  const getActiveDrivers = useCallback(async (driverData: Driver[]) => {
    if (driverData) {
      const activeDriverCount = ReportUtils.ActiveDrivers(driverData);
      setActiveDrivers(activeDriverCount);
      localStorage.setItem('activeDrivers', JSON.stringify(activeDriverCount));
    }
  }, []);

  useEffect(() => {
    if (vehicleData?.length > 0) {
      getActiveVehicles(vehicleData);
    }
  }, [vehicleData, getActiveVehicles]);

  useEffect(() => {
    if (driverData?.length > 0) {
      getActiveDrivers(driverData);
    }
  }, [driverData, getActiveDrivers]);

  useEffect(() => {
    if (sessionUser) {
      const cachedOwnerData = localStorage.getItem('ownerData');
      const cachedVehicleData = localStorage.getItem('vehicleData');
      const cachedEventData = localStorage.getItem('eventData');
      const cachedDriverData = localStorage.getItem('driverData');
      const cachedCollectionsData = localStorage.getItem('collectionsData');
      const cachedCollectorsData = localStorage.getItem('collectorsData');
      const cachedMarshalData = localStorage.getItem('marshalData');
      const cachedSquadCarData = localStorage.getItem('squadCarData');
      const cachedSquadMembersData = localStorage.getItem('squadMemberData');
      const cachedSubscriptionData = localStorage.getItem('subscriptionData');
      const cachedSubscribersData = localStorage.getItem('subscribersData');
      const cachedEmailData = localStorage.getItem('contactData');
      const cachedDcData = localStorage.getItem('dcData');
      const cahcedUserData = localStorage.getItem('userData');
      const cahcedOwnerDocs = localStorage.getItem('ownerDocs');
      const cahcedOwnerVehicles = localStorage.getItem('ownerVehicles');
      const cahcedVehicleDocs = localStorage.getItem('vehicleDocs');
      const cachedUserDocs = localStorage.getItem('userDocs');
      const cachedDutyData = localStorage.getItem('dutyData');
      const cachedRankData = localStorage.getItem('rankData');
      const cachedComsData = localStorage.getItem('comsData');
      const cachedNotificationData = localStorage.getItem('notificationData');
      const cachedScheduledComs = localStorage.getItem('scheduledComs');
      const cachedDutyHistory = localStorage.getItem('dutyHistory');


      if (cachedOwnerData) {
        try {
          setOwnerData(JSON.parse(cachedOwnerData));
        } catch (error) {
          console.error('Error parsing cached owner data:', error);
          localStorage.removeItem('ownerData'); // Remove invalid data
          setOwnerData([]);
        }
      } else {
        fetchOwnerData();
      };

      if (cachedVehicleData) {
        try {
          setVehicleData(JSON.parse(cachedVehicleData));
          console.log("cached vehicle data")
        } catch (error) {
          console.error('Error parsing cached vehicle data:', error);
          localStorage.removeItem('vehicleData');
          setVehicleData([]);
        }
      } else {
        fetchVehicleData();
        console.log("Fetched From API")
      };

      if (cachedDriverData) {
        try {
          setDriverData(JSON.parse(cachedDriverData));
        } catch (error) {
          console.error('Error parsing cached driver data:', error);
          localStorage.removeItem('driverData');
          setDriverData([]);
        }
      } else {
        fetchDriverData();
      };

      if (cachedEventData) {
        try {
          setEventData(JSON.parse(cachedEventData));
        } catch (error) {
          console.error('Error parsing cached event data:', error);
          localStorage.removeItem('eventData');
          setEventData([])
        }
      } else {
        fetchEventData();
      }

      if (cachedCollectionsData) {
        try {
          setCollectionsData(JSON.parse(cachedCollectionsData));
        } catch (error) {
          console.error('Error parsing cached collections data:', error);
          localStorage.removeItem('collectionsData');
          setCollectionsData([])
        }
      } else {
        fetchCollectionsData();
      }

      if (cachedCollectorsData) {
        try {
          setCollectorsData(JSON.parse(cachedCollectorsData));
        } catch (error) {
          console.error('Error parsing cached collectors data:', error);
          localStorage.removeItem('collectorsData');
          setCollectorsData([])
        }
      } else {
        fetchCollectorsData();
      }

      if (cachedMarshalData) {
        try {
          setMarshalData(JSON.parse(cachedMarshalData));
        } catch (error) {
          console.error('Error parsing cached marshal data:', error);
          localStorage.removeItem('marshalData');
          setMarshalData([])
        }
      } else {
        fetchMarshalData();
      }

      if (cachedSquadCarData) {
        try {
          setSquadCarData(JSON.parse(cachedSquadCarData));
        } catch (error) {
          console.error('Error parsing cached squad car data:', error);
          localStorage.removeItem('squadCarData');
          setSquadCarData([])
        }
      } else {
        fetchSquadCarData();
      }

      if (cachedSquadMembersData) {
        try {
          setSquadMembersData(JSON.parse(cachedSquadMembersData));
        } catch (error) {
          console.error('Error parsing cached squad members data:', error);
          localStorage.removeItem('squadMemberData');
          setSquadMembersData([]);
        }
      } else {
        fetchSquadMembersData();
      }

      if (cachedSubscriptionData) {
        try {
          setSubscriptionData(JSON.parse(cachedSubscriptionData));
        } catch (error) {
          console.error('Error parsing cached subscription data:', error);
          localStorage.removeItem('subscriptionData');
          setSubscriptionData([]);
        }
      } else {
        fetchSubscriptionData();
      }

      if (cachedSubscribersData) {
        try {
          setSubscribersData(JSON.parse(cachedSubscribersData));
        } catch (error) {
          console.error('Error parsing cached subscribers data:', error);
          localStorage.removeItem('subscribersData');
          setSubscribersData([]);
        }
      } else {
        fetchSubscribersData();
      }

      if (cachedEmailData) {
        try {
          setContactData(JSON.parse(cachedEmailData));
        } catch (error) {
          console.error('Error parsing cached email data:', error);
          localStorage.removeItem('contactData');
          setContactData([]);
        }
      } else {
        fetchContactData();
      };

      if (cachedDcData) {
        try {
          setDcData(JSON.parse(cachedDcData));
        } catch (error) {
          console.error('Error parsing cached dc data:', error);
          localStorage.removeItem('dcData');
          setDcData([]);
        }
      } else {
        fetchDcData();
      };

      if (cahcedUserData) {
        try {
          setUserData(JSON.parse(cahcedUserData));
        } catch (error) {
          console.error('Error parsing cached user data:', error);
          localStorage.removeItem('userData');
          setUserData([]);
        }
      } else {
        fetchUserData();
      };

      if (cahcedOwnerDocs) {
        try {
          setOwnerDocs(JSON.parse(cahcedOwnerDocs));
        } catch (error) {
          console.error('Error fetching owner docs:', error);
          localStorage.removeItem('ownerDocs');
        }
      } else {
        fetchOwnerDocs();
      };

      if (cahcedOwnerVehicles) {
        try {
          setOwnerVehicles(JSON.parse(cahcedOwnerVehicles));
        } catch (error) {
          console.error('Error fetching owner Vehicles data:', error);
          localStorage.removeItem('ownerVehicles');
        }
      } else {
        fetchOwnerVehicleCountData();
      };

      if (cahcedVehicleDocs) {
        try {
          setVehicleDocs(JSON.parse(cahcedVehicleDocs));
        } catch (error) {
          console.error('Error fetching vehicle docs:', error);
          localStorage.removeItem('vehicleDocs');
        }
      } else {
        fetchVehicleDocs();
      };

      if (cachedUserDocs) {
        try {
          setUserDocs(JSON.parse(cachedUserDocs));
        } catch (error) {
          console.error('Error fetching user docs:', error);
          localStorage.removeItem('userDocs');
        }
      } else {
        fetchUserDocs();
      };

      if (cachedDutyData) {
        try {
          setDutyData(JSON.parse(cachedDutyData));
        } catch (error) {
          console.error('Error parsing cached duty data:', error);
          localStorage.removeItem('dutyData');
        }
      } else {
        fetchDutyData();
      };

      if (cachedRankData) {
        try {
          setRankData(JSON.parse(cachedRankData));
        } catch (error) {
          console.log('cached rank data broken:', error);
          localStorage.removeItem('rankData');
        }
      } else {
        fetchRankData();
      };

      if (cachedComsData) {
        try {
          setComsData(JSON.parse(cachedComsData));
        } catch (error) {
          console.error('Error parsing cached coms data:', error);
          localStorage.removeItem('comsData');
        }
      } else {
        fetchComsData();
      };

      try {
        fetchSubscriptionPlan();
      } catch (error) {
        console.error('Error parsing cached subscription plan:', error);
      };

      // UNCAHCED


      if (cachedScheduledComs) {
        try {
          setShceduledComs(JSON.parse(cachedScheduledComs));
        } catch (error) {
          console.log('Error fetching scheduled coms:' + error);
          localStorage.removeItem('scheduledComs');
        }
      } else {
        fetchScheduledComs();
      };

      if (cachedDutyHistory) {
        try {
          setDutyHistory(JSON.parse(cachedDutyHistory));
        } catch (error) {
          console.log('Error fetching duty history:' + error);
          localStorage.removeItem('dutyHistory');
        }
      } else {
        fetchDutyHistory();
      };


      if (cachedNotificationData) {
        try {
          setNotificationData(JSON.parse(cachedNotificationData));
        } catch (error) {
          console.error('Error parsing cached notification data:', error);
          localStorage.removeItem('notificationData');
        }
      } else {
        fetchNotificationData();
      };


      try {
        fetchDriverDocs();
      } catch (error) {
        console.error('Error fetching driver docs:', error);
      };

      ///--///

      if (sessionUser) {
        const userData = {
          id: sessionUser.id,
          email: sessionUser.email,
          role: sessionUser.role,
          name: sessionUser.name,
          association: sessionUser.association,
          accessToken: sessionUser.accessToken,

        };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
      }

    }
  }, [fetchOwnerData, fetchVehicleData, fetchDriverData, fetchEventData, fetchCollectionsData, fetchCollectorsData, fetchMarshalData, fetchSquadCarData, fetchSquadMembersData, fetchOwnerVehicleCountData, fetchSubscriptionData, fetchSubscribersData, fetchSubscriptionPlan, fetchDriverDocs, fetchContactData, fetchRankData, fetchDutyData, fetchDcData, fetchComsData, fetchUserData, fetchOwnerDocs, fetchUserDocs, fetchNotificationData, fetchVehicleDocs, fetchScheduledComs, fetchDutyHistory, sessionUser]);

  useEffect(() => {
    if (!sessionUser) {
      navigate('/');
      localStorage.clear();

      setOwnerData([]);
      setVehicleData([]);
      setDriverData([]);
      setEventData([]);
      setCollectionsData([]);
      setCollectorsData([]);
      setMarshalData([]);
      setSquadCarData([]);
      setSquadMembersData([]);
      setOwnerVehicles([]);
      setSubscriptionData([]);
      setSubscribersData([]);
      setSubscriptionPlan([]);
      setDriverDocs([]);
      setContactData([]);
      setRankData([]);
      setDutyData([]);
      setDcData([]);
      setComsData([]);
      setUserData([]);
      setOwnerDocs([]);
      setActiveVehicles(0);
      setActiveDrivers(0);
    }
  }, [sessionUser, navigate]);

  const refresh = async () => {
    localStorage.clear();

    fetchOwnerData();
    fetchVehicleData();
    fetchDriverData();
    fetchEventData();
    fetchCollectionsData();
    fetchCollectorsData();
    fetchMarshalData();
    fetchSquadCarData();
    fetchSquadMembersData();
    fetchOwnerVehicleCountData();
    fetchSubscriptionData();
    fetchSubscribersData();
    fetchSubscriptionPlan();
    fetchDriverDocs();
    fetchContactData();
    fetchRankData();
    fetchDutyData();
    fetchDcData();
    fetchComsData();
    fetchUserData();
    fetchOwnerDocs();
    fetchVehicleDocs();
    fetchScheduledComs();
    fetchUserDocs();
    fetchNotificationData();
    fetchDutyHistory();
    console.log("refreshed")
  };

  return (
    <OwnerContext.Provider
      value={{
        sessionUser,
        ownerData, fetchOwnerData,
        vehicleData, fetchVehicleData,
        driverData, fetchDriverData,
        eventData, fetchEventData,
        collectionsData, fetchCollectionsData,
        collectorsData, fetchCollectorsData,
        marshalData, fetchMarshalData,
        squadCarData, fetchSquadCarData,
        squadMembersData, fetchSquadMembersData,
        ownerVehicles, fetchOwnerVehicleCountData,
        subscriptionData, fetchSubscriptionData,
        subscribersData, fetchSubscribersData,
        subscriptionPlan, fetchSubscriptionPlan,
        driverDocs, fetchDriverDocs,
        contactData, fetchContactData,
        activeVehicles, activeDrivers,
        rankData, fetchRankData,
        isCollapsed, setIsCollapsed,
        dutyData, fetchDutyData,
        dcData, fetchDcData,
        comsData, fetchComsData,
        userData, fetchUserData,
        notificationData, fetchNotificationData,
        vehicleDocs, fetchVehicleDocs,
        isDarkMode, toggleDarkMode,
        ownerDocs, fetchOwnerDocs,
        shceduledComs, fetchScheduledComs,
        userDocs, fetchUserDocs,
        dutyHistory, fetchDutyHistory,
        refresh,
      }}>
      {children}
    </OwnerContext.Provider>
  );
}