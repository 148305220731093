import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loadingTaxi from '../assets/images/taxiLine.png';
import checked from '../assets/icons/checked.png';
import warning from '../assets/icons/warning.png';
import toast from 'react-hot-toast';
import DcUtils from '../utils/DcUtils';

export default function HomePage() {
  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState<any>(null);
  const [isVerified, setIsVerified] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  const goHome = () => {
    navigate('/');
  };

  const validateVehicleRegistration = (vehicleReg: string | string[] | undefined) => {
    return vehicleReg && !vehicleReg.includes(' ') && vehicleReg.length >= 8;
  };

  const handleComplaintSubmit = async (event: { preventDefault: () => void; target: any }) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const vehicleReg = formData.get('vehicleReg')?.toString().toUpperCase();

    if (!validateVehicleRegistration(vehicleReg)) {
      toast.error('Please Enter A Valid Vehicle Registration.');
      return;
    }

    setHasSearched(true);
    setLoading(true); // Set loading to true when the request starts

    (async () => {
      try {
        const response = await DcUtils.verifyVehicle(vehicleReg);
        if (response) {
          setVehicleData(response);
          setIsVerified(true);
          toast.success('Vehicle Verified Successfully');
        } else {
          setIsVerified(false);
          setVehicleData(null);
          toast.error('Vehicle not found.');
        }
      } catch (error) {
        console.error(error);
        setIsVerified(false);
        setVehicleData(null);
        toast.error('Failed to verify vehicle.');
      } finally {
        setLoading(false); // Set loading to false when the request completes
      }
    })();
  };

  return (
    <main className="flex flex-col w-full min-h-screen bg-slate-200 items-center">

      {/* HEADER */}
      <header className="p-4 flex justify-between items-center w-full bg-white shadow-md fixed top-0 z-10">
        <div className="text-3xl font-semibold text-slate-700 cursor-pointer" onClick={goHome}>
          Taxi Admin
        </div>
      </header>

      {/* MAIN CONTENT */}
      <div className="flex flex-col w-full p-4 mt-[120px] items-center">
        <section className="flex flex-col h-auto md:flex-row w-full md:w-3/4 lg:w-2/3 items-center bg-white shadow-md rounded-xl p-6">
          <div className="md:w-1/2 p-4">
            <img src={loadingTaxi} alt="Taxi loading" className="w-full h-auto rounded-xl shadow" />
          </div>

          <div className="flex flex-col w-full items-center md:w-1/2 p-6">
            <h3 className="text-2xl md:text-3xl font-bold mb-4">Need to verify a taxi?</h3>
            <p className="text-md md:text-lg mb-6">
              <span className="text-green-700 font-semibold">Verify</span> that a taxi belongs to our database of registered vehicles.
            </p>

            {/* Search Form */}
            <form onSubmit={handleComplaintSubmit} className="flex flex-col items-center gap-4 mb-6 w-full">
              <div className="flex flex-col items-center w-full">
                <label className="font-medium">Vehicle Registration</label>
                <input
                  className="longTextInput md:normalTextInput"
                  type="text"
                  name="vehicleReg"
                  placeholder="Enter Vehicle Registration"
                  required
                />
              </div>
              <hr className='w-[90%] border-[1px] border-slate-400' />

              {loading ? (
                <div className="flex flex-col items-center w-full h-[250px]">
                  <p className="text-xl text-gray-600">Verifying vehicle...</p>
                  <div className="loader mt-4"></div> {/* Add a loader here (e.g., a spinner or loading animation) */}
                </div>
              ) : hasSearched && (
                <div className="flex flex-col items-center w-full h-[250px]">
                  <div className="w-[100px] h-[100px]">
                    <img
                      src={isVerified ? checked : warning}
                      alt={isVerified ? "Verified" : "Not Verified"}
                      className="w-full h-full"
                    />
                  </div>

                  <div>
                    <h3 className={`text-2xl md:text-3xl font-bold mb-4 ${isVerified ? 'text-green-700' : 'text-red-700'}`}>
                      {isVerified ? 'Verified' : 'Not Verified'}
                    </h3>
                  </div>

                  {vehicleData && isVerified && (
                    <div className="w-full px-10">
                      <div className="flex justify-between">
                        <p className="font-semibold">Registration:</p>
                        <p>{vehicleData.vehicleData.vehicle_registration}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="font-semibold">Driver:</p>
                        <p>{vehicleData.driverName} {vehicleData.driverSurname}</p>
                      </div>
                      <div className="flex justify-between">
                        <p className="font-semibold">Association:</p>
                        <p>{vehicleData.associationName}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="flex justify-center">
                <button type="submit" className="homeScreenSignInBtn" disabled={loading}>
                  {loading ? 'Verifying...' : 'Verify'}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>

      <footer className="flex w-full h-[80px] items-center justify-center bg-white mt-auto shadow-md">
        Taxi Admin 2024 © All Rights Reserved
      </footer>
    </main>
  );
}
