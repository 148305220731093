import React from 'react';
import { Collections } from '../../../../../../../types/Types';
import CommonUtils from '../../../../../../../utils/CommonUtils';

interface FinanceTileProps {
  collection: Collections;
  onClick: (collection: Collections) => void;
}

const CollectionTile: React.FC<FinanceTileProps> = ({ collection, onClick }) => {
  const comDate = CommonUtils.formatDateString(collection.collection_end);

  const handleComTileClick = () => {
    onClick(collection);
  };

  const collectionDue = () => {
    const today = new Date();
    if (today > new Date(collection.collection_end)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div onClick={handleComTileClick} className="flex flex-row borderThemed justify-between p-2 rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200">
      <div className="w-[60%]">
        <h3 className="text-md font-bold">{collection.collection_name}</h3>
        <p className="modaltextsmallBold">{collection.collection_desc}</p>
      </div>
      <div className="flex flex-col w-[40%]">
        <p className='modaltextsmall text-center' >Due:</p>
        <p className="modaltextsmallBold text-center">{comDate}</p>
        <p className='modaltextsmall'>Collection Status</p>
        <p className={`${collectionDue() ? 'text-red-500 text-center font-bold text-[9pt]' : 'modaltextsmallBold'}`}> {collectionDue() ? "Overdue " : "Active"}</p>
      </div>
    </div>
  );
};

export default CollectionTile;
