import { Driver } from "@/types/Types";

export default class CommonUtils {

  static GenerateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < 20; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    result = result.slice(0, 5) + '-' + result.slice(5, 10) + '-' + result.slice(10, 20);

    return result;
  };

  static GetImageExtension = (url: string) => {
    try {
      // Extract the part of the URL before any query parameters
      const cleanUrl = url.split('?')[0]; // Remove the query parameters

      // Get the extension by splitting the string at the last dot (.)
      const extension = cleanUrl.substring(cleanUrl.lastIndexOf('.') + 1);

      // Return the extension if valid, or throw an error if not found
      if (extension) {
        return extension;
      } else {
        throw new Error('File extension not found');
      }
    } catch (error) {
      console.error('Error extracting image extension:', error);
      return null; // Return null or handle the error as needed
    }
  };


  public static extractTokenFromHeader(authHeader: string | null): string | null {
    if (!authHeader || !authHeader.startsWith('Bearer ')) {
      throw new Error('Unauthorized');
    }
    return authHeader.split(' ')[1];
  }

  // --- FORM VALIDATIONS --- //

  public static validatePhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  public static validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  public static validateVehicleRegistration = (vehicleReg: string) => {
    const vehicleRegRegex = /^[A-Za-z0-9]{7,8}$/;
    return vehicleRegRegex.test(vehicleReg);
  };

  public static validateVehicleModelYear = (year: string) => {
    const yearNum = Number(year);
    const currentYear = new Date().getFullYear();
    const minYear = 1886; // The first automobile year
    return yearNum >= minYear && yearNum <= currentYear;
  };

  public static validateIdNumber = (idNumber: string) => {
    const idNumberRegex = /^\d{13}$/;
    return idNumberRegex.test((idNumber));
  };


  /// --- ///
  static formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('en-GB', options); // 'en-GB' ensures the day comes first
  };

  static formatDate = (date: Date) => {
    const options: any = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('en-GB', options); // 'en-GB' ensures the day comes first
  };

  // VEHICLE COMMON UTILS //
  static DriverAvailable = (formData: any, driverData: any) => {

    const driver = driverData.find((driver: Driver) => driver.driver_id === formData.vehicle_driver);
    if (driver === undefined) {
      return true;
    }
    if (driver.driver_vehicle === '' || driver.driver_vehicle === formData.vehicle_id || driver.driver_vehicle === null) {
      return true;
    } else {
      return false
    };
  }

  // VEHICLE COMMON UTILS //

  static VehicleAvailable = (formData: any, vehicleData: any) => {

    const vehicle = vehicleData.find((vehicle: any) => vehicle.vehicle_id === formData.driver_vehicle);

    if (vehicle === undefined) {
      return true;
    }

    if (vehicle.vehicle_driver === '' || vehicle.vehicle_driver === formData.driver_vehicle || vehicle.vehicle_driver === null) {
      return true;
    } else {
      return false
    };
  }
}