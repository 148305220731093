import React, { useEffect, useState } from 'react';
// UTILS
import CommonUtils from '../../../../../../utils/CommonUtils';
import SubscriptionUtils from '../../../../../../utils/SubscriptionUtils';
// COMPONENTS
import Threshold from './threshold';
import toast from 'react-hot-toast';
import useAuthStore from '../../../../../../stores/AuthStore';
import useSubscriptionsStore from '../../../../../../stores/SubscribersStore';
import useOwnerStore from '../../../../../../stores/OwnerStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

interface CreateSubscriptionModalProps {
  setCreateSubscriptionModal: (state: boolean) => void;
}

const CreateSubscriptionModal: React.FC<CreateSubscriptionModalProps> = ({ setCreateSubscriptionModal }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { updateSubscriptionsData, updateSubscribersData, fetchSubscriptionsData, subscriptionPlan, fetchSubscriptionsPlanData } = useSubscriptionsStore();
  const { ownerVehicleCount, fetchOwnerVehicleCountData } = useOwnerStore();
  // States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [dueDate, setDueDate] = useState('');
  const [plans, setPlans] = useState<{ [key: string]: { membership: number; perTaxi: number } }>({});


  useFetchData(fetchSubscriptionsData);
  useFetchData(fetchOwnerVehicleCountData);
  useFetchData(fetchSubscriptionsPlanData);

  const [formData, setFormData] = useState({
    subscription_name: '',
    subscription_desc: '',
    subscription_period: 'weekly',
    min: 0,
    max: 0,
    membership: 0,
    per_vehicle: 0,
    subscription_start: '',
    subscription_due: '',
  });

  const processedData = subscriptionPlan.map((item: { sub_plan_model: string; }) => {
    let parsedModel: string;

    if (item.sub_plan_model && item.sub_plan_model.trim() !== "") {
      try {
        parsedModel = JSON.parse(item.sub_plan_model);
      } catch (error) {
        console.warn("Failed to parse JSON, leaving the data as is:", error, item.sub_plan_model);
        parsedModel = item.sub_plan_model;
      }
    } else {
      console.warn("Empty or invalid JSON string:", item.sub_plan_model);
      parsedModel = item.sub_plan_model;
    }

    return {
      ...item,
      sub_plan_model: parsedModel
    };
  });

  const newPlan = (processedData[0]?.sub_plan_model);

  // USEFULL TO SHOW THE USER THE CALCULATIONS BEFORE THEY MAKE A SUBSCRIPTION
  const handleCalculate = () => {
    const highestPlanKey = Object.keys(newPlan).reduce((maxKey, currentKey) => {
      return parseInt(currentKey) > parseInt(maxKey) ? currentKey : maxKey;
    }, "0");

    ownerVehicleCount.forEach(owner => {
      const planKey = owner.vehicle_count <= parseInt(highestPlanKey) ? owner.vehicle_count?.toString() : highestPlanKey;
      const plan = newPlan[planKey];

      if (plan) {
        const { membership, perTaxi } = plan;
        const totalPayment = membership + (perTaxi * owner.vehicle_count);
        const thatTHICCJason = {
          ownerName: owner.user_name,
          ownerVehicles: owner.vehicle_count,
          ownerMembership: membership,
          ownerPerTaxi: perTaxi,
          ownerTotalPayment: totalPayment
        };
        console.log(thatTHICCJason);
      } else {
        console.log(`No plan found for ${owner.user_name} with ${owner.vehicle_count} vehicles.`);
      }
    });
  };

  const handleModalClose = () => {
    setCreateSubscriptionModal(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const calculateDueDate = (startDate: string, period: string) => {
    const start = new Date(startDate);
    let dueDate = new Date(start);

    switch (period) {
      case 'Once Off':
        dueDate.setDate(start.getDate() + 7);
        break;
      case 'Weekly':
        dueDate.setDate(start.getDate() + 7);
        break;
      case 'Bi-Weekly':
        dueDate.setDate(start.getDate() + 14);
        break;
      case 'Monthly':
        dueDate.setMonth(start.getMonth() + 1);
        break;
      case 'Annually':
        dueDate.setFullYear(start.getFullYear() + 1);
        break;
      default:
        break;
    }

    return dueDate.toISOString().split('T')[0];
  };

  const validateSubscription = () => {
    const { subscription_name, subscription_desc, subscription_period, min, max, membership, per_vehicle, subscription_start, subscription_due } = formData;
    const dueDate = new Date(subscription_due);
    const today = new Date();
    if (!subscription_name || !subscription_desc || !subscription_period || !subscription_start || !subscription_due) {
      toast.error('Please fill in all required fields');
      return false;
    };
    if (dueDate < today) {
      toast.error('Due date cannot be in the past');
      return false;
    }

    return true;

  };


  const handleSubscriptionCreate = async () => {
    if (!validateSubscription()) return;
    if(!sessionUser) return

    setIsSubmitting(true);

    try {
      const subscriptionId = CommonUtils.GenerateRandomString();

      const highestPlanKey = Object.keys(newPlan).reduce((maxKey, currentKey) => {
        return parseInt(currentKey) > parseInt(maxKey) ? currentKey : maxKey;
      }, "0");

      let thatTHICCdata = ownerVehicleCount.map(owner => {
        const planKey = owner.vehicle_count <= parseInt(highestPlanKey) ? owner.vehicle_count?.toString() : highestPlanKey;
        const plan = newPlan[planKey];

        if (plan) {
          const { membership, perTaxi } = plan;
          const totalPayment = membership + (perTaxi * owner.vehicle_count);
          return {
            ownerName: owner.user_name,
            ownerVehicles: owner.vehicle_count,
            ownerMembership: membership,
            ownerPerTaxi: perTaxi,
            ownerTotalPayment: totalPayment
          };
        } else {
          console.log(`No plan found for ${owner.user_name} with ${owner.vehicle_count} vehicles.`);
          return undefined;
        }
      }).filter(data => data !== undefined);

      await SubscriptionUtils.CreateNewSubscription(sessionUser, subscriptionId, formData, thatTHICCdata, newPlan);
      updateSubscriptionsData(sessionUser);
      updateSubscribersData(sessionUser)
      handleModalClose();
    } catch (error) {
      setError('Failed to create subscription. Please try again.');
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.subscription_start && formData.subscription_period) {
      const newDueDate = calculateDueDate(formData.subscription_start, formData.subscription_period);
      setFormData((prev) => ({
        ...prev,
        subscription_due: newDueDate,
      }));
    }
  }, [formData.subscription_start, formData.subscription_period]);


  return (
    <div className="mediumModal">

      <div className="modalHeader">
        <p className='modalHeading' >CREATE A SUBSCRIPTIONS FOR OWNERS</p>
      </div>

      <div className="modalContent">

        <div className=' flex flex-col md:flex-row justify-around rounded-lg w-full'>

          <div className=" flex flex-col items-center justify-around rounded-xl borderThemed">
            <div className="w-full">

                <div className="flex flex-col justify-around p-2 ">
                  <p className="modaltext">Subscription Name</p>
                  <input className="longTextInput"
                    type="text"
                    name="subscription_name"
                    value={formData.subscription_name}
                    onChange={handleChange} />
                </div>

                <div className="flex flex-col justify-around p-2 ">
                  <p className="modaltext">Subscription interval</p>
                  <select 
                    name="subscription_period" 
                    onChange={handleChange} 
                    value={formData.subscription_period} 
                    className="longTextInput">
                    <option value="Once Off">Once Off</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Bi-Weekly">Bi Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Annually">Annualy</option>
                  </select>
                </div>

              <div className="flex flex-col justify-around p-2 ">
                <p className="modaltext">Subscription Description</p>
                <textarea className='longTextArea'
                  name="subscription_desc"
                  value={formData.subscription_desc}
                  onChange={handleChange} />
              </div>
            </div>

              <div className="flex flex-col justify-around p-2 w-50% ">
                <p className="modaltext">Subscription Start</p>
                <input className='longTextInput'
                  type="date"
                  name="subscription_start"
                  value={formData.subscription_start}
                  onChange={handleChange} />
              </div>

              <div className="w-[60%] p-2">
                <div className="flex justify-between w-full ">
                  <p className='modaltext'>Start date:</p>
                  <p>{formData.subscription_start}</p>
                </div>
                <div className="flex justify-between w-full">
                  <p className='modaltext'>Subscription Due :</p>
                  <p>{formData.subscription_due}</p>
                </div>
              </div>

          </div>
          <div className="w-[400px]">
            <Threshold plans={plans} setPlans={setPlans} />

          </div>


        </div>
      </div>

      <div className="modalBtnFooter">
        <button className='pageBtngood'
          onClick={handleCalculate}>
          Calculate
        </button>
        <button className='pageBtngood'
          onClick={handleSubscriptionCreate}>
          Create
        </button>
        <button onClick={handleModalClose} className='pageBtncancel'>
          Close
        </button>
      </div>

    </div>
  );
};

export default CreateSubscriptionModal;
