import React, { useEffect, useState } from "react";
import UserMedia from "./UserMedia";
import { Owner } from "../../../../../../types/Types";
import toast from "react-hot-toast";
import CommonUtils from "../../../../../../utils/CommonUtils";
import OwnerUtils from "../../../../../../utils/OwnerUtils";
import { useOwner } from "../../../../components/OwnerContext";

interface UserInfoProps {
  currentUser: Owner;
}

const UserInfo: React.FC<UserInfoProps> = ({ currentUser }) => {
  const { sessionUser } = useOwner();
  const [formData, setFormData] = useState({
    user_id: currentUser?.user_id,
    user_name: currentUser?.user_name,
    user_surname: currentUser?.user_surname,
    user_email: currentUser?.user_email,
    user_status: currentUser?.user_status,
    user_phone: currentUser?.user_phone,
    user_city: currentUser?.user_city,
    user_idNum: currentUser?.user_idNum,
    user_gender: currentUser?.user_gender,
    alternative_name: currentUser?.alternative_name,
    alternative_number: currentUser?.alternative_number,
    user_membership: currentUser?.user_membership
  });

  useEffect(() => {
    setFormData({
      user_id: currentUser?.user_id,
      user_name: currentUser?.user_name,
      user_surname: currentUser?.user_surname,
      user_email: currentUser?.user_email,
      user_status: currentUser?.user_status,
      user_phone: currentUser?.user_phone,
      user_city: currentUser?.user_city,
      user_idNum: currentUser?.user_idNum,
      user_gender: currentUser?.user_gender,
      alternative_name: currentUser?.alternative_name,
      alternative_number: currentUser?.alternative_number,
      user_membership: currentUser?.user_membership
    });
  }, [currentUser?.alternative_name, currentUser?.alternative_number, currentUser?.user_city, currentUser?.user_email, currentUser?.user_gender, currentUser?.user_id, currentUser?.user_idNum, currentUser?.user_membership, currentUser?.user_name, currentUser?.user_phone, currentUser?.user_status, currentUser?.user_surname]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { user_name, user_surname, user_email, user_phone, user_idNum, user_gender, alternative_number } = formData;
    if (!user_name || !user_surname || !user_email || !user_phone || !user_idNum || !user_gender) {
      toast.error("Please fill in all required fields");
      return false;
    };
    if (!CommonUtils.validateEmail(user_email)) {
      toast.error("Please enter a valid email address");
      return false;
    };
    if (!CommonUtils.validatePhoneNumber(user_phone) || (alternative_number && !CommonUtils.validatePhoneNumber(alternative_number))) {
      toast.error("Phone number must be a 10-digit number starting with 0 and no spaces.");
      return false;
    };
    if (!CommonUtils.validateIdNumber(user_idNum)) {
      toast.error("Please enter a valid ID number");
      return false;
    }
    return true;
  };

  const handleUserUpdate = () => {
    if (!validateForm()) return;
    try {
      OwnerUtils.UpdateUserDetails(sessionUser, formData);
      toast.success("User details updated successfully");
    } catch (error) {
      toast.error("Failed to update user details");
      console.log(error);
    }

    console.log(formData);
  };

  return (
    <div className="">
      <div className="modalHeader">
        <h1 className="modalHeading">User Information</h1>
      </div>

      <div className="modalContent">
        <div className="flex flex-row justify-between">

          <div className="flex flex-col">
            <h2>Personal Information</h2>
            <div className="flex flex-row justify-between ">
              <div className="h-[100%] border-slate-500 border-[2px] rounded-md m-[2px]">
                <div className="flex">
                  <div className="m-2 ">
                    <p className="font-semibold">Full Name</p>
                    <input
                      name="user_name"
                      value={formData.user_name} // Use formData
                      onChange={handleInputChange}
                      placeholder="Name"
                      className="normalTextInput"
                    />
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Surname</p>
                    <input
                      name="user_surname"
                      value={formData.user_surname} // Use formData
                      onChange={handleInputChange}
                      placeholder="Name"
                      className="normalTextInput"
                    />
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Email</p>
                    <input
                      name="user_email"
                      value={formData.user_email} // Use formData
                      onChange={handleInputChange}
                      placeholder="Email"
                      className="normalTextInput"
                    />
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Phone Number</p>
                    <input
                      type="text"
                      name="user_phone"
                      value={formData.user_phone} // Use formData
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      className="smallNumberInput"
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="m-2 ">
                    <p className="font-semibold">ID Number</p>
                    <input
                      type="number"
                      name="user_idNum"
                      value={formData.user_idNum} // Use formData
                      onChange={handleInputChange}
                      placeholder="ID Number"
                      className="normalTextInput"
                    />
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Gender</p>
                    <select
                      name="user_gender"
                      value={formData.user_gender} // Use formData
                      onChange={handleInputChange}
                      className="smallNumberInput"
                    >
                      <option value="">Select </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Status</p>
                    <select
                      name="user_status"
                      value={formData.user_status} // Use formData
                      onChange={handleInputChange}
                      className="normalTextInput"
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Suspended">Suspended</option>
                      <option value="On Leave">On Leave</option>
                    </select>
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Membership Type</p>
                    <select
                      name="user_membership"
                      value={formData?.user_membership}
                      onChange={handleInputChange}
                      className="normalTextInput">
                      <option value="None">None</option>
                      <option value="Full Member">Full Member</option>
                      <option value="Dual Member">Dual Member</option>
                      <option value="Special">Special</option>
                    </select>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="m-2">
                    <p className="font-semibold">Alternative Contact Person</p>
                    <input
                      type="text"
                      name="alternative_name"
                      value={formData.alternative_name} // Use formData
                      onChange={handleInputChange}
                      placeholder="Full Name"
                      className="normalTextInput"
                    />
                  </div>
                  <div className="m-2 ">
                    <p className="font-semibold">Alternative Contact Number</p>
                    <input
                      type="text"
                      name="alternative_number"
                      value={formData.alternative_number} // Use formData
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      className="normalTextInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL FOOTER */}
      <div className="modalBtnFooter">
        <button onClick={handleUserUpdate} className="pageBtngood">
          Save Changes
        </button>
      </div>
      {/*  */}
    </div>
  );
};

export default UserInfo;
