import React from 'react'
import SecSettingsComponent from './components/SecSettingsComponent'
import { AdminProvider } from '../../../components/AdminContext'
import AdminLayout from '../../../components/AdminLayout'

const Settings = () => {

  return (
    <AdminProvider>
      <AdminLayout>
        <div className='pageHeight'>
          <SecSettingsComponent />
        </div>
      </AdminLayout>
    </AdminProvider>
  )
}

export default Settings