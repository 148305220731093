import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSession } from './SessionContext';
import { HashLoader } from 'react-spinners';
import toast from 'react-hot-toast';

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: string[]; // Roles allowed to access the route
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
  const { user, status } = useSession();

  if (status === 'loading') {
    return <div className='loaderBackground'> <HashLoader color="#36d7b7" /></div>;
  }

  if (status === 'unauthenticated' || !user) {
    return <Navigate to="/" replace />;
  }

  if (!allowedRoles.includes(user.role)) {
    toast.error('You do not have permission to access this page');
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
