import { SessionUser } from "@/types/Types";
import CommonUtils from "./CommonUtils";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class VehicleUtils {

  // FETCH VEHICLES BASES ON RANK ID
  static FetchVehicles = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/vehicle/get_vehicles`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    }
  }

  // CREATE A NEW VEHICLE
  static CreateNewVehicle = async (sessionUser: SessionUser, formData: any) => {
    if (sessionUser.association) {
      try {
        const Vehicle_id = CommonUtils.GenerateRandomString();
        const response = await fetch(`${API_BASE_URL}/api/vehicle/create_vehicle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, association: sessionUser.association, vehicle_id: Vehicle_id, ownerId: formData.ownerId }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error creating driver:', error);
        throw error;
      }
    }
  }

  // UPDATE VEHICLE DATA
  static UpdateVehicle = async (sessionUser: any, formData: any) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/vehicle/update_vehicle`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }


        console.log('Vehicle created:');
      } catch (error) {
        console.error('Error creating driver:', error);
        throw error;
      }
    }
  }

  // DELETE VEHICLE
  static DeleteVehicle = async (sessionUser: any, vehicleId: any,) => {
    if (sessionUser.association) {
      try {
        if (sessionUser.association) {
          const response = await fetch(`${API_BASE_URL}/api/vehicle/delete_vehicle`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ vehicleId: vehicleId, association: sessionUser.association }),
          });

          if (!response.ok) {
            console.error('Error deleting Vehicle:', response.statusText);
            throw new Error('Network response at delete vehicle was not ok');
          }
        }
      } catch (error) {
        console.error('Error deleting Vehicle:', error);
      }
    }
  }

  //-- OWNERS UTILS --//

  static FetchOwnerVehicles = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/vehicle/get_owner_vehicles`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    }
  }

  static UpdateOwnerVehicle = async (sessionUser: any, formData: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/vehicle/update_owner_vehicle`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ vehicleId: formData.vehicleId, driver: formData.vehicleDriver, status: formData.vehicleStatus }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }


        console.log('Vehicle created:');
      } catch (error) {
        console.error('Error creating driver:', error);
        throw error;
      }
    }
  }


}
