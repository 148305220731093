const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class EventFinancesUtils {

  static CreateEventFinance = async (sessionUser: any, eventId: any, formData: any,) => {
    if (sessionUser?.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event_finances/create_event_finance`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, eventId }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log('Event created:');
      } catch (error) {
        console.error('Error creating Event:', error);
      }
    }
  };

  // FETCH DRIVER DATA USING THE ASSOCIATION
  static FetchEventFinances = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event_finances/get_event_finances`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        };

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };
  };

  // UPDATE/EDIT DRIVER DETAILS
  static UpdateEventExpense = async (sessionUser: any, formData: any,) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event_finances/update_expense`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, association: sessionUser.association }),
        });

        if (!response.ok) {
          throw new Error('Failed to update Event information');
        }

      } catch (error) {
        console.error('Error updating driver:', error);
        throw error;
      }
    }
  };

  // THIS IS TO DELETE THE EVENT
  static DeleteEventExpense = async (sessionUser: any, expense_id: number) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event_finances/delete_expense`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ expense_id }),
        });
        if (!response.ok) {
          console.error('Error deleting event:', response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error deleting event:', error);
        throw error;
      }
    } else {
      console.error('No association found in session');
    }
  };

}