import React from 'react';
import { useNavigate } from 'react-router-dom';
import siyaya from '../assets/images/siyaya.png';

export default function HomePage() {
  const navigate = useNavigate();

  const signIn = () => {
    navigate('/signin');
  };

  const complaintsPage = () => {
    navigate('/complaint');
  };

  const verifyPage = () => {
    navigate('/verify');
  };

  return (
    <main className="flex flex-col items-center w-full h-[100vh] bg-slate-200">

      {/* HEADER */}
      <header className="p-4 flex justify-between items-center w-full h-[90px] bg-white shadow-md fixed top-0">
        <h1 className="text-3xl font-semibold text-slate-700">Taxi Admin</h1>
        <div className="flex space-x-4">
          <button className="homeScreenSignInBtn" onClick={complaintsPage}>Report</button>
          <button className="homeScreenSignInBtn" onClick={verifyPage}>Verify Taxi</button>
          <button className="homeScreenSignInBtn" onClick={signIn}>Sign In</button>
        </div>
      </header>

      {/* MAIN CONTENT */}
      <main className="flex flex-col items-center justify-center w-full h-full mt-[100px] p-6">
        <div className="flex flex-col md:flex-row items-center justify-center w-full max-w-5xl gap-10">
          <div className="flex flex-col items-start md:w-1/2 space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold text-center md:text-left text-slate-800">
              Fast Track your <br />
              <span className="text-green-700">Taxi Administration</span> <br />
              The 21st Century Way.
            </h1>
            <p className="text-lg md:text-xl text-slate-600">
              A taxi <span className="text-green-700 font-semibold">Administration</span> application that manages taxi fleets, drivers, finances, and events. It provides user roles, secure login, and efficient organization for streamlined taxi operations and customer support.
            </p>
          </div>

          <div className="flex justify-center md:w-1/2">
            <img src={siyaya} alt="siyaya" className="w-full max-w-sm rounded-lg shadow-md" />
          </div>
        </div>
      </main>

      {/* FOOTER */}
      <footer className="flex w-full h-[100px] items-center justify-center bg-white shadow-md mt-4">
        <p className="text-slate-500">Taxi Admin 2024 © All Rights Reserved</p>
      </footer>

    </main>
  );
}
