import React, { useState, useEffect, lazy, Suspense } from "react";
import DateUtils from "../../../../../../../utils/DateUtils";
import EventsUtils from "../../../../../../../utils/EventsUtils";
import { Autocomplete, TextField } from "@mui/material";
import EventCommunication from "../../../../components/modals/EventCommunication";
import toast from "react-hot-toast";
import { Driver, SquadCar, SquadMember } from "../../../../../../../types/Types";
import DeleteDialog from "../../../../../../../components/common/dialogue/DeleteDialogue";
import { useFetchData } from "../../../../../../../hooks/useFetchData";
import useVehicleStore from "../../../../../../../stores/VehicleStore";
import useSquadStore from "../../../../../../../stores/SquadStore";
import useDriverStore  from "../../../../../../../stores/DriverStore";
import useEventStore from "../../../../../../../stores/EventStore";
import useAuthStore from "../../../../../../../stores/AuthStore";

const AssignedEventTile = lazy(() => import("../../../../components/AssignedEventTile"));

///--///

interface EventModalProps {
  selectedEvent: any;
}

interface EventsAssigned {
  association_id: number;
  events_assigned_id: number;
  events_assigned_vehicle: string;
  events_assigned_driver: string;
  event_id: number;
  event_assign_id: number;
  event_assign_vehicle: string;
  event_assign_driver: string;
}

const EventDetails: React.FC<EventModalProps> = ({ selectedEvent }) => {
  // Stores
  const { sessionUser,  } = useAuthStore();
  const { vehicleData, fetchVehicleData } = useVehicleStore(); 
  const {updateEventData} = useEventStore();
  const { driverData, fetchDriverData} = useDriverStore();
  const { squadCarData, fetchSquadCarData, squadMembersData, fetchSquadMembersData } = useSquadStore();
  // States
  const [eventComm, setEventComm] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventsAssigned, setEventsAssigned] = useState<EventsAssigned[]>([]);

  const formatDate = (date: Date) => DateUtils.FormatSqlDate(date);

  const [formData, setFormData] = useState({
    eventId: selectedEvent?.id || selectedEvent?.event_id,
    eventName: selectedEvent?.title || selectedEvent?.event_name,
    eventCategory: selectedEvent?.extendedProps?.category || selectedEvent?.event_category,
    eventDesc: selectedEvent?.extendedProps?.description || selectedEvent?.event_desc,
    eventStart: selectedEvent?.startStr ? formatDate(new Date(selectedEvent.startStr)) : selectedEvent?.event_start ? formatDate(new Date(selectedEvent.event_start)) : "",
    eventEnd: selectedEvent?.endStr ? formatDate(new Date(selectedEvent.endStr)) : selectedEvent?.event_end ? formatDate(new Date(selectedEvent.event_end)) : "",
  });

  useFetchData(fetchVehicleData);
  useFetchData(fetchSquadCarData);
  useFetchData(fetchSquadMembersData);
  useFetchData(fetchDriverData);

  const [assignForm, setAssignForm] = useState({
    eventId: selectedEvent?.id,
    squad_car: "",
    vehicle: "",
  });

  useEffect(() => {
    setFormData({
      eventId: selectedEvent?.id || selectedEvent.event_id,
      eventName: selectedEvent?.title || selectedEvent.event_name,
      eventDesc: selectedEvent?.extendedProps?.description || selectedEvent.event_desc,
      eventCategory: selectedEvent?.extendedProps?.category || selectedEvent.event_category,
      eventStart: selectedEvent?.startStr ? formatDate(new Date(selectedEvent.startStr)) : selectedEvent?.event_start ? formatDate(new Date(selectedEvent.event_start)) : "",
      eventEnd: selectedEvent?.endStr ? formatDate(new Date(selectedEvent.endStr)) : selectedEvent?.event_end ? formatDate(new Date(selectedEvent.event_end)) : "",
    });
    FetchEventsAssigned();
  }, [selectedEvent]);

  const squadCarOptions = squadCarData.map((squadCar: SquadCar) => ({
    label: squadCar.squad_car_registration,
    id: squadCar.squad_car_id,
  }));

  const vehicleOptions = vehicleData.map((vehicle) => ({
    label: vehicle.vehicle_registration,
    id: vehicle.vehicle_id,
  }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filteredEventsAssigned = eventsAssigned?.filter(
    (event) => event.event_id === selectedEvent.event_id
  );

  const handleAutocompleteChange = (type: "squad_car" | "vehicle") => (
    _event: any,
    newValue: { label: string; id: number } | null
  ) => {
    if (newValue) {
      setAssignForm((prev) => ({
        ...prev,
        [type]: newValue.label,
      }));
    }
  };

  const handleEventAssignedCommunication = () => {
    setEventComm(true);
  };

  const validateForm = () => {
    if (!formData.eventName || !formData.eventStart || !formData.eventEnd || (formData.eventCategory === "" || undefined)) {
      toast.error("Please fill in all required fields");
      return false;
    }
    return true;
  };

  const handleEventUpdate = async () => {
    if (!validateForm() || !sessionUser) {
      return;
    }
    try {
      await EventsUtils.UpdateEventsDetails(formData, sessionUser);
      toast.success("Event updated successfully!");
      updateEventData(sessionUser);
    } catch (error) {
      console.log(error);
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleEventDelete = async () => {
    if (!sessionUser) return;
    try {
      await EventsUtils.DeleteEvent(sessionUser, selectedEvent.id);
      toast.success("Event deleted successfully!");
      updateEventData(sessionUser);
    } catch (error) {
      console.log(error);
    }
  };

  const FetchEventsAssigned = async () => {
    try {
      const eventsAssigned = await EventsUtils.EventsAssigned(sessionUser);
      setEventsAssigned(eventsAssigned);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleAssign = async () => {
    const isVehicleAssigned = filteredEventsAssigned.some(
      (event) => event.event_assign_vehicle === assignForm.vehicle
    );

    if (isVehicleAssigned) {
      toast.error("This vehicle is already assigned to this event.");
      return;
    }

    if (!sessionUser) return;

    let squadDriver = null;
    let vehicleDriver: Driver | undefined;

    if (assignForm.squad_car) {
      squadDriver = squadMembersData.find(
        (member: SquadMember) => member.squad_member_vehicle === assignForm.squad_car
      );
    }

    if (assignForm.vehicle) {
      vehicleDriver = driverData.find(
        (driver: Driver) => driver.driver_vehicle === assignForm.vehicle
      );
    }

    try {
      const driverInfo = squadDriver ? squadDriver : vehicleDriver;
      if (!driverInfo) {
        toast.error("No driver found for the selected vehicle or squad car.");
        return;
      }

      await EventsUtils.AssignToEvents(assignForm, driverInfo, selectedEvent.id, sessionUser);

      updateEventData(sessionUser);
      toast.success("Event assigned successfully!");
      await FetchEventsAssigned();
    } catch (error) {
      console.error("Error assigning event:", error);
      toast.error("An error occurred while assigning the event.");
    }
  };

  return (
    <div className="">

      <div className="modalContent">

        {/* MODAL HEADER */}
        <div className="flex flex-col justify-between ">
          <div className="flex flex-col md:flex-row justify-between ">
            <div className="flex flex-col w-[37%] rounded-md m-[2px]">

              <div className="flex flex-col">
                <div className="flex flex-col">
                  <p className="modaltext">Event Name</p>
                  <input className="longTextInput"
                    name="eventName"
                    value={formData.eventName}
                    onChange={handleInputChange}
                    placeholder="Event Name"
                  />
                </div>
                <div className="">
                  <p className="modaltext">Event Category</p>
                  <select className="longTextInput"
                    name="eventCategory"
                    value={formData.eventCategory}
                    onChange={handleInputChange}>
                    <option value="">Select Category</option>
                    <option value="funeral">Funeral</option>
                    <option value="special">Special</option>
                    <option value="Meeting">Meeting</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                  <p className="modaltext">Start Date</p>
                  <input className="longTextInput"
                    type="date"
                    name="eventStart"
                    value={formData.eventStart}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="">
                  <p className="modaltext">Event Date</p>
                  <input className="longTextInput"
                    type="date"
                    name="eventEnd"
                    value={formData.eventEnd}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <p className="modaltext">Event Description</p>
                <textarea
                  name="eventDesc"
                  value={formData.eventDesc}
                  onChange={handleInputChange}
                  placeholder="Event Description"
                  className="longTextArea"
                />
              </div>
            </div>

            {/* EVENTS ASSIGNED SECTION */}
            <div className="flex flex-col">

              <Suspense fallback={<div>Loading...</div>}>
                <div className="h-[200px] w-full borderThemed overflow-y-auto rounded-md m-2">
                  {filteredEventsAssigned?.length > 0 ? (
                    filteredEventsAssigned.map((event, index) => (
                      <AssignedEventTile key={index} event={event}
                        FetchEventsAssigned={FetchEventsAssigned}
                      />
                    ))
                  ) : (
                    <div className="text-center p-4">Nothing Assigned Yet</div>
                  )}
                </div>
              </Suspense>

              {/* ASSIGN VEHICLE AND SQUAD */}
              <div className=" flex flex-col  " >

                <div className="flex justify-between ring-green-500 h-[60px] w-full">
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option.label}
                    id="vehicle-autocomplete"
                    options={vehicleOptions}
                    sx={{ width: 230 }}
                    onChange={handleAutocompleteChange('vehicle')}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Vehicle" />
                    )}
                  />

                  <button className="pageBtngood" onClick={handleVehicleAssign}>
                    Assign
                  </button>

                </div>


                <div className="flex justify-between ring-green-500 h-[50px] w-full">
                  {/* ASSIGN SQUAD */}
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option.label}
                    id="squadCar-autocomplete"
                    options={squadCarOptions}
                    sx={{ width: 230 }}
                    onChange={handleAutocompleteChange('squad_car')}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Squad Vehicle" />
                    )}
                  />

                  <button className="pageBtngood" onClick={openDeleteDialog}>
                    Delete
                  </button>
                  {/*  */}
                </div>
              </div>

              <div className="mt-5">
                <p className="modaltext"> Send Communication</p>
                <button className="pageBtngreen" onClick={handleEventAssignedCommunication} >Send</button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="modalBtnFooter">
        <button
          onClick={handleEventUpdate}
          className="pageBtngood"
        >
          Save Changes
        </button>
        <button className="pageBtnWarn" onClick={openDeleteDialog}>Delete</button>

      </div>
      {eventComm && (
        <div className="modalBackground">
          <EventCommunication setEventComm={setEventComm} selectedEvent={selectedEvent} filteredEvents={filteredEventsAssigned} />
        </div>
      )}

      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleEventDelete}
        title="Delete Event?"
        description={`Are you sure you want to delete ${selectedEvent?.title}?`}
      />
    </div>
  );
};

export default EventDetails;