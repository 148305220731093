import React, { useState } from 'react'
import UserModal from './modal/UserModal'
import { User } from '../../../../../../types/Types'
import toast from "react-hot-toast";
import CreateAccount from '../modal/CreateAccount'
import UserUtils from '../../../../../../utils/UserUtils'
import useOwnerStore from '../../../../../../stores/OwnerStore'
import { useFetchData } from '../../../../../../hooks/useFetchData'
import useAuthStore from '../../../../../../stores/AuthStore';

const Admin = () => {
  const {  sessionUser } = useAuthStore();
  const { userData ,fetchUserData, updateUserData } = useOwnerStore() 
  const [userInfo, setUserInfo] = useState<any>([])
  const [userModal, setUserModal] = useState(false)
  const [createUser, setCreateUser] = useState(false)

  useFetchData(fetchUserData);

  const handelUserModal = (user: any) => {
    setUserInfo(user)
    setUserModal(!userModal)
  };

  const filteredUserData = userData.filter((user: any) => user.user_id !== sessionUser?.id && user.user_role !== 'Owner');

  // Api calls
  const handleUserUpdate = async (formData: User) => {
    if (!sessionUser) return;
    try {
      await UserUtils.UpdateUserDetails(sessionUser, formData)
      updateUserData(sessionUser)
      toast.success('User details updated successfully')
    } catch (error) {
      toast.error('Failed to update user details')
    };
  };

  const handleUserCreate = async (formData: User) => {
    if (!sessionUser) return;
    try {
      await UserUtils.CreateUser(sessionUser, formData)
      updateUserData(sessionUser)
      toast.success('User created successfully')
      setCreateUser(false)
    } catch (error) {
      toast.error('Failed to create user')
    }
  };

  return (
    <div>

      <div className="borderThemed rounded-md h-[73vh] overflow-y-auto">
        <button className='pageBtngood' onClick={() => setCreateUser(true)}>Create Account</button>
        {
          filteredUserData.map((user) => {
            return (
              <div onClick={() => handelUserModal(user)} key={user.user_id} className="py-3 h-[100px] ring m-2 p-2 rounded-md cursor-pointer ">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {user.user_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {user.user_email}
                      </div>
                      <div className="text-sm text-gray-500">
                        Role: {user.user_role}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      {
        userModal &&
        <div className="modalBackground">
          <UserModal
            onClose={() => setUserModal(false)}
            user={userInfo}
            onSave={handleUserUpdate}
          />
        </div>
      }
      {
        createUser &&
        <div className="modalBackground">
          <CreateAccount
            onClose={() => setCreateUser(false)}
            handleSubmit={handleUserCreate}
          />
        </div>
      }
    </div>
  )
}

export default Admin