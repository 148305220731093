import { create } from "zustand";
import { SessionUser } from "../types/Types";
import CommunicationUtils from "../utils/CommunicationUtils";

interface UserComsType {
  comsData: any[];
  scheduledComsData: any[];
  contactData: any[];
  loading: boolean;
  error: string | null;
  fetchComsData: (sessionUser: SessionUser) => Promise<void>;
  fetchContactData: (sessionUser: SessionUser) => Promise<void>;
  fetchScheduledComsData: (sessionUser: SessionUser) => Promise<void>;

  updateComsData(sessionUser: SessionUser): unknown;
  updateScheduledComsData(sessionUser: SessionUser): unknown;
  updateContactData(sessionUser: SessionUser): unknown;
}

const useComsStore = create<UserComsType>((set, get) => ({
  comsData: [],
  scheduledComsData: [],
  contactData: [],
  loading: false,
  error: null,

  // ------------------------ Coms Data --------------------------- //

  fetchComsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateComsData(sessionUser);
  },

  updateComsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await CommunicationUtils.fetchComs(sessionUser);
      set({ comsData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch coms data",
        loading: false
      });
    }
  },

  // ------------------------ Scheduled Coms Data --------------------------- //

  fetchScheduledComsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateScheduledComsData(sessionUser);
  },

  updateScheduledComsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await CommunicationUtils.fetchScheduledComs(sessionUser);
      set({ scheduledComsData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch scheduled coms data",
        loading: false
      });
    }
  },

  // ------------------------ Contact Data --------------------------- //

  fetchContactData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateContactData(sessionUser);
  },

  updateContactData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await CommunicationUtils.findEmails(sessionUser);
      set({ contactData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch contact data",
        loading: false
      });
    }
  },


}));

export default useComsStore;
