import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import ComplainantModal from "../modal/ComplainantModal";
import { ThemeProvider } from "@mui/material";
import { getTheme } from "../../../components/muitheme/MuiTheme";
import ClosedComplainantModal from "../modal/ClosedComplainantModal";
import { useOwner } from "../../../../components/OwnerContext";

interface Collections {
  collection_id: number,
  collection_name: string,
  collection_desc: string,
  collection_amount: number
}

const ClosedCases: React.FC = () => {
  const { dcData, isDarkMode } = useOwner();
  const [complaintModal, setComplaintModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Collections | undefined>(undefined);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const PendingCases = dcData.filter((cases: any) => cases.dc_status === "Closed Case");

  const columns = useMemo<MRT_ColumnDef<Collections>[]>(
    () => [
      {
        accessorKey: "customer_name",
        header: "Complainant Name",
        size: 100,
      },
      {
        accessorKey: "complaint_title",
        header: "Complaint Title",
        size: 200,
      },
      {
        accessorKey: "dc_status",
        header: "Complaint Status",
        size: 100,
      },
      {
        accessorKey: "dc_date",
        header: "Complaint Date",
        size: 100,
        Cell: ({ cell }) => formatDate(cell.getValue<string>()), // Custom cell renderer
      },
    ],
    []
  );

  const handleComplaintModal = (row: Collections) => {
    setSelectedRow(row);
    setComplaintModal(!complaintModal);
  };

  const handleExportRows = (rows: MRT_Row<Collections>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save(`Closed-Cases-Secretary-${Date.now()}.pdf`);
  };

  return (
    <div className="p-2">

      <ThemeProvider theme={(getTheme(isDarkMode))}>
        <MaterialReactTable
          columns={columns}
          data={PendingCases}
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleComplaintModal(row.original),
            sx: {
              cursor: "pointer",
            },
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <div >
              <Button
                disabled={dcData.length === 0}
                onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                startIcon={<FileDownloadIcon />}>
                Export As PDF
              </Button>
            </div>
          )}
        />

        {/* MODALS */}
        {selectedRow && (
          <div
            className={`${complaintModal ? "modalBackground" : "hidden"}`}>
            <ClosedComplainantModal
              setComplaintModal={setComplaintModal}
              selectedRow={selectedRow}
            />
          </div>
        )}
        {/*  */}

      </ThemeProvider>
    </div>
  );
};

export default ClosedCases;
