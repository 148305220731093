import React, { useState, useEffect } from "react";
import SubscriptionUtils from "../../../../../../utils/SubscriptionUtils";
import toast from "react-hot-toast";
import { Subscriber } from "../../../../../../types/Types";
import { useOwner } from "../../../../components/OwnerContext";

interface DriverModalProps {
  setRecordSubscriptionModal: (state: boolean) => void;
  selectedRow: Subscriber
}

const RecSubscriptionModal: React.FC<DriverModalProps> = ({
  setRecordSubscriptionModal,
  selectedRow,
}) => {
  const { sessionUser, fetchSubscribersData } = useOwner();

  const [formData, setFormData] = useState({
    subscriber_collected: selectedRow?.subscriber_collected || 0, // Fallback value
  });

  useEffect(() => {
    setFormData({
      subscriber_collected: selectedRow?.subscriber_collected || 0, // Fallback to avoid undefined
    });
  }, [selectedRow]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOwnerModalClose = () => {
    setRecordSubscriptionModal(false);
  };

  const handleSubscriptionRecord = async () => {
    try {
      if (selectedRow) { // Ensure selectedRow exists before proceeding
        await SubscriptionUtils.RecordSubscription(sessionUser, formData, selectedRow);
        fetchSubscribersData();
        setRecordSubscriptionModal(false);
        toast.success("Subscription recorded successfully");
      } else {
        console.error("No subscription record selected.");
      }
    } catch (error) {
      console.error("Error recording subscription:", error);
      toast.error("Failed to record subscription. Please try again.");
    }
  };

  return (
    <div className="smallModal">
      <div className="modalHeading">
        <h1 className="text-grey-800">
          Record Subscription Payment for {selectedRow?.subscriber_name || "Unknown"}
        </h1>
      </div>

      <div className="modalContent">
        <h2>
          Expected Collection Amount{" "}
          <span className="modaltext"> R {selectedRow?.subscriber_total} </span>
        </h2>
        <div className="flex flex-col items-center justify-between">
          <p className="modaltext">Collection Amount</p>
          <input
            className="mediumTextInput"
            type="number"
            name="subscriber_collected"
            value={formData.subscriber_collected}
            onChange={handleInputChange}
            placeholder="Amount"
          />
        </div>
      </div>

      <div className="modalBtnFooter">
        <button className="pageBtngood" onClick={handleSubscriptionRecord}>
          Submit
        </button>
        <button onClick={handleOwnerModalClose} className="pageBtncancel">
          Close
        </button>
      </div>
    </div>
  );
};

export default RecSubscriptionModal;
