import React, { Suspense, } from 'react';
import { HashLoader } from 'react-spinners';
import { AdminProvider } from '../../../components/AdminContext';
import FinanceDash from './main/FinanceDash';
import AdminLayout from '../../../components/AdminLayout';

function SecretaryFinance() {

  return (
    <AdminProvider>
      <AdminLayout>
        <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
          <div className="pageHeight">
            <FinanceDash />
          </div>
        </Suspense>
      </AdminLayout>
    </AdminProvider>
  );
}

export default SecretaryFinance;
