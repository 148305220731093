import React, { useState, ChangeEvent } from "react";
import toast from "react-hot-toast";
import ExpensesUtils from "../../../../../../utils/ExpenseUtils";
import useExpensesStore from "../../../../../../stores/ExpenseStore";
import useAuthStore from "../../../../../../stores/AuthStore";


interface CreateExpenseProps {
  setCreateExpenseModal: (state: boolean) => void;
};

interface FormData {
  expense_name: string;
  expense_category: string;
  expense_amount: string;
}

const CreateExpense: React.FC<CreateExpenseProps> = ({ setCreateExpenseModal }) => {
  const { sessionUser } = useAuthStore();
  const { fetchExpensesData } = useExpensesStore();
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [formData, setFormData] = useState<FormData>({
    expense_name: "",
    expense_category: "",
    expense_amount: "",
  });


  const handleSubmit = async () => {
    if (isSubmitting || !sessionUser) return;
    setIsSubmitting(true);

    try {
      await ExpensesUtils.CreateExpense(sessionUser, formData);
      handlePageClose();
      fetchExpensesData(sessionUser);
      toast.success('Expense created successfully!');
    } catch (err) {
      toast.error('Failed to create Expense. Please try again.');
      console.log(err)
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePageClose = () => {
    setCreateExpenseModal(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { name, value, type } = target;
    let checked: boolean | undefined;

    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      checked = target.checked;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "collectionStart" || name === "collectionEnd"
        ? new Date(value)
        : type === "number"
          ? Number(value)
          : checked !== undefined
            ? checked
            : value,
    }));
  };
  return (
    <div className="smallModal">

      <div className="modalHeader">
        <h1 className="modalHeading">Create A New Expense</h1>
      </div>

      <div className="modalContent">
        <div className="flex flex-col">

          <div className="flex md:flex-row flex-col">
            <div className="m-2">
              <p className="modaltext">Expense Name</p>
              <input className="longTextInput"
                name="expense_name"
                value={formData.expense_name}
                onChange={handleChange}
                placeholder="Name"
              />
            </div>

          </div>
          <div className="flex md:flex-row flex-col">
            <div className="m-2">
              <p className="modaltext">Expense Category</p>
              <input className="longTextInput"
                name="expense_category"
                value={formData.expense_category}
                onChange={handleChange}
                placeholder="Category"
              />
            </div>

          </div>

          <div className="flex flex-col">
            <div className="flex md:flex-row flex-col">
              <div className="m-2">
                <p className="modaltext">Expense Amount</p>
                <input className="longTextInput"
                  type="number"
                  name="expense_amount"
                  value={formData.expense_amount}
                  onChange={handleChange}
                  placeholder="R 0.00"
                />
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className="modalBtnFooter">
        <button className="pageBtngood"
          onClick={handleSubmit}>
          {isSubmitting ? 'Adding...' : 'Add Expense'}
        </button>
        <button className="pageBtncancel"
          onClick={handlePageClose} >
          Close
        </button>
      </div>

    </div>
  );
};

export default CreateExpense;