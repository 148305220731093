import React from 'react';
import { Subscription } from '../../../../../../../types/Types';

interface SubscriptionTitleProps {
  subscription: Subscription;
  onClick: (subscription: Subscription) => void;
}

const SubscriptionTile: React.FC<SubscriptionTitleProps> = ({ subscription, onClick }) => {
  const subscriptionDate = new Date(subscription.subscriptions_due).toDateString();

  const handleComTileClick = () => {
    onClick(subscription);
  };

  return (
    <div onClick={handleComTileClick} className="flex flex-row borderThemed justify-between p-2 rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200">
      <div className="w-[60%]">
        <p className="modaltextsmallBold"> {subscription?.subscription_name}</p>
        <h3 className="text-md font-bold">{subscription.subscription_period}</h3>
        <p className="modaltextsmallBold">{subscription.subscription_desc}</p>
      </div>
      <div className="flex flex-col w-[40%]">
        <p className='modaltextsmall'>Due:</p>
        <p className="modaltextsmallBold text-center">{subscriptionDate}</p>
        <p className={`${subscription.subscription_status === 'Active' ? 'text-green-500 text-center font-bold text-[9pt]' : 'text-red-500 text-center font-bold text-[9pt]'}`}>{subscription.subscription_status}</p>
      </div>
    </div>
  );
};

export default SubscriptionTile;
