import { TextField } from "@mui/material";
import React, { useState, ChangeEvent } from "react";
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import CommonUtils from "../../../../../../utils/CommonUtils";
import toast from "react-hot-toast";
import useColletionsStore from "../../../../../../stores/CollectionsStore";
import useAuthStore from "../../../../../../stores/AuthStore";


interface CreateCollectionProps {
  setCreateCollectionModal: (state: boolean) => void;
}

interface FormData {
  collectionName: string;
  collectionDesc: string;
  collectionAmount: number;
  collectionStart: Date;
  collectionEnd: Date;
  vehicleCollection: boolean;
  ownerCollection: boolean;
  vehicleAmount: number | string;
  ownerAmount: number | string;
}

const CreateCollection: React.FC<CreateCollectionProps> = ({ setCreateCollectionModal }) => {
  const { sessionUser } = useAuthStore();
  const { updateCollectionsData, updateCollectorsData } = useColletionsStore();
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [formData, setFormData] = useState<FormData>({
    collectionName: "",
    collectionDesc: "",
    collectionAmount: 0,
    collectionStart: new Date(),
    collectionEnd: new Date(),
    vehicleCollection: false,
    ownerCollection: false,
    vehicleAmount: '',
    ownerAmount: ''
  });


  const handleSubmit = async () => {
    if (isSubmitting || !sessionUser) return; // Prevent multiple submissions
    setIsSubmitting(true);

    try {
      const collectionId = CommonUtils.GenerateRandomString();
      console.log(collectionId);
      await CollectionUtils.CreateNewCollection(sessionUser, collectionId, formData);
      handlePageClose();
      updateCollectionsData(sessionUser);
      updateCollectorsData(sessionUser);
      toast.success('Collection created successfully!');
    } catch (err) {
      toast.error('Failed to create collection. Please try again.');
      console.log(err)
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePageClose = () => {
    setCreateCollectionModal(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    const { name, value, type } = target;
    let checked: boolean | undefined;

    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      checked = target.checked;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "collectionStart" || name === "collectionEnd"
        ? new Date(value)
        : type === "number"
          ? Number(value)
          : checked !== undefined
            ? checked
            : value,
    }));
  };
  return (
    <div className="smallModal">

      <div className="modalHeader">
        <h1 className="modalHeading">Create A New Collection</h1>
      </div>

      <div className="modalContent">
        <div className="flex flex-col">

          {/* owner collection*/}
            <div className="flex items-center mt-3">
              <input
                checked={formData.ownerCollection}
                onChange={handleChange}
                type="checkbox"
                id="ownerCollection"
                name="ownerCollection"
                className="mr-2 h-[25px] w-[25px]"
              />
              <label htmlFor="ownerCollection" className="text-sm">Owner Collection</label>
              <input className="longTextInput md:normalTextInput"
                placeholder="Owner Amount"
                type="number"
                id="outlined-basic"
                name="ownerAmount"
                value={formData.ownerAmount}
                onChange={handleChange}
              />
            </div>
            {/* vehicle collection */}
            <div className="flex items-center mt-3">
              <input className="mr-2 h-[25px] w-[25px]"
                checked={formData.vehicleCollection}
                onChange={handleChange}
                type="checkbox"
                id="vehicleCollection"
                name="vehicleCollection" />
              <label htmlFor="vehicleCollection" className="text-sm">Vehicle Collection</label>
              <input className="longTextInput md:normalTextInput"
                placeholder="Vehicle Amount"
                type="number"
                id="outlined-basic"
                name="vehicleAmount"
                value={formData.vehicleAmount}
                onChange={handleChange} />
            </div>
          {/*  */}

          {/* COLLECTION NAME AND DESCRIPTION */}
          <div className="flex flex-col items-center justify-center">


              <div className="m-2 ">
                <input className="longTextInput"
                  name="collectionName"
                  value={formData.collectionName}
                  onChange={handleChange}
                  placeholder="Collection Name"
                />
              </div>
              <div className="m-2 h-[100px]">
                <textarea className="longTextArea"
                  name="collectionDesc"
                  value={formData.collectionDesc}
                  onChange={handleChange}
                  placeholder="Collection Description"
                />
              </div>

            <div className="">
                <div className="m-2">
                  <p className="modaltext">Start Date</p>
                  <input
                    type="date"
                    name="collectionStart"  // Updated name to match formData
                    value={formData.collectionStart.toISOString().split("T")[0]}
                    onChange={handleChange}
                    className="longTextInput"
                  />
                </div>
                <div className="m-2">
                  <p className="modaltext">End Date</p>
                  <input
                    type="date"
                    name="collectionEnd"
                    value={formData.collectionEnd.toISOString().split("T")[0]}
                    onChange={handleChange}
                    className="longTextInput"
                  />
                </div>
            </div>

          </div>




        </div>

      </div>

      <div className="modalBtnFooter">
        <button className="pageBtngood"
          onClick={handleSubmit}>
          {isSubmitting ? 'Adding...' : 'Add Collection'}
        </button>
        <button className="pageBtncancel"
          onClick={handlePageClose} >
          Close
        </button>
      </div>

    </div>
  );
};

export default CreateCollection;