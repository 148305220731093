import { create } from "zustand";
import { Expense, SessionUser } from "../types/Types";
import ExpensesUtils from "../utils/ExpenseUtils";

interface ExpenseStoreType {
  expensesData: Expense[];
  loading: boolean;
  error: string | null;
  fetchExpensesData: (sessionUser: SessionUser) => Promise<void>;
  updateExpenseData: (sessionUser: SessionUser) => Promise<void>;
}

const useExpensesStore = create<ExpenseStoreType>((set, get) => ({
  expensesData: [],
  loading: false,
  error: null,

  // ----- Expenses Data ----- //

  fetchExpensesData: async (sessionUser) => {
    set({ loading: true, error: null });
    await get().updateExpenseData(sessionUser);
  },

  updateExpenseData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await ExpensesUtils.GetExpenses(sessionUser);
      set({ expensesData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch expense data",
        loading: false
      });
    }
  }
}));

export default useExpensesStore;
