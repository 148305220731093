import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { HashLoader } from 'react-spinners';

interface User {
  access: any;
  id: string;
  role: string;
  email: string;
  name: string;
  association: number;
  association_name: string;
  accessToken: string;
}

interface SessionContextType {
  user: User | null;
  status: 'loading' | 'authenticated' | 'unauthenticated';
  setUser: (user: User | null) => void;
  logout: () => void;
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [status, setStatus] = useState<'loading' | 'authenticated' | 'unauthenticated'>('loading');
  const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout | null>(null);

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
    setStatus('unauthenticated');
  }, []);

  const resetTimer = useCallback(() => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 10 * 60 * 1000); // 10 minutes
    setLogoutTimer(timer);
  }, [logout, logoutTimer]);


  useEffect(() => {
    const fetchUser = () => {
      const cachedUser = localStorage.getItem('user');
      if (cachedUser) {
        try {
          const parsedUser = JSON.parse(cachedUser);
          setUser(parsedUser);
          setStatus('authenticated');
        } catch (error) {
          console.error('Error parsing cached user data:', error);
          localStorage.removeItem('user');
          setStatus('unauthenticated');
        }
      } else {
        setStatus('unauthenticated');
      }
    };

    fetchUser();
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
    }

  }, [resetTimer]);

  useEffect(() => {
    if (status !== 'loading') {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        setStatus('authenticated');
      } else {
        localStorage.removeItem('user');
        setStatus('unauthenticated');
      }
    }
  }, [user, status, resetTimer, logoutTimer]);

  return (
    <SessionContext.Provider value={{ user, status, setUser, logout }}>
      {status === 'loading' ? <div><div className='loaderBackground'> <HashLoader color="#36d7b7" /></div></div> : children}
    </SessionContext.Provider>
  );
};