import React, { Suspense } from 'react';
import { HashLoader } from 'react-spinners';
import FinanceDash from './main/FinanceDash';
import { OwnerProvider } from '../../../components/OwnerContext';
import OwnerLayout from '../../../components/OwnerLayout';

function SecretaryFinance() {


  return (
    <OwnerProvider>
      <OwnerLayout>
        <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
          <div className="pageHeight">
            <FinanceDash />
          </div>
        </Suspense>
      </OwnerLayout>
    </OwnerProvider>
  );
}

export default SecretaryFinance;
