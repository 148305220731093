import { SessionUser } from "@/types/Types";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class OwnerUtils {

  static FetchOwnerData = async (sessionUser: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/owner/get_owners`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch owner:', error);
    }
  };

  static UpdateOwnerDetails = async (formData: SessionUser, sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/owner/update_owner`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          throw new Error('Failed to update Owner information');
        }
      } catch (error) {
        console.error('Error updating owner:', error);
        throw error;
      }
    }
  };

  static DeleteOwner = async (sessionUser: SessionUser, user_id: any,) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/owner/delete_owner`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ user_id }),
      });

      if (!response.ok) {
        console.error('Error deleting owner:', response.statusText);
        throw new Error('Network response at DeleteOwner was not ok');
      }

    } catch (error) {
      console.error('Error deleting owner:', error);
    }
  };

  static CreateOwner = async (sessionUser: SessionUser, formData: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/owner/create_owner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ formData }),
      });

      if (!response.ok) {
        console.error('Error creating owner:', response.statusText);
        throw new Error('Network response was not ok');
      }

    } catch (error) {
      console.error('Error creating owner:', error);
      throw error;
    }
  };

  static OwnerVehicleCount = async (sessionUser: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/owner/owners_vehicles`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
      });

      if (!response.ok) {
        console.error('Error creating owner:', response.statusText);
        throw new Error('Network response was not ok');
      }

      return response.json(); // Or whatever you need to return
    } catch (error) {
      console.error('Error fetching owner vehicles data:', error);
      throw error; // Propagate the error to be handled by the caller
    }
  };

  static FetchUserData = async (sessionUser: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/owner/get_users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok at FetchUserData');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  static UpdateUserDetails = async (sessionUser: SessionUser, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/owner/update_user`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          throw new Error('Failed to update User');
        }
      } catch (error) {
        console.error('Error updating User:', error);
        throw error;
      }
    }
  };

  static ResetOwnerPassword = async (sessionUser: SessionUser, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/owner/pass_reset`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error('Error Reseting Password:', error);
        throw error;
      }
    }
  };


}