import { create } from "zustand";
import { Driver, SessionUser } from "../types/Types";
import DriverUtils from "../utils/DriverUtils";

interface UserStoreType {
  updateDriverData(sessionUser: SessionUser): unknown;
  driverData: Driver[];
  loading: boolean;
  error: string | null;
  fetchDriverData: (sessionUser: SessionUser) => Promise<void>;
};

const useDriverStore = create<UserStoreType>((set, get) => ({
  driverData: [],
  loading: false,
  error: null,

  // ----- Driver Data ----- //

  fetchDriverData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateDriverData(sessionUser);
  },

  updateDriverData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await DriverUtils.FetchDriverData(sessionUser);
      set({ driverData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch drivers",
        loading: false
      });
    }
  }

}));

export default useDriverStore;