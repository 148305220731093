import { create } from "zustand";
import { SessionUser } from "../types/Types";
import FileUtils from "../utils/FileUtils";

interface UserStoreType {
  vehicleDocsData: any[];
  driverDocsData: any[];
  ownerDocsData: any[];
  userDocsData: any[];
  loading: boolean;
  error: string | null;
  fetchVehicleDocsData: (sessionUser: SessionUser) => Promise<void>;
  updateVehicleDocsData(sessionUser: SessionUser): unknown;
  fetchDriverDocsData: (sessionUser: SessionUser) => Promise<void>;
  updateDriverDocsData(sessionUser: SessionUser): unknown;
  fetchOwnerDocsData: (sessionUser: SessionUser) => Promise<void>;
  updateOwnerDocsData(sessionUser: SessionUser): unknown;
  fetchUserDocsData: (sessionUser: SessionUser) => Promise<void>;
  updateUserDocsData(sessionUser: SessionUser): unknown;
}

const useDocumentStore = create<UserStoreType>((set, get) => ({
  vehicleDocsData: [],
  driverDocsData: [],
  ownerDocsData: [],
  userDocsData: [],
  loading: false,
  error: null,

  // ----- Vehicle Docs ----- //
  fetchVehicleDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateVehicleDocsData(sessionUser);
  },

  updateVehicleDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await FileUtils.FetchVehicelDocs(sessionUser);
      set({ vehicleDocsData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch vehicle docs data",
        loading: false
      });
    }
  },

  // ----- Driver Docs ----- //

  fetchDriverDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateDriverDocsData(sessionUser);
  },

  updateDriverDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await FileUtils.FetchDriverDocs(sessionUser);
      set({ driverDocsData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch driver docs data",
        loading: false
      });
    }
  },

  // ----- Owner Docs ----- //

  fetchOwnerDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateOwnerDocsData(sessionUser);
  },

  updateOwnerDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await FileUtils.FetchOwnerDocs(sessionUser);
      set({ ownerDocsData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch owner docs data",
        loading: false
      });
    }
  },

  // ----- User Docs ----- //
  fetchUserDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateUserDocsData(sessionUser);
  },

  updateUserDocsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await FileUtils.FetchUserDocs(sessionUser);
      set({ userDocsData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch user docs data",
        loading: false
      });
    }
  },


}));

export default useDocumentStore;
