import EventsUtils from "../../../../../../utils/EventsUtils";
import React, { useState, ChangeEvent } from "react";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../stores/AuthStore";
import useEventStore from "../../../../../../stores/EventStore";

interface CreateEventsProps {
  setCreateEventModal: (state: boolean) => void;
  selectedDate: {
    allDay: boolean;
    end: Date;
    endStr: string;
    start: Date;
    startStr: string;
  };
}

interface FormData {
  eventName: string;
  eventDesc: string;
  eventCategory: string;
  eventStart: string;
  eventEnd: string;
}

const CreateEvents: React.FC<CreateEventsProps> = ({ setCreateEventModal, selectedDate }) => {
  const { sessionUser } = useAuthStore();
  const { updateEventData } = useEventStore();
  

  const [formData, setFormData] = useState<FormData>({
    eventName: '',
    eventDesc: '',
    eventCategory: '',
    eventStart: selectedDate?.startStr,
    eventEnd: selectedDate?.endStr,
  });

  const handlePageClose = () => {
    setCreateEventModal(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // FORM VALIDATION
  const validateForm = () => {
    if (!formData.eventName) {
      toast.error('Please enter an event name');
      return false;
    };
    if (!formData.eventCategory) {
      toast.error('Please select an event category');
      return false;
    };
    if (!formData.eventStart || !formData.eventEnd) {
      toast.error('Please select an event start and end time');
      return false;
    };
    if (formData.eventStart >= formData.eventEnd) {
      toast.error('Event start time must be before event end time');
      return false;
    };

    if (!formData.eventDesc) {
      toast.error('Please enter an event description');
      return false;
    };

    return true;
  }


  // API CALLS

  const handleEventCreate = async () => {
    if (!validateForm() || !sessionUser) {
      return;
    }

    try {
      toast.promise(EventsUtils.CreateNewEvent(sessionUser, formData), {
        loading: 'Creating event...',
        success: 'Event created successfully!',
        error: 'Failed to create event. Please try again.',
      });
      updateEventData(sessionUser);
      handlePageClose();
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  return (
    <div className="smallModal">

      <div className="modalHeader">
        <h1 className="modalHeading">
          Add Event
        </h1>
      </div>

      <div className="modalContent">
        <div className="m-2">
          <p className="modaltext">Event Name</p>
          <input className="longTextInput"
            name="eventName"
            value={formData.eventName}
            onChange={handleChange}
            placeholder="Event Name"
          />
        </div>
        <div className="m-2">
          <p className="modaltext">Event Category</p>
          <select className="longTextInput"
            name="eventCategory"
            value={formData.eventCategory}
            onChange={handleChange}>
            <option value="">Select Category</option>
            <option value="funeral">Funeral</option>
            <option value="special">Special</option>
            <option value="Meeting">Meeting</option>
          </select>
        </div>


        <div className="m-2">
          <p className="modaltext">Event Description</p>
          <textarea className="longTextArea"
            name="eventDesc"
            value={formData.eventDesc}
            onChange={handleChange}
            placeholder="Event Description"
          />
        </div>
        <div className="m-2">
          <p className="modaltext">Start Date</p>
          <input className="longTextInput"
            type="date"
            name="eventStart"
            value={formData.eventStart}
            onChange={handleChange}
          />
        </div>
        <div className="m-2">
          <p className="modaltext">End Date</p>
          <input className="longTextInput"
            type="date"
            name="eventEnd"
            value={formData.eventEnd}
            onChange={handleChange}
          />
        </div>

      </div>

      {/* MODAL FOOTER */}
      <div className="modalBtnFooter">
        <button className="pageBtngood"
          onClick={handleEventCreate} >
          Add Event </button>
        <button className="pageBtncancel"
          onClick={handlePageClose}>
          Close</button>
      </div>
      {/*  */}

    </div>
  );
};

export default CreateEvents;
