import React, { useState, lazy, Suspense } from 'react'
// LAYOUT IMPORT
import { Tab, Tabs } from '@mui/material';
import { HashLoader } from 'react-spinners';
import AdminLayout from '../../../components/AdminLayout';
const AdminVehicles = lazy(() => import('./tabs/Vehicles'));
const Squads = lazy(() => import('./tabs/squads'));
const Marshals = lazy(() => import('./tabs/Marshals'));
const Drivers = lazy(() => import('./tabs/Drivers'));
const Duty = lazy(() => import('./tabs/duty/Duty'));
// MAIN FUNCTION
function SecretaryTransport() {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <AdminLayout >
      <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
        <div className="pageHeight">
          <Tabs value={currentTab} onChange={handleTabChange} centered>
            <Tab label="Vehicles" />
            <Tab label="Squads" />
            <Tab label="Marshals" />
            <Tab label="Drivers" />
            <Tab label="Duty" />
          </Tabs>
          <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
            {currentTab === 0 && <AdminVehicles />}
            {currentTab === 1 && <Squads />}
            {currentTab === 2 && <Marshals />}
            {currentTab === 3 && <Drivers />}
            {currentTab === 4 && <Duty />}
          </Suspense>
        </div>
      </Suspense>
    </AdminLayout>
  )
}

export default SecretaryTransport;
