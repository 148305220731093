import React, { useState } from 'react';
import { User } from '../../../../../../types/Types';
import toast from 'react-hot-toast';
import CommonUtils from '../../../../../../utils/CommonUtils';


interface CreateAccountProps {
  onClose: () => void;
  handleSubmit: (formData: User) => void;
};

const CreateAccount: React.FC<CreateAccountProps> = ({ onClose, handleSubmit }) => {
  const [formData, setFormData] = useState<User>({
    user_id: '',
    user_name: '',
    user_surname: '',
    user_email: '',
    user_phone: '',
    user_gender: '',
    user_idNum: '',
    alternative_name: '',
    alternative_number: '',
    user_role: 'User',
    user_membership: 'Full Member',
    user_status: '',
    user_city: null,
    user_title: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (!formData.user_name || !formData.user_surname || !formData.user_email || !formData.user_phone || !formData.user_idNum || !formData.user_gender || !formData.user_membership || !formData.user_role) {
      toast.error("Please fill in all required fields");
      return false;
    };

    if (!CommonUtils.validateEmail(formData.user_email)) {
      toast.error("Please enter a valid email address");
      return false;
    };

    if (!CommonUtils.validatePhoneNumber(formData.user_phone)) {
      toast.error("Phone number must be a 10-digit number starting with 0 and no spaces");
      return false;
    };

    if (!CommonUtils.validateIdNumber(formData.user_idNum)) {
      toast.error("Please enter a valid ID number");
      return false;
    };

    if (formData.alternative_number) {
      if (!CommonUtils.validatePhoneNumber(formData.alternative_number)) {
        toast.error("Phone number must be a 10-digit number starting with 0 and no spaces");
        return false;
      };
    };


    return true
  };

  const handleSave = () => {
    if (!validateForm()) return;
    handleSubmit(formData);
  };

  const handleModalClose = () => {
    onClose();
  };

  return (
    <div className="mediumModal">
      {/* Modal Header */}
      <div className="modalHeader">
        <h1 className="modalHeading">Create New User</h1>
      </div>

      {/* Modal Content */}
      <div className="modalContent">
        <div className="flex">
          <div className="m-2">
            <p className="modaltext">User Name</p>
            <input
              className="normalTextInput"
              name="user_name"
              value={formData.user_name}
              onChange={handleInputChange}
              placeholder="User Name"
            />
          </div>
          <div className="m-2">
            <p className="modaltext">Surname</p>
            <input
              className="normalTextInput"
              name="user_surname"
              value={formData.user_surname}
              onChange={handleInputChange}
              placeholder="Surname"
            />
          </div>
          <div className="m-2">
            <p className="modaltext">User Phone</p>
            <input
              className="normalTextInput"
              type="number"
              name="user_phone"
              value={formData.user_phone}
              onChange={handleInputChange}
              placeholder="User Phone"
            />
          </div>

          <div className="m-2">
            <p className="modaltext">User Gender</p>
            <select
              className="normalTextInput"
              name="user_gender"
              value={formData.user_gender}
              onChange={handleInputChange}>
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>


        </div>

        <div className="flex">

          <div className="m-2">
            <p className="modaltext">ID Number</p>
            <input
              type='number'
              className="normalTextInput"
              name="user_idNum"
              value={formData.user_idNum}
              onChange={handleInputChange}
              placeholder="ID Number"
            />
          </div>

          <div className="m-2">
            <p className="modaltext">User Email (Optional)</p>
            <input
              className="normalTextInput"
              name="user_email"
              value={formData.user_email}
              onChange={handleInputChange}
              placeholder="User Email"
            />
          </div>

        </div>

        <div className="flex">

          <div className="m-2">
            <p className="modaltext">Alternative Contact Name</p>
            <input
              className="normalTextInput"
              name="alternative_name"
              value={formData.alternative_name}
              onChange={handleInputChange}
              placeholder="Alternative Name"
            />
          </div>

          <div className="m-2">
            <p className="modaltext">Alternative Contact Number</p>
            <input
              className="normalTextInput"
              name="alternative_number"
              value={formData.alternative_number}
              onChange={handleInputChange}
              placeholder="Alternative Number"
            />
          </div>
        </div>
        <p className='font-poppins my-2'>The User's Login Details will be sent to their phone number via SMS</p>

      </div>

      {/* Modal Footer */}
      <div className="modalBtnFooter">
        <button onClick={handleSave} className="pageBtngood">
          Create
        </button>
        <button onClick={handleModalClose} className="pageBtncancel">
          Close
        </button>
      </div>
    </div>
  );
};

export default CreateAccount;
