import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSession } from '../../../components/SessionContext';
// UTILS
import NotificationUtils from '../../../utils/NotificationUtils';

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  access: string;
  association: number;
  accessToken: string;
}

export interface AdminContextType {
  sessionUser: any;
  isCollapsed: boolean; setIsCollapsed: (value: boolean) => void;
  notificationData: any[]; fetchNotificationData: () => void;
  isDarkMode: boolean; toggleDarkMode: () => void;
  activeVehicles: number; activeDrivers: number;
  refresh: () => void;
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within a AdminProvider');
  }
  return context;
}

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeVehicles, setActiveVehicles] = useState<number>(0);
  const [activeDrivers, setActiveDrivers] = useState<number>(0);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { user: sessionUser } = useSession();

  const toggleDarkMode = () => {
    setIsDarkMode(prev => {
      const newMode = !prev;

      document.documentElement.classList.toggle('dark', newMode);

      localStorage.setItem('isDarkMode', newMode?.toString());

      return newMode;
    });
  };
  // API FETCHES // 

  const fetchNotificationData = async () => {
    if (sessionUser) {
      try {
        const data = await NotificationUtils.FetchNotificationData(sessionUser);
        setNotificationData(data);
      } catch (error) {
        console.error('Error fetching notification data:', error);
      }
    }
  };


  useEffect(() => {
    if (sessionUser) {

      const savedMode = localStorage.getItem('isDarkMode') === 'true';
      setIsDarkMode(savedMode);
      if (savedMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      };

      // UNCAHCED

      try {
        fetchNotificationData();
      } catch (error) {
        console.error('Error fetching notification data:', error);
      };


      ///--///

      if (sessionUser) {
        const userData = {
          id: sessionUser.id,
          email: sessionUser.email,
          role: sessionUser.role,
          name: sessionUser.name,
          access: sessionUser.access,
          association: sessionUser.association,
          accessToken: sessionUser.accessToken,
        };
        localStorage.setItem('user', JSON.stringify(userData));
      }

    }
  }, []);


  useEffect(() => {
    if (!sessionUser) {
      // Clear all items from localStorage
      localStorage.clear();
      setActiveVehicles(0);
      setActiveDrivers(0);

    }
  }, [sessionUser]);

  const refresh = async () => {
    localStorage.clear();
    fetchNotificationData();
  }


  // Run effect when sessionUser changes

  return (
    <AdminContext.Provider
      value={{
        sessionUser,
        activeVehicles, activeDrivers,
        isCollapsed, setIsCollapsed,
        notificationData, fetchNotificationData,
        isDarkMode, toggleDarkMode,
        refresh,
      }}>
      {children}
    </AdminContext.Provider>
  );
}
