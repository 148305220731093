import React from 'react'
import SecSettingsComponent from './components/SecSettingsComponent'
import { OwnerProvider } from '../../../components/OwnerContext'
import OwnerLayout from '../../../components/OwnerLayout'

const Settings = () => {

  return (
    <OwnerProvider>
      <OwnerLayout>
        <div className='pageHeight'>
          <SecSettingsComponent />
        </div>
      </OwnerLayout>
    </OwnerProvider>
  )
}

export default Settings