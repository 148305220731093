import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../stores/AuthStore';

type AccessType = {
  Transport?: { view?: boolean };
};

const useAccess = (): AccessType => {
    const { sessionUser } = useAuthStore();
    const navigate = useNavigate();

    return useMemo(() => {
        if (!sessionUser) {
        navigate('/');
        return {};
        };

        try {
          if (sessionUser.role === 'Secretary') {
            return {
              Finance: { view: true, edit: true },
              Transport: { view: true, edit: true },
              Communication: { view: true, edit: true },
              Staff: { view: true, edit: true },
              Schedule: { view: true, edit: true },
              DC: { view: true, edit: true },
              Owners: { view: true, edit: true },
              Settings: { view: true, edit: true }
            }
          };

        return JSON.parse(sessionUser.access) as AccessType;
        } catch (error) {
        console.error("Failed to parse access permissions:", error);
        return {};
        }
    }, [sessionUser]);
};

export default useAccess;
