/* eslint-disable no-restricted-globals */
import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import toast from "react-hot-toast";
import ResolvedComplainantModal from "../../../../../admin/Home/Pages/dc/modal/ResolvedComplainantModal";
import useAuthStore from "../../../../../../stores/AuthStore";
import useDCStore from "../../../../../../stores/DCStore";
import { useFetchData } from "../../../../../../hooks/useFetchData";

interface Collections {
  collection_id: number,
  collection_name: string,
  collection_desc: string,
  collection_amount: number
};

const FinesTable: React.FC = () => {
  const { sessionUser } = useAuthStore();
  const { dcDataData, fetchDcData } = useDCStore();
  const [selectedRow, setSelectedRow] = useState<Collections | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Collections | null>(null);
  const [complaintModal, setComplaintModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);

  useFetchData(fetchDcData);

  const finedCases = dcDataData.filter((cases: any) => cases.dc_fine > 0);

  const [filteredData, setFilteredData] = useState(finedCases);

  const handleCollectionModal = (rowData: any) => {
    setSelectedRow(rowData);
    setSelectedCase(rowData);
    setComplaintModal(true);
  };

  // Open delete dialog
  const openDeleteDialog = (rowData: any) => {
    setRowToDelete(rowData);
    setDeleteDialogOpen(true);
  };

  const filterPaid = () => {
    setFilteredData(finedCases.filter((dc) => dc.dc_fine_paid > 0));
  };

  const filterUnpaid = () => {
    setFilteredData(finedCases.filter((dc) => dc.dc_fine_paid <= 0));
  };

  const handleCollectionDelete = async () => {
    if (rowToDelete) {
      try {
        await CollectionUtils.DeleteCollection(sessionUser, rowToDelete.collection_id);
        toast.success("Collection deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete collection");
      }
      setDeleteDialogOpen(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "complaint_title",
        header: "Complaint Title",
        size: 100,
      },
      {
        accessorKey: "dc_fine",
        header: "Fines",
        size: 200,
      },
      {
        accessorKey: "customer_phone",
        header: "Description",
        size: 200,
      },
      {
        accessorKey: "dc_fine_paid",
        header: "Fine Collected",
        size: 200,
      },
    ],
    []
  );

  const handleExportRows = (rows: MRT_Row<Collections>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('mrt-pdf-example.pdf');
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        enableRowActions
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleCollectionModal(row.original),
          sx: {
            cursor: "pointer",
          },
        })}
        renderRowActionMenuItems={({ row, table }) => [
          // eslint-disable-next-line react/jsx-pascal-case
          <MRT_ActionMenuItem
            key="delete"
            label="Delete"
            table={table}
            onClick={() => openDeleteDialog(row.original)} icon={undefined} />,
        ]}
        renderTopToolbarCustomActions={({ table }) => (
          <div>
            <Button
              disabled={finedCases.length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export As PDF
            </Button>
            <button className="pageBtngoodSmall" onClick={() => filterPaid()}>
              Paid
            </button>
            <button className="pageBtngoodSmall" onClick={() => filterUnpaid()}>
              Unpaid
            </button>
            <button className="pageBtngoodSmall" onClick={() => setFilteredData(finedCases)}>
              All
            </button>

          </div>
        )}
      />

      {/* CREATE MODAL */}
      {complaintModal && (
        <div className="modalBackground">
          <ResolvedComplainantModal setComplaintModal={setComplaintModal} selectedRow={selectedCase} />
        </div>
      )}
      {/*  */}

      {/* DELETE MODAL */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleCollectionDelete}
        title="Delete Marshal?"
        description={`Are you sure you want to delete ${rowToDelete?.collection_name}?`}
      />
      {/*  */}
    </>
  );
};

export default FinesTable;