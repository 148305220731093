import React, { useState } from 'react';
import ScheduledComTile from './ScheduledComTile';
import { ScheduledCom } from '../../../../../../types/Types';
import ScheduleTileModal from './modals/ScheduleTileModal';
import useAuthStore from '../../../../../../stores/AuthStore';
import useComsStore from '../../../../../../stores/ComsStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

const ScheduledComsContainer = () => {
  const { sessionUser } = useAuthStore();
  const { scheduledComsData, fetchScheduledComsData } = useComsStore();
  const [comModal, setComModal] = useState(false);
  const [selectedCom, setSelectedCom] = useState<ScheduledCom | null>(null);

  useFetchData(fetchScheduledComsData)

  const sentData = scheduledComsData.filter((com: ScheduledCom) => (com.schedule_com_sender)?.toString() === sessionUser?.id);

  const handleScheduleTileClick = (com: ScheduledCom) => {
    setComModal(!comModal);
    setSelectedCom(com);
  };

  return (
    <div className='w-[25vw] borderThemed h-[70vh] rounded-2xl p-2'>
      <div className="flex flex-col h-full">
        <div className="text-lg p-2">Scheduled Communications</div>
        <div className="">

        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {sentData.length > 0 ? (
            sentData.map((com: ScheduledCom) => (
              <div className="" onClick={() => handleScheduleTileClick(com)}>
                <ScheduledComTile key={com.schedule_com_id} com={com} />
              </div>
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>

      {/* MODAL */}
      {comModal &&
        <div className="modalBackground">
          <ScheduleTileModal com={selectedCom} comModal={comModal} handleComTileClick={() => setComModal(false)} />
        </div>
      }
      {/*  */}
    </div>
  );
};

export default ScheduledComsContainer;
