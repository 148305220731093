import React, { useState, ChangeEvent } from "react";
import DcUtils from "../../../../../../../utils/DcUtils";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../../stores/AuthStore";
import useColletionsStore from "../../../../../../../stores/CollectionsStore";
import useDcStore from "../../../../../../../stores/DCStore";

interface PendingCaseModalProps {
  setNewCaseModal: (state: boolean) => void;
  selectedRow: any;
}

const PendingCaseModal: React.FC<PendingCaseModalProps> = ({ setNewCaseModal, selectedRow }) => {
  const { sessionUser } = useAuthStore();
  const { updateCollectorsData } = useColletionsStore();
  const { updateDcData } = useDcStore()

  const [findings, setFindings] = useState<string>('');
  const [fines, setFines] = useState<string>('');
  const [status, setStatus] = useState<string>('Resolved Case');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePageClose = () => {
    setNewCaseModal(false); // Close the modal
  };

  const handleFindingsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFindings(e.target.value);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
  };
  const handelFineChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFines(e.target.value);
  };

  const handleCaseProgress = async () => {
    setIsSubmitting(true);
    setError(null);
    if (!sessionUser) return;

    try {
      await DcUtils.ResolveComplaint(sessionUser, selectedRow.dc_id, findings, status, fines);
      updateCollectorsData(sessionUser);
      toast.success('Case progressed successfully!');
      updateDcData(sessionUser);
      handlePageClose();
    } catch (error) {
      console.error('Failed to progress case:', error);
      setError('Failed to progress case. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="smallModal">
      <div className="modalContent">
        <div className="flex flex-col justify-between">
          <p className="modaltext">Please type in your Pending findings:</p>
          <textarea className="textAreaSmall"
            required
            name="findings"
            id="findings"
            value={findings}
            onChange={handleFindingsChange} // Handling findings input
          />
          <div className="flex">
            <div className="m-2">
              <p className="modaltext">Status</p>
              <select
                name="status"
                id="status"
                className="normalSelectInput"
                value={status}
                onChange={handleStatusChange} // Handling status selection
              >
                <option value="Resolved Case">Resolve</option>
                <option value="Closed Case">Close</option>
              </select>
            </div>

            <div className="m-2">
              <p className="modaltext">Fine</p>
              <input className="normalSelectInput"
                name="fines"
                value={fines}
                onChange={handelFineChange}
                type="number" />
            </div>
          </div>
        </div>
        {error && <p className="errorText">{error}</p>} {/* Display error message */}
      </div>

      <div className="modalBtnFooter">
        <button
          onClick={handleCaseProgress}
          className="pageBtngood"
          disabled={isSubmitting} // Disable the button during submission
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        <button
          onClick={handlePageClose}
          className="pageBtncancel"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PendingCaseModal;
