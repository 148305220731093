import { create } from "zustand";
import { SessionUser, Subscriber, Subscription, SubscriptionPlan } from "../types/Types";
import SubscriptionUtils from "../utils/SubscriptionUtils";

interface SubscriptionsStoreType {
  subscriptionsData: Subscription[];
  subscribersData: Subscriber[];
  subscriptionPlan: SubscriptionPlan[];
  loading: boolean;
  error: string | null;
  fetchSubscriptionsData: (sessionUser: SessionUser) => Promise<void>;
  fetchSubscribersData: (sessionUser: SessionUser) => Promise<void>;
  fetchSubscriptionsPlanData(sessionUser: SessionUser): Promise<void>;
  updateSubscriptionsData(sessionUser: SessionUser): unknown;
  updateSubscribersData(sessionUser: SessionUser): unknown;
  updateSubscriptionsPlanData(sessionUser: SessionUser): unknown;
}

const useSubscriptionsStore = create<SubscriptionsStoreType>((set, get) => ({
  subscriptionsData: [],
  subscribersData: [],
  subscriptionPlan: [],
  loading: false,
  error: null,

  // ----- Subscriptions Data ----- //

  fetchSubscriptionsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateSubscriptionsData(sessionUser);
  },

  updateSubscriptionsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await SubscriptionUtils.FetchSubscriptionData(sessionUser);
      set({ subscriptionsData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch collections",
        loading: false
      });
    }
  },

  // ------- Subscribers Data ----- //

  fetchSubscribersData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateSubscribersData(sessionUser);
  },

  updateSubscribersData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await SubscriptionUtils.FetchSubscribersData(sessionUser);
      set({ subscribersData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch subscribers",
        loading: false
      });
    }
  },

  // ------- Subscription Plans Data ----- //

  fetchSubscriptionsPlanData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateSubscriptionsPlanData(sessionUser);
  },

  updateSubscriptionsPlanData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await SubscriptionUtils.FetchSubscriptionPlan(sessionUser);
      set({ subscriptionPlan: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch subscribers",
        loading: false
      });
    }
  },
}));

export default useSubscriptionsStore;
