import React, { useState } from 'react';
import ComTile from './ComTIle';
import useAuthStore from '../../../../../../stores/AuthStore';
import useComsStore from '../../../../../../stores/ComsStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

const SentComsContainer = () => {
  const { sessionUser } = useAuthStore();
  const { comsData, fetchComsData } = useComsStore(); 
  const [searchTerm, setSearchTerm] = useState('');

  useFetchData(fetchComsData);

  const sentData = comsData.filter((com) => (com.com_sender)?.toString() === sessionUser?.id);

  // Filter the comsData based on the search term
  const filteredComs = sentData.filter(com =>
    com.com_message.toLowerCase().includes(searchTerm.toLowerCase()) ||
    com.com_subject.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='w-[25vw] borderThemed h-[70vh] rounded-2xl p-2'>
      <div className="flex flex-col h-full">
        <div className="text-lg p-2">Sent Communications</div>
        <div className="">
          {/* Search input */}
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {filteredComs.length > 0 ? (
            filteredComs.map(com => (
              <ComTile key={com.com_id} com={com} />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SentComsContainer;
