import React, { useState, useEffect } from "react";
import AddCollectionModal from "./AddCollectionModal";
import CollectorTable from "../tables/CollectorTable";
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import { Collections } from "../../../../../../types/Types";
import toast from "react-hot-toast";
import { useOwner } from "../../../../components/OwnerContext";

interface DriverModalProps {
  setCollectionModal: (state: boolean) => void;
  selectedRow: {
    collection_id: number;
    collection_name: string;
    collection_desc: string;
    collection_amount: number;
  };
}


const CollectionModal: React.FC<DriverModalProps> = ({ setCollectionModal, selectedRow }) => {
  const { sessionUser, fetchCollectionsData } = useOwner();
  const [isEditing, setIsEditing] = useState(false);
  const [addCollectionModal, setAddCollectionModal] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState<Collections | null>(null);

  const [formData, setFormData] = useState({
    collectionId: selectedRow?.collection_id,
    collectionName: selectedRow?.collection_name,
    collectionDesc: selectedRow?.collection_desc,
    collectionAmount: selectedRow?.collection_amount,
  });

  const openDeleteDialog = (selectedRow: any) => {
    setCollectionToDelete(selectedRow);
    setDeleteDialogOpen(true);
  };

  const handleCollectionModalClose = () => {
    setCollectionModal(false);
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // OPERATIONS //

  const handleCollectionUpdate = async () => {
    try {
      await CollectionUtils.UpdateCollection(formData, sessionUser);
      fetchCollectionsData();
      setIsEditing(false);
      setCollectionModal(false);
    } catch (error) {
      toast.error("Failed to update collection");
      console.error(error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleCollectionDelete = async () => {
    if (collectionToDelete) {
      try {
        await CollectionUtils.DeleteCollection(sessionUser, collectionToDelete.collection_id);
        fetchCollectionsData();
        setCollectionModal(false);
        toast.success("Collection deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete collection");
      }
      setDeleteDialogOpen(false);
    }
  };
  // -- // 


  useEffect(() => {
    setFormData({
      collectionId: selectedRow?.collection_id,
      collectionName: selectedRow?.collection_name,
      collectionDesc: selectedRow?.collection_desc,
      collectionAmount: selectedRow?.collection_amount,
    });
  }, [selectedRow]);

  return (
    <div className="mediumModal">

      <div className="modalHeader">
        <h1 className="modalHeading">Collection Information</h1>
      </div>

      <div className="modalContent">
        <div className="h-[100px] rounded-md m-[2px]">
          <div className="flex md:flex-row flex-col">
            <div className="m-2 ">
              <p className="font-semibold">Collection Name</p>
              <input
                className="longTextInput md:normalTextInput"
                name="collectionName"
                value={formData.collectionName}
                onChange={handleInputChange}
                placeholder="Name"
                disabled={!isEditing}
              />
            </div>
            <div className="m-2 ">
              <p className="font-semibold">Collection Amount</p>
              <input
                className="longTextInput md:normalTextInput"
                name="collectionAmount"
                value={formData.collectionAmount}
                type="number"
                onChange={handleInputChange}
                placeholder="Name"
                disabled={!isEditing}
              />
            </div>
            <div className="m-2 ">
              <p className="font-semibold">Collection Description</p>
              <input
                className="longTextInput md:normalTextInput"
                name="collectionDescription"
                value={formData.collectionDesc}
                onChange={handleInputChange}
                placeholder="Description"
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>

        <div className="h-[300px] w-full mt-[200px] md:mt-0">
          <CollectorTable collectionData={selectedRow} />
        </div>

      </div>

      {/* MODAL FOOTER */}
      <div className="modalBtnFooter">
        <button
          onClick={handleCollectionModalClose}
          className="pageBtncancel">
          Close
        </button>
      </div>
      {/*  */}

      {/* Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleCollectionDelete}
        title="Delete Collection?"
        description={`Are you sure you want to delete: ${collectionToDelete?.collection_name}?`}
      />
      {/*  */}

    </div>
  );
};

export default CollectionModal;