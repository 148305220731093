import React, { useState } from 'react';
import { useAdmin } from './AdminContext';
import SidebarItem from '../../../components/common/SidebarItem';
import taxiAdminLogo from '../../../assets/images/TaxiAdmin.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { LuFileText, LuUsers, LuSettings, LuCalendarSearch } from "react-icons/lu";
import { GiReceiveMoney } from "react-icons/gi";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { IoMdBus } from "react-icons/io";
import { GoLaw } from "react-icons/go";
import { IoMenu } from "react-icons/io5";
import { FaPeopleRoof } from 'react-icons/fa6';
import useAccess from '../../../hooks/useUserAccess';

const AdminSidebar: React.FC = () => {
  const { isCollapsed } = useAdmin();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Parse access from the sessionUser
  const access: any =  useAccess();

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <aside>
      {/* DESKTOP SIDEBAR */}
      <nav className="hidden md:flex h-full w-[250px] flex-col justify-between bg-slate-300 duration-300">
        <div className="p-4 pb-2 flex flex-col w-full justify-between">
          <div className="flex items-center justify-between mb-2 h-[50px] w-full">
            <div className="flex items-center h-[40px] w-full cursor-pointer" onClick={() => navigate('/admin/home')}>
              <img
                className={`overflow-hidden transition-all object-contain ${isCollapsed ? "w-0" : "w-12"}`}
                src={taxiAdminLogo} alt="Taxi Admin Logo"
              />
              {!isCollapsed && <p className="ml-2 font-semibold text-center">Taxi Admin</p>}
            </div>
          </div>

          <hr className="mx-4 border-t-[1px] w-[80%]" />

          <div className="flex flex-col justify-center mt-2">
            <SidebarItem title={isCollapsed ? '' : "Dashboard"} link="/admin/home" icon={<LuFileText size={20} color='#30855d' />} isActive={location.pathname === '/admin/home'} />
            {access.Schedule?.view && (
              <SidebarItem title={isCollapsed ? '' : "Schedule"} link="/admin/schedule" icon={<LuCalendarSearch size={20} color='#30855d' />} isActive={location.pathname === '/admin/schedule'} />
            )}
              {access.Transport?.view && (
                <SidebarItem title={isCollapsed ? '' : "Transport"} link="/admin/transport" icon={<IoMdBus size={20} color='#30855d' />} isActive={location.pathname === '/admin/transport'} />
              )}
            {access.Finance?.view && (
              <SidebarItem title={isCollapsed ? '' : "Finance"} link="/admin/finance" icon={<GiReceiveMoney size={20} color='#30855d' />} isActive={location.pathname === '/admin/finance'} />
            )}
            {access.Owners?.view && (
              <SidebarItem title={isCollapsed ? '' : "Owners"} link="/admin/owners" icon={<BsFillPersonVcardFill size={20} color='#30855d' />} isActive={location.pathname === '/admin/owners'} />
            )}
            {access.Staff?.view && (
            <SidebarItem title={isCollapsed ? '' : "Staff"} link="/admin/staff" icon={<FaPeopleRoof size={20} color='#30855d' />} isActive={location.pathname === '/admin/staff'} />
            )}
            {access.Communication?.view && (
              <SidebarItem title={isCollapsed ? '' : "Communication"} link="/admin/communication" icon={<LuUsers size={20} color='#30855d' />} isActive={location.pathname === '/admin/communication'} />
            )}
            {access.DC?.view && (
              <SidebarItem title={isCollapsed ? '' : "DC"} link="/admin/dc" icon={<GoLaw size={20} color='#30855d' />} isActive={location.pathname === '/admin/dc'} />
            )}
          </div>
        </div>

        <div className="flex flex-col items-center p-2">
          <hr className="mx-4 border-t-[1px] w-[80%]" />
          <SidebarItem title={isCollapsed ? '' : "Account"} link="/admin/settings" icon={<LuSettings size={20} color='#30855d' />} isActive={location.pathname === '/admin/settings'} />
        </div>
      </nav>

      {/* MOBILE SIDEBAR */}
      <nav className="md:hidden absolute top-3 left-0 flex items-center justify-between p-2">
        <div className="flex items-center">
          <button onClick={toggleMenu}>
            <IoMenu size={25} />
          </button>
          <img src={taxiAdminLogo} alt="Taxi Admin Logo" className="hidden md:flex ml-2 h-[40px] object-contain" />
        </div>
      </nav>

      <div className={`fixed top-0 left-0 h-full w-[50vw] bg-white transform transition-transform duration-300 z-10 ${menuOpen ? "translate-x-0" : "-translate-x-full"}`}>
        <div className="p-4">
          <img className="w-[100px] mb-4" src={taxiAdminLogo} alt="Logo" />
          <SidebarItem title="Dashboard" link="/admin/home" icon={<LuFileText size={20} color='#30855d' />} isActive={location.pathname === '/admin/home'} />
          {access.Schedule?.view && (
            <SidebarItem title="Schedule" link="/admin/schedule" icon={<LuCalendarSearch size={20} color='#30855d' />} isActive={location.pathname === '/admin/schedule'} />
          )}
          {access.Finance?.view && (
            <SidebarItem title="Finance" link="/admin/finance" icon={<GiReceiveMoney size={20} color='#30855d' />} isActive={location.pathname === '/admin/finance'} />
          )}
          {access.Transport?.view && (
            <SidebarItem title="Transport" link="/admin/transport" icon={<IoMdBus size={20} color='#30855d' />} isActive={location.pathname === '/admin/transport'} />
          )}
          {access.Owners?.view && (
            <SidebarItem title="Owners" link="/admin/owners" icon={<BsFillPersonVcardFill size={20} color='#30855d' />} isActive={location.pathname === '/admin/owners'} />
          )}
          {access.Staff?.view && (
            <SidebarItem title="Owners" link="/admin/owners" icon={<BsFillPersonVcardFill size={20} color='#30855d' />} isActive={location.pathname === '/admin/owners'} />
          )}
          {access.Communication?.view && (
            <SidebarItem title="Communication" link="/admin/communication" icon={<LuUsers size={20} color='#30855d' />} isActive={location.pathname === '/admin/communication'} />
          )}
          {access.DC?.view && (
            <SidebarItem title="DC" link="/admin/dc" icon={<GoLaw size={20} color='#30855d' />} isActive={location.pathname === '/admin/dc'} />
          )}
          <SidebarItem title="Account" link="/admin/settings" icon={<LuSettings size={20} color='#30855d' />} isActive={location.pathname === '/admin/settings'} />
        </div>
      </div>

      {menuOpen && (
        <div className="fixed inset-0 bg-black opacity-50 z-2" onClick={toggleMenu}></div>
      )}
    </aside>
  );
};

export default AdminSidebar;
