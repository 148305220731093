import React, { Suspense, useState } from 'react'
import { HashLoader } from 'react-spinners'
import { Tab, Tabs } from '@mui/material'
import NewCases from './tabs/NewCases'
import PendingCases from './tabs/PendingCases'
import ResolvedCases from './tabs/ResolvedCases'
import ClosedCases from './tabs/ClosedCases'
import AdminLayout from '../../../components/AdminLayout'

function Page() {
  const [driverHistoryModal, setDriverHistoryModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleModalClose = () => {
    setDriverHistoryModal(!driverHistoryModal)
    console.log(driverHistoryModal)
  }

  return (
    <AdminLayout>
      <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
        <div className="flex flex-col items-center  w-full h-full pageHeight">
          <div className="w-full">
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="New Cases" />
              <Tab label="Peding Cases" />
              <Tab label="Resolved Cases" />
              <Tab label="Closed Cases" />
            </Tabs>
            <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
              {currentTab === 0 && <NewCases />}
              {currentTab === 1 && <PendingCases />}
              {currentTab === 2 && <ResolvedCases />}
              {currentTab === 3 && <ClosedCases />}
            </Suspense>
          </div>

        </div>
      </Suspense>
    </AdminLayout>
  )
}

export default Page