import { Coms, SessionUser } from "@/types/Types";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;


export default class CommunicationUtils {



  //-- SMS API --//
  static SendEventAssignedComs = async (sessionUser: any, filteredEvents: any, selectedEvent: any) => {
    try {
      const currentEvent = {
        title: selectedEvent.title,
        start: selectedEvent.startStr,
        end: selectedEvent.endStr
      }

      // Create an array of message objects from the filteredEvents
      const messages = filteredEvents.flatMap((event: any) => [
        {
          message: `Dear ${event.event_assign_driver}, You have been assigned the vehicle '${event.event_assign_vehicle}' for the event '${currentEvent.title}'. 
          The event is starting at ${currentEvent.start} and ending at ${currentEvent.end}.
          Thank You. Taxi Admin`,
          number: event.driver_number,

        },
        {
          message: `Dear ${event.owner_name}, Your vehicle '${event.event_assign_vehicle}' has been assigned to driver ${event.event_assign_driver}. 
          For the Following event: '${currentEvent.title}', Starting at ${currentEvent.start}  and ending at ${currentEvent.end}. Thank You. Taxi Admin`,
          number: event.driver_number,
        }
      ]);

      // Log the messages array for debugging
      console.log("Prepared messages for SMS API:", JSON.stringify(messages, null, 2));

      // Send the messages to the SMS API
      const response = await fetch(`${API_BASE_URL}/api/sms/send_sms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ messages }),
      });

      // Check if the response was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      this.RecordComs(sessionUser, {
        com_subject: `Assignment SMS from ${currentEvent.title} `,
        com_message: JSON.stringify(messages),
        com_type: "SMS",
        com_finance: 0,
        com_owner: 1,
        com_marshal: 0,
        com_staff: 0,
        com_squad: 0,
        com_commuter: 0,
        com_driver: 1

      });


    } catch (error) {
      console.error('Failed to send SMS:', error);
      throw error;
    }
  };

  static SendSmsComm = async (sessionUser: any, selectedRoles: any, { body, recipients }: { body: string; recipients: string[] }) => {
    try {
      // Create an array of message objects from the recipients and body
      const messages = recipients.map((recipient: string) => ({
        message: body,
        number: recipient
      }));

      // Log the messages array for debugging
      console.log("Prepared messages for SMS API:", JSON.stringify(messages, null, 2));

      // Send the messages to the SMS API
      const response = await fetch(`${API_BASE_URL}/api/sms/send_sms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ messages }),
      });

      // Check if the response was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      this.RecordComs(sessionUser, {
        com_subject: `SMS from ${sessionUser.name} `,
        com_message: body,
        com_type: "SMS",
        com_finance: selectedRoles.com_finance,
        com_owner: selectedRoles.com_owner,
        com_marshal: selectedRoles.com_marshal,
        com_staff: selectedRoles.com_staff,
        com_squad: selectedRoles.com_squad,
        com_commuter: selectedRoles.com_commuter,
        com_driver: selectedRoles.com_driver
      })


    } catch (error) {
      console.error('Failed to send SMS:', error);
      throw error;
    }
  };


  //-- SENT EMAIL  --//
  static async SendEmails(sessionUser: any, formData: any) {
    try {
      const { recipients, selectedRoles } = formData;

      // Filter out invalid email addresses
      const validRecipients = recipients.filter((email: string | null) => email && email.includes('@'));
      if (validRecipients.length === 0) {
        throw new Error('No valid recipients provided.');
      }

      // Prepare the email payload
      const payload = {
        subject: formData.subject,
        body: formData.body,
        recipients: validRecipients,
      };



      // Send the emails to the API
      const emailResponse = await fetch(`${API_BASE_URL}/api/email/send_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!emailResponse.ok) {
        throw new Error('Failed to send emails');
      }

      try {
        this.RecordComs(sessionUser, {
          com_subject: formData.subject,
          com_message: formData.body,
          com_type: "Email",
          com_finance: selectedRoles.com_finance,
          com_owner: selectedRoles.com_owner,
          com_marshal: selectedRoles.com_marshal,
          com_staff: selectedRoles.com_staff,
          com_squad: selectedRoles.com_squad,
          com_commuter: selectedRoles.com_commuter,
          com_driver: selectedRoles.com_driver
        })

      } catch (error) {
        throw new Error('Failed to add communication to the database' + error);
      }

      console.log("Emails sent and communication saved successfully");

    } catch (error) {
      console.error('Error in sending emails and adding communication:', error);
      throw error;
    }
  };


  static RecordComs = async (sessionUser: any, formData: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/coms/create_coms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Failed to record communication:', error);
      throw error;
    }
  };



  static findEmails = async (sessionUser: any) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/email/find_emails`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    }
  }

  static fetchComs = async (sessionUser: any) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/coms/get_coms`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    }
  };

  static SendAppComm = async (sessionUser: any, communicationData: Coms) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/coms/create_coms`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify(communicationData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  };

  static fetchScheduledComs = async (sessionUser: any) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/coms/get_scheduled_coms`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok @ fetchScheduledComs');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch scheduled coms:', error);
      }
    }
  };

  static deleteScheduledCom = async (sessionUser: any, schedule_com_id: number) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/coms/delete_scheduled_coms`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ schedule_com_id: schedule_com_id }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok @ deleteScheduledCom');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch scheduled coms:', error);
      }
    }
  };


  static ScheduleComm = async (sessionUser: SessionUser, comDetails: any) => {
    try {

      const response = await fetch(`${API_BASE_URL}/api/coms/schedule_com`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ comDetails }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // console.log(comDetails)

    } catch (error) {
      console.error('Failed to send SMS:', error);
      throw error;
    }
  };







}