import React from 'react'
import SubscriptionTable from '../tables/SubscriptionTable'
import { AdminProvider } from '../../../../components/AdminContext'
import AdminLayout from '../../../../components/AdminLayout'

const Subscription = () => {
  return (
    <AdminProvider>
      <AdminLayout>
        <div className="p-2">
          <SubscriptionTable />
        </div>
      </AdminLayout>

    </AdminProvider>
  )
}

export default Subscription