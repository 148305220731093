import React, { Suspense, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem } from "material-react-table";
import {  Staff } from "../../../../../../types/Types";
import { HashLoader } from "react-spinners";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import toast from "react-hot-toast";
import StaffUtils from "../../../../../../utils/StaffUtils";
import StaffCreate from "../modals/CreateStaffModal";
import StaffModal from "../modals/StaffModal";
import useStaffStore from "../../../../../../stores/StaffStore";
import useAuthStore from "../../../../../../stores/AuthStore";
import { useFetchData } from "../../../../../../hooks/useFetchData";

const StaffTable: React.FC = () => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { staffData, fetchStaffData, updateStaffData  } = useStaffStore();
  // States
  const [createStaffModal, setCreateStaffModal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Staff | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Staff | null>(null);

  useFetchData(fetchStaffData);

  // Handle modal display
  const handleMarshalModal = (rowData: Staff) => {
    setSelectedRow(rowData);
    setStaffModal(true);
  };

  const handleCreateMarshalModal = () => {
    setCreateStaffModal(!createStaffModal);
  };

  // Open delete dialog
  const openDeleteDialog = (rowData: Staff) => {
    setRowToDelete(rowData);
    setDeleteDialogOpen(true);
  };

  // Handle driver deletion
  const handleStaffDelete = async () => {
    if (rowToDelete && sessionUser) {
      try {
        await StaffUtils.DeleteStaff(sessionUser, rowToDelete.staff_id);
        updateStaffData(sessionUser);
        toast.success("Staff deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Staff to delete marshal");
      }
    }
    setDeleteDialogOpen(false);
  };

  // Define columns for the table
  const columns = useMemo(
    () => [
      {
        accessorKey: "staff_name",
        header: "Full Name",
        size: 100,
      },
      {
        accessorKey: "staff_surname",
        header: "Surname",
        size: 100,
      },
      {
        accessorKey: "staff_phone",
        header: "Phone Number",
        size: 200,
      },
      {
        accessorKey: "staff_title",
        header: "Title",
        size: 100,
      },
      {
        accessorKey: "staff_status",
        header: "Status",
        size: 100,
      },
      {
        accessorKey: "staff_email",
        header: "email",
        size: 100,
      },
    ],
    []
  );

  return (
    <>
      <div className="bg-transparent">
        <MaterialReactTable
          columns={columns}
          data={staffData}
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleMarshalModal(row.original),
            sx: {
              cursor: "pointer",
              backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent row background
            },
          })}
          renderTopToolbarCustomActions={() => (
            <div className="flex">
              <button className="pageBtngood" onClick={handleCreateMarshalModal}>
                Add Staff
              </button>
            </div>
          )}
          renderRowActionMenuItems={({ row, table }) => [
            // eslint-disable-next-line react/jsx-pascal-case
            <MRT_ActionMenuItem
              key="delete"
              label="Delete"
              icon={undefined}
              table={table}
              onClick={() => openDeleteDialog(row.original)}
            />,
          ]}
        />
      </div>

      {selectedRow && (
        <div className={`${staffModal ? "modalBackground" : "hidden"}`}>
          <StaffModal setStaffModal={setStaffModal} selectedRow={selectedRow} />
        </div>
      )}

      {createStaffModal && (
        <Suspense fallback={<div><HashLoader color="#36d7b7" /></div>}>
          <div className="modalBackground">
            <StaffCreate setCreateStaffModal={setCreateStaffModal} />
          </div>
        </Suspense>
      )}

      {/* Render Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleStaffDelete}
        title="Delete Staff?"
        description={`Are you sure you want to delete ${rowToDelete?.staff_name}?`}
      />
      {/*  */}

    </>
  );
};

export default StaffTable;
