import { MaterialReactTable, MRT_ColumnDef, MRT_Row } from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { useFetchData } from '../../../../../../../hooks/useFetchData';
import { Button } from '@mui/material';
import ExpenseAdd from '../ExpenseAdd';
import useEventFinanceStore from '../../../../../../../stores/EventFinanceStore';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import EventExpenseModal from '../EventExpenseModal';

interface EventExpenseProps {
  selectedEvent: Event;
}

const EventExpense: React.FC<EventExpenseProps> = ({ selectedEvent }) => {
  const [addExpenseModal, setAddExpenseModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const { eventFinanceData, fetchEventFinanceData } = useEventFinanceStore();

  useFetchData(fetchEventFinanceData);

  const relevantData = eventFinanceData.filter((item) => `${item.event_finances_eventID}` === selectedEvent.id);

  const handleAddExpenseModal = () => {
    setAddExpenseModal(true);
  };

  const handleExpenseModal = (row: any) => {
    setSelectedRow(row);
    setExpenseModal(true);
  };

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => [
      row.original.event_finance_name,
      row.original.event_finance_desc,
      row.original.event_finance_amount,
    ]);
    const tableHeaders = columns.map((c) => c.header || "");

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      theme: "grid",
      styles: {
        minCellHeight: 10,
        halign: "left",
        valign: "middle",
      },
      didParseCell: function (data) {
        if (data.section === "head") {
          data.cell.styles.halign = "center";
        }
      },
    });

    doc.save(`Event Expense Table ${Date.now()}.pdf`);
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    {
      accessorKey: "event_finance_name",
      header: "Name",
      size: 200,
    },
    {
      accessorKey: "event_finance_desc",
      header: "Description",
      size: 100,
    },
    {
      accessorKey: "event_finance_amount",
      header: "Amount",
      size: 100,
    },
  ], []);

  return (
    <div className='w-full h-full'>
      <MaterialReactTable
        columns={columns}
        data={relevantData}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleExpenseModal(row.original),
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="flex">
            <Button
              disabled={eventFinanceData.length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export As PDF
            </Button>
            <button className="pageBtngood" onClick={handleAddExpenseModal}>
              Add Expense
            </button>
          </div>
        )}
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
      />

      {addExpenseModal && selectedEvent && (
        <div className="modalBackground">
          <ExpenseAdd
            handleAddExpenseModal={() => setAddExpenseModal(false)}
            selectedEvent={selectedEvent}
          />
        </div>
      )}

      {expenseModal && (
        <div className="modalBackground">
          <EventExpenseModal
            setAddExpenseModal={() => setExpenseModal(false)}
            selectedRow={selectedRow}
          />
        </div>
      )}
    </div>
  );
};

export default EventExpense;
