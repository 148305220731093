import React from 'react'
import SecretaryLayout from '../../../../components/AdminLayout'
import FinesTable from '../tables/FinesTable'
import { AdminProvider } from '../../../../components/AdminContext'

const Fines = () => {
  return (
    <AdminProvider>
      <SecretaryLayout>
        <div className="p-2">
          <FinesTable />
        </div>
      </SecretaryLayout>

    </AdminProvider>
  )
}

export default Fines