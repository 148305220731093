import React from 'react';
import { Suspense } from 'react';
import { HashLoader } from 'react-spinners';
import DashboardTile from '../../../../../components/dashboard/dashboardCard';
import { MdEvent, MdOutlineDirectionsBus } from 'react-icons/md';
import { FaRegAddressCard } from 'react-icons/fa';
import { GrMoney } from 'react-icons/gr';
import NotificationTile from './components/NotificationTile';
import { useOwner } from '../../../components/OwnerContext';

const DashboardPage = (): JSX.Element => {
  const { sessionUser, activeVehicles, activeDrivers, notificationData, collectionsData, eventData } = useOwner();


  // Sort notifications by the latest time
  const sortedNotifications = [...notificationData].sort((a, b) => {
    return new Date(b.noti_date).getTime() - new Date(a.noti_date).getTime();
  });

  // COLLECTIONS //
  const totalCollection = collectionsData.reduce((acc, collection) => acc + collection.collection_amount, 0);
  //--//

  // EVENTS //
  const totalEvents = eventData.length;
  //--//

  return (
    <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
      <div className="flex flex-col rounded-2xl pageHeight">

        <div className="flex items-center h-[20vh] borderThemed rounded-md">
          <h1 className="text-xl font-bold p-2">Welcome back, {sessionUser?.name}</h1>
          <p className='px-2'>{new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', hour12: true }).format(new Date())}</p>
        </div>

        <div className="flex flex-row justify-between w-full">

          {/* HEADER ICONS */}
          <div className="flex flex-row justify-around w-full">
            <div className="flex flex-col justify-between my-2 w-[60%] items-center">
              <DashboardTile title={"Active Vehicles"} period={'Today'} icon={<MdOutlineDirectionsBus size={40} />} figure={activeVehicles} percentage={"10%"} suffix={"Vehicles"} navigateTo={'/owner/transport'} />
              <DashboardTile title={"Active Drivers"} period={'Today'} icon={<FaRegAddressCard size={30} />} figure={activeDrivers} percentage={"10%"} suffix={"Drivers"} navigateTo={'/owner/home'} />
            </div>

            <div className="flex flex-col justify-between my-2 w-[60%] items-center">
              <DashboardTile title={"Collections"} period={'Monthly'} icon={<GrMoney size={30} />} figure={`R${totalCollection.toFixed(2)}`} percentage={"10%"} suffix={""} navigateTo='/owner/home' />
              <DashboardTile title={"Events"} period={'Events'} icon={<MdEvent size={30} />} figure={totalEvents} percentage={"10%"} suffix={"Events"} navigateTo='/owner/schedule' />
            </div>
          </div>
          {/*  */}

          {/* NOTIFICATION SECTION */}
          <div className="borderThemed rounded-xl w-[50%] overflow-y-auto h-[56vh] my-4">
            <h4 className="text-xl font-bold p-2 text-center">Notifications</h4>
            <hr />
            {sortedNotifications.map((notification) => (
              <NotificationTile key={notification.id} notification={notification} />
            ))}
          </div>
          {/*  */}

        </div>
      </div>
    </Suspense>
  );
};

export default DashboardPage;
