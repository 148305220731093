import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Button} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RecCollectionModal from "../modals/RecCollectionModal";
import { Collectors } from "../../../../../../types/Types";
import AddCollectionModal from "../modals/AddCollectionModal";
import useColletionsStore from "../../../../../../stores/CollectionsStore";
import { useFetchData } from "../../../../../../hooks/useFetchData";

interface CollectorTableProps {
  collectionData: any;
}

const CollectorTable: React.FC<CollectorTableProps> = ({ collectionData }) => {
  // Stores
  const { collectorsData, fetchCollectorsData } = useColletionsStore();
  // States
  const [recordCollectionModal, setRecordCollectionModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Collectors | null>(null);
  const [addCollectionModal, setAddCollectionModal] = useState(false);
  const [filteredData, setFilteredData] = useState(collectorsData);

  useFetchData(fetchCollectorsData);

  useEffect(() => {
    setFilteredData(collectorsData);
  }, [collectorsData]);

  const filterPaid = () => {
    setFilteredData(collectorsData.filter(collector => collector.collectors_amount > 0));
  };

  const filterUnpaid = () => {
    setFilteredData(collectorsData.filter(collector => collector.collectors_amount === 0));
  };
  const filterAll = () => {
    setFilteredData(collectorsData);
  };

  const handleAddNewCollectionModal = () => {
    setAddCollectionModal(!addCollectionModal);
  };


  const filteredCollectorsData = useMemo(() => {
    const collection_id = collectionData.collection_id;
    return collectorsData.filter(collector => collector.collection_id === collection_id);
  }, [collectorsData, collectionData]);

  const handleRecordCollectionModal = (rowData: Collectors) => {
    setSelectedRow(rowData);
    setRecordCollectionModal(true);
  };

  const columns = useMemo<MRT_ColumnDef<Collectors>[]>(() => [
    {
      accessorKey: "user",
      header: "User",
      size: 200,
    },
    {
      accessorKey: "vehicle_collection",
      header: "Vehicle Collection",
      size: 100,
      Cell: ({ cell }) => `R ${cell.getValue<number>() || ' --- '}`,
    },
    {
      accessorKey: "owner_collection",
      header: "Owner Collection",
      size: 100,
      Cell: ({ cell }) => `R ${cell.getValue<number>() || ' --- '}`,
    },
    {
      accessorKey: "collectors_amount",
      header: "Collected Amount",
      size: 200,
      Cell: ({ cell }) => `R ${cell.getValue<number>()?.toFixed(2) || ' --- '}`,
    },
  ], []);

  const handleExportRows = (rows: MRT_Row<Collectors>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => [
      row.original.user,
      row.original.collection_id,
      `R ${row.original.collectors_amount.toFixed(2)}`,
    ]);
    const tableHeaders = columns.map((c) => c.header || '');

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      theme: 'grid',
      styles: {
        minCellHeight: 10,
        halign: 'left',
        valign: 'middle',
      },
      didParseCell: function (data) {
        if (data.section === 'head') {
          data.cell.styles.halign = 'center';
        }
      },
    });

    doc.save(`Collection_${collectionData.collection_name}.pdf`);
  };

  // Filter functions




  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={filteredData || []}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRecordCollectionModal(row.original)
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="flex">
            <Button
              disabled={filteredCollectorsData.length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export As PDF
            </Button>
            <button className="pageBtngood" onClick={handleAddNewCollectionModal}>
              New Collector
            </button>
            <button className="pageBtngoodSmall" onClick={filterPaid}>
              Paid
            </button>
            <button className="pageBtngoodSmall" onClick={filterUnpaid}>
              Unpaid
            </button>
            <button className="pageBtngoodSmall" onClick={filterAll}>
              All
            </button>
          </div>
        )}
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
      />

      {recordCollectionModal && selectedRow && (
        <div className="modalBackground">
          <RecCollectionModal
            setRecordCollectionModal={setRecordCollectionModal}
            selectedRow={selectedRow}
          />
        </div>
      )}

      <div className={`${addCollectionModal ? "modalBackground" : "hidden"}`}>
        <AddCollectionModal setAddCollectionModal={setAddCollectionModal} collectionData={collectionData} />
      </div>
    </>
  );
};

export default CollectorTable;
