import React, { useState } from 'react';
import CollectionTile from './tile/CollectionTile';
import CollectionModal from '../modals/CollectionModal';
import { Collections } from '../../../../../../types/Types';
import { useOwner } from '../../../../components/OwnerContext';

const CollectionContainer = () => {
  const { collectionsData } = useOwner();
  const [collectionModal, setCollectionModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<Collections | null>(null);

  const ownerCollections = collectionsData.filter(collection => collection.owner_collection === 1);

  const handleCollectionClick = (collection: Collections) => {
    setSelectedCollection(collection);
    setCollectionModal(true);
  };

  return (
    <div className='w-[25vw] borderThemed h-[70vh] rounded-2xl p-2'>
      <div className="flex flex-col h-full">
        <div className="text-lg p-2">Collections</div>
        <div className="">
          {/* You can add content here if needed */}
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {ownerCollections && ownerCollections.length > 0 ? (
            ownerCollections.map(collection => (
              <CollectionTile
                key={collection.collection_id}
                collection={collection}
                onClick={handleCollectionClick}
              />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
      {collectionModal && (
        <div className="modalBackground">
          <CollectionModal selectedRow={selectedCollection} setCollectionModal={setCollectionModal} />
        </div>
      )}
    </div>
  );
};

export default CollectionContainer;