import CommonUtils from "./CommonUtils";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API; 
export default class DriverUtils {

  // FETCH DRIVER DATA USING THE ASSOCIATION
  static FetchDriverData = async( sessionUser:any) =>{
      if (sessionUser.association) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/driver/get_drivers`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          return(data);
        } catch (error) {
          console.error('Failed to fetch drivers:', error);
          throw error;
        }
      }
  }

      // UPDATE/EDIT DRIVER DETAILS
  static UpdateDriverDetails = async( formData: any, sessionUser: any) => 
    {
      if (sessionUser?.association) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/driver/update_driver`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({
              ...formData,
              association: sessionUser.association,
            }),
          });
    
          if (!response.ok) {
            throw new Error('Failed to update driver information');
          }
        } catch (error) {
          console.error('Error updating driver:', error);
          throw error;
        }
      }
  };

    static CreateNewDriver = async (sessionUser:any,formData:any) => {
        if (sessionUser.association) {
          try {
            const driverId = CommonUtils.GenerateRandomString();
            const response = await fetch(`${API_BASE_URL}/api/driver/create_driver`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionUser.accessToken}`,
              },
              body: JSON.stringify({ ...formData, driver_id: driverId, association: sessionUser.association, ownerId: formData.ownerId }),
            });
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            console.log('Driver created:');
          } catch (error) {
            console.error('Error creating driver:', error);
            throw error;
          }
        }
      }

      // THIS IS TO DELETE THE DRIVER
    static DeleteDriver = async (sessionUser:any,driverId:any,) => {
      try {
        if (sessionUser?.association) {
          const response = await fetch(`${API_BASE_URL}/api/driver/delete_driver`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ driverId: driverId}),
          });

          if (!response.ok) {
            console.error('Error deleting driver:', response.statusText);
            throw new Error('Network response was not ok');
          }
        }
      } catch (error) {
        console.error('Error deleting driver:', error);
        throw error;
      }
    }

  // OWNER CONTROLS 
  static FetchOwnerDriverData = async(sessionUser:any,) =>{
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/driver/get_owner_drivers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return(data);
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  }

  static UpdateOwnerDriverDetails = async( formData: any, sessionUser: any, ) => {
      if (sessionUser?.association) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/driver/update_owner_driver`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ 
              driverId: formData.driverId,
              driverVehicle: formData.vehicleAssigned,
              driverStatus: formData.driverStatus,
            }),
          });
    
          if (!response.ok) {
            throw new Error('Failed to update driver information');
          }
        } catch (error) {
          console.error('Error updating driver:', error);
          throw error;
        }
      }
  };
}