import React, { useState } from 'react';
import { Subscription } from '../../../../../../types/Types';
import SubscriptionTile from './tile/SubscriptionTile';
import SubscriptionModal from '../modals/subscriptionModal';
import useSubscriptionStore from '../../../../../../stores/SubscribersStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

const SubscriptionsContainer = () => {
  const { subscriptionsData, fetchSubscriptionsData} = useSubscriptionStore();
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<Subscription | null>(null);

  const sortedSubscriptions = subscriptionsData.sort((a, b) => new Date(b.subscription_made).getTime() - new Date(a.subscription_made).getTime());

  const handleSubscriptionClick = (subscription: Subscription) => {
    setSelectedCollection(subscription);
    setSubscriptionModal(true);
  };

  useFetchData(fetchSubscriptionsData);

  const handleSubscriptionTableClick = () => {
    window.location.href = '/admin/finance/subscription';
  };

  return (
    <div className='md:w-[35vw] borderThemed h-[50vh] rounded-2xl p-2 my-2'>
      <div className="flex flex-col h-full">
        <div className="cursor-pointer" onClick={handleSubscriptionTableClick}>
          <div className="text-lg p-2">Subscriptions</div>
          {/* You can add content here if needed */}
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {sortedSubscriptions && sortedSubscriptions.length > 0 ? (
            sortedSubscriptions.map(subscription => (
              <SubscriptionTile
                key={subscription.subscription_id}
                subscription={subscription}
                onClick={handleSubscriptionClick}
              />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
      {subscriptionModal && (
        <div className="modalBackground">
          <SubscriptionModal selectedRow={selectedCollection} setSubscriptionModal={setSubscriptionModal} />
        </div>
      )}
    </div>
  );
};

export default SubscriptionsContainer;
