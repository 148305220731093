import { Driver,Vehicle } from '../types/Types';

export default class ReportUtils {

  static ActiveVehicles(vehicles: Vehicle[]): number {
    let activeCount = 0;
    for (let vehicle of vehicles) {
        if (vehicle.vehicle_status === "Active") {
            activeCount += 1;
        }
    }
    return activeCount;
  }

  static ActiveDrivers(drivers: Driver[]): number {
    let activeCount = 0;
    for (let driver of drivers) {
        if (driver.driver_status === "Active") {
            activeCount += 1;
        }
    }
    return activeCount;
  }

}