import React from 'react'
import { useState, useMemo, lazy, Suspense } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';

import EventTile from '../../../../../../components/common/eventTile';
import DateUtils from '../../../../../../utils/DateUtils';
import EventModal from '../modals/EventsModal';
import { HashLoader } from 'react-spinners';
import useEventStore from '../../../../../../stores/EventStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';
import useAccess from '../../../../../../hooks/useUserAccess';

const CreateEvents = lazy(() => import('../../../components/schedule/modals/EventsCreate'));

function Schedule() {
  const { eventData, fetchEventData } = useEventStore();
  const access: any =  useAccess();
  
  useFetchData(fetchEventData);

  // HOOKS
  const [eventModal, setEventModal] = useState(false);
  const [createEventModal, setCreateEventModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedEvent, setSelectedEvent] = useState<{
    id: number;
    title: string;
    startStr: Date;
    endStr: Date;
    extendedProps: any;
  } | null>(null);

  const formattedData = useMemo(() => {
    return DateUtils.CalendarEventFormat(eventData);
  }, [eventData]);

  const today = new Date();

  const formattedUpcomingData = useMemo(() => {
    return DateUtils.CalendarEventFormat(eventData)
      .filter(event => new Date(event.start) >= today)
      .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  }, [eventData]);

  // FUNCTIONS
  const handleDateClick = (selected: any) => {
    setCreateEventModal(true);
    setSelectedDate(selected);
  };

  const handleEventClick = (selected: any) => {
    const mappedEvent = {
      id: selected.event.id,
      title: selected.event.title,
      startStr: selected.event.startStr,
      endStr: selected.event.endStr,
      eventCategory: selected.event.extendedProps.category,
      extendedProps: selected.event.extendedProps,
    };
    setSelectedEvent(mappedEvent);
    setEventModal(true);
  };

  const handleCloseEventModal = () => {
    setEventModal(false);
    setSelectedEvent(null);
  };

  const handleCloseCreateEventModal = () => {
    setCreateEventModal(false);
    setSelectedDate(null);
  };

  return (

    <div className="flex md:flex-row md:justify-between flex-col p-4">

      <div className=" md:w-[60vw] ">
        <FullCalendar
          expandRows={true}
          timeZone="UTC"
          height={"70vh"}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          headerToolbar={{ left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth' }}
          initialView="dayGridMonth"
          selectable={true}
          select={handleDateClick}
          eventClick={handleEventClick}
          weekNumbers={true}
          events={formattedData}
          nowIndicator={true}
        />
      </div>

      {/* CALENDAR SIDEBAR */}
      <div className="flex flex-col w-full md:w-[18vw] p-2">
        <p className="text-[20px] text-center">Upcoming Events</p>
        {formattedUpcomingData?.length === 0 && <p className="text-center">No upcoming events</p>}
        <div className="borderThemed h-[60vh] rounded-2xl mt-[20px] overflow-y-auto">
          {formattedUpcomingData?.map((event: any) => (
            <EventTile key={event.id} event={event} />
          ))}
        </div>
      </div>
      {/* CALENDAR COMPONENT */}


      {/* EVENT MODAL */}
      {eventModal && selectedEvent && (
        <div className="modalBackground">
          <EventModal setEventModal={handleCloseEventModal} selectedEvent={selectedEvent} />
        </div>
      )}
      {/* EVENT CREATE MODAL */}
      {access.Finance?.edit && createEventModal && selectedDate && (
        <div className="modalBackground">
          <Suspense fallback={<div><HashLoader color="#36d7b7" /></div>}>
            <CreateEvents setCreateEventModal={handleCloseCreateEventModal} selectedDate={selectedDate} />
          </Suspense>
        </div>
      )}
    </div>

  )
}

export default Schedule