import React from 'react';
import DashboardPage from './Pages/dashboard/DashboardPage';
import { OwnerProvider } from '../components/OwnerContext';
import OwnerLayout from '../components/OwnerLayout';

const OwnerDash = (): JSX.Element => {

    return (
        <OwnerProvider>
            <OwnerLayout>
                <div className="">
                    <DashboardPage />
                </div>
            </OwnerLayout>
        </OwnerProvider>
    );
};

export default OwnerDash;