import React, { useState } from 'react';
import { Complaint, } from '../../../../../../types/Types';
import ResolvedComplainantModal from '../../dc/modal/ResolvedComplainantModal';
import DcFinesTile from './tile/DcFinesTile';
import useDCStore from '../../../../../../stores/DCStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

const DcFinesContainer = () => {
  const { dcDataData, fetchDcData  } = useDCStore(); 
  const [complaintModal, setComplaintModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Complaint | null>(null);

  useFetchData(fetchDcData)

  const handleSubscriptionClick = (complaint: Complaint) => {
    setSelectedCase(complaint);
    setComplaintModal(true);
  };

  const handleFinesTableClick = () => {
    window.location.href = '/admin/finance/fines';
  };

  const finedCases = dcDataData.filter((cases: any) => cases.dc_fine > 0);

  return (
    <div className='md:w-[35vw] borderThemed h-[50vh] rounded-2xl p-2 my-2'>
      <div className="flex flex-col h-full">
        <div className="cursor-pointer" onClick={handleFinesTableClick}>
          <div className="text-lg p-2">Fines</div>
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {finedCases && finedCases.length > 0 ? (
            finedCases.map(fined => (
              <DcFinesTile
                key={fined.dc_id}
                fined={fined}
                onClick={handleSubscriptionClick}
              />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
      {complaintModal && (
        <div className="modalBackground">
          <ResolvedComplainantModal setComplaintModal={setComplaintModal} selectedRow={selectedCase} />
        </div>
      )}
    </div>
  );
};

export default DcFinesContainer;
