import React from 'react';
import { Complaint } from '../../../../../../../types/Types';
import { useOwner } from '../../../../../components/OwnerContext';

interface DcFinesTileProps {
  fined: Complaint;
  onClick: (fined: Complaint) => void;
}

const DcFinesTile: React.FC<DcFinesTileProps> = ({ fined, onClick }) => {
  const { driverData } = useOwner();
  const finedDate = new Date(fined.dc_date).toDateString();

  const finedDriver = driverData.find(driver => driver.driver_id === `${fined.dc_driver}`);

  const handleComTileClick = () => {
    onClick(fined);
  };

  return (
    <div onClick={handleComTileClick} className="flex flex-row borderThemed justify-between p-2 rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200">
      <div className="w-[60%]">
        <p className="modaltextsmallBold">Fined: {fined?.complaint_title}</p>
        <h3 className="modaltextsmallBold">{finedDriver?.driver_name}</h3>
        <p className="modaltextsmallBold">{fined.complaint_title}</p>
      </div>
      <div className="w-[40%]">
        <p className="modaltextsmallBold">{finedDate}</p>
      </div>
    </div>
  );
};

export default DcFinesTile;
