import React, { useState, useEffect } from "react";
import PerSubscriptionTable from "../tables/PerSubsriptionTable";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import { Subscription } from "../../../../../../types/Types";
import toast from "react-hot-toast";
import SubscriptionUtils from "../../../../../../utils/SubscriptionUtils";
import CommonUtils from "../../../../../../utils/CommonUtils";
import  useAuthStore  from "../../../../../../stores/AuthStore";
import  useSubscriptionsStore  from "../../../../../../stores/SubscribersStore";

interface SubscriptionModalProps {
  setSubscriptionModal: (state: boolean) => void;
  selectedRow: {
    subscription_id: string;
    subscription_name: string;
    subscription_desc: string;
    subscription_period: string;
    subscription_status: string;
    subscriptions_start: string;
    subscriptions_due: string;
    subscription_made: string;
  };
};

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ setSubscriptionModal, selectedRow }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { updateSubscribersData, updateSubscriptionsData } = useSubscriptionsStore();
  // States
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<Subscription | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    subscription_id: selectedRow.subscription_id,
    subscription_name: selectedRow.subscription_name,
    subscription_desc: selectedRow.subscription_desc,
    subscription_period: selectedRow.subscription_period,
    subscription_status: selectedRow.subscription_status,
    subscriptions_start: new Date(selectedRow.subscriptions_start),
    subscriptions_due: new Date(selectedRow.subscriptions_due),
    subscription_made: new Date(selectedRow.subscription_made),
  });

  const openDeleteDialog = (selectedRow: any) => {
    setSubscriptionToDelete(selectedRow);
    setDeleteDialogOpen(true);
  };

  const handleCollectionModalClose = () => {
    setSubscriptionModal(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  // OPERATIONS //

  const handleSubscriptionUpdate = async () => {
    if (!sessionUser) return;
    try {
      await SubscriptionUtils.UpdateSubscription(sessionUser, formData);
      updateSubscribersData(sessionUser);
      updateSubscriptionsData(sessionUser);
      setSubscriptionModal(false);
      toast.success("Subscription updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update subscription. Please try again.");
    }
  };

  const handleSubscriptionDelete = async () => {
    if (!sessionUser) return;
    try {
      await SubscriptionUtils.DeleteSubscription(sessionUser, subscriptionToDelete?.subscription_id);
      updateSubscriptionsData(sessionUser);
      toast.success("Subscription deleted successfully");
      setDeleteDialogOpen(false);
      setSubscriptionModal(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete subscription. Please try again.");
    }
  };

  const dueDate = CommonUtils.formatDate(formData.subscriptions_due);

  // --- //

  useEffect(() => {
    setFormData({
      subscription_id: selectedRow.subscription_id,
      subscription_name: selectedRow.subscription_name,
      subscription_desc: selectedRow.subscription_desc,
      subscription_period: selectedRow.subscription_period,
      subscriptions_due: new Date(selectedRow.subscriptions_due),
      subscription_made: new Date(selectedRow.subscription_made),
      subscription_status: selectedRow.subscription_status,
      subscriptions_start: new Date(selectedRow.subscriptions_start),
    });
  }, [selectedRow]);

  return (
    <div className="largeModal">
      <div className="modalHeader">
        <h1 className="modalHeading">Subscription Information</h1>
      </div>

      <div className="modalContent">
        <div className="h-[100px] rounded-md m-[2px]">
          <div className="flex">
            <div className="m-2">
              <p className="modaltext">Subscription Name</p>
              <input
                name="subscription_name"
                value={formData.subscription_name}
                onChange={handleInputChange}
                placeholder="Name"
                className="normalTextInput"
              />
            </div>
            <div className="m-2">
              <p className="modaltext">Subscription Description</p>
              <input
                name="subscription_desc"
                value={formData.subscription_desc}
                onChange={handleInputChange}
                placeholder="Description"
                className="longTextInput"
              />
            </div>
            <div className="">
              <p>
                Next Due: {dueDate}
              </p>
              <p>
                Status: {formData.subscription_status}
              </p>
              <p>
                interval: {formData.subscription_period}
              </p>
              <p>
                Subscription Made: {CommonUtils.formatDate(formData.subscription_made)}
              </p>
            </div>
          </div>
        </div>
        <div className="h-[300px] w-full">
          <PerSubscriptionTable subscriptionData={selectedRow} />
        </div>
      </div>

      <div className="modalBtnFooter">
        <button className="pageBtnWarn" onClick={() => openDeleteDialog(selectedRow)}>
          Delete
        </button>

        <button className="pageBtngood" onClick={handleSubscriptionUpdate}>
          Save
        </button>

        <button className="pageBtncancel" onClick={handleCollectionModalClose}>
          Close
        </button>
      </div>

      {/* Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleSubscriptionDelete}
        title="Delete Subscription?"
        description={`Are you sure you want to delete: ${subscriptionToDelete?.subscription_name}?`}
      />
    </div>
  );
};

export default SubscriptionModal;
