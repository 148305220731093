import React from "react";
import { useNavigate } from "react-router-dom";

interface DashboardTileProps {
  title: string;
  icon: any;
  period: string;
  figure: number | string;
  percentage: string;
  suffix: string;
  navigateTo: string;
}

const DashboardTile: React.FC<DashboardTileProps> = ({ title, icon, figure, suffix, period, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <section className="flex items-center justify-center p-2 w-[200px] md:w-[20vw] h-[120px] rounded-2xl m-2 borderThemed cursor-pointer" onClick={() => navigate(navigateTo)}>
      <div className="flex flex-col">
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <h4 className="font-bold">{title}</h4>
            <hr className="h-[10px] w-[1px] mx-[5px] bg-green-500" />
            <p className="text-gray-600 text-sm">{period}</p>
          </div>
        </div>
        <div className="flex items-center h-full ">
          <div className="">{icon}</div>
          <div className="flex flex-col ml-[20px]">
            <p className="text-1rem font-bold">{figure} {suffix}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardTile;