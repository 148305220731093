import React, { useState, ChangeEvent } from "react";
import toast from "react-hot-toast";
import StaffUtils from "../../../../../../utils/StaffUtils";
import useAuthStore from "../../../../../../stores/AuthStore";
import useStaffStore from "../../../../../../stores/StaffStore";

interface CreateDriverProps {
  setCreateStaffModal: (state: boolean) => void;
}

interface FormData {
  staff_name: string;
  staff_surname: string;
  staff_phone: string;
  staff_email: string;
  staff_title: string;
  staff_id_num: string;
}

const StaffCreate: React.FC<CreateDriverProps> = ({ setCreateStaffModal }) => {
  const { sessionUser } = useAuthStore();
  const { updateStaffData } = useStaffStore();

  const [formData, setFormData] = useState<FormData>({
    staff_name: "",
    staff_surname: "",
    staff_phone: "",
    staff_email: "",
    staff_title: "",
    staff_id_num: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);



  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (!sessionUser) return;

    try {
      await StaffUtils.CreateStaff(sessionUser, formData);
      handlePageClose();
      toast.success('Staff created successfully!');
      updateStaffData(sessionUser);
    } catch (err) {
      toast.error('Failed to create Staff. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePageClose = () => {
    setCreateStaffModal(false);
  };

  return (
    <div className="smallModal">

      {/* MAIN HEADER */}
      <div className="modalHeader">
        <h1 className="modalHeading">Add A New Staff</h1>
      </div>
      {/*  */}

      {/* MAIN CONTENT */}
      <div className="modalContent">
            <div className="">
              <p className="modaltext">Staff Name</p>
              <input className="longTextInput"
                name="staff_name"
                value={formData.staff_name}
                onChange={handleChange}
                placeholder="Full Name"
              />
            </div>
            <div className="">
              <p className="modaltext"> Surname</p>
              <input className="longTextInput"
                name="staff_surname"
                value={formData.staff_surname}
                onChange={handleChange}
                placeholder="Surname"
              />
            </div>

            <div className="">
              <p className="modaltext">Phone Number</p>
              <input className="longTextInput"
                type="number"
                name="staff_phone"
                value={formData.staff_phone}
                onChange={handleChange}
                placeholder="Phone Number"
              />
            </div>
            <div className="">
              <p className="modaltext">Staff ID Number</p>
              <input className="longTextInput"
                type="number"
                name="staff_id_num"
                value={formData.staff_id_num}
                onChange={handleChange}
                placeholder="ID Number"
              />
            </div>

            <div className="">
              <p className="modaltext">Staff Position</p>
              <input className="longTextInput"
                name="staff_title"
                value={formData.staff_title}
                onChange={handleChange}
                placeholder="Position"
              />
            </div>
            <div className="">
              <p className="modaltext">Email</p>
              <input className="longTextInput"
                name="staff_email"
                value={formData.staff_email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>


          <p className="font-poppins">Optional</p>

            <div className="">
              <p className="modaltext">Alternative Name</p>
              <input className="longTextInput"
                name="staff_alternative_name"
                value={formData.staff_alternative_name}
                onChange={handleChange}
                placeholder="Alternative Name"
              />
            </div>
            <div className="">
              <p className="modaltext">Alternative Phone</p>
              <input className="longTextInput"
                name="staff_alternative_phone"
                value={formData.staff_alternative_phone}
                onChange={handleChange}
                placeholder="Alternative Phone"
              />
            </div>
      </div>
      {/*  */}

      {/* MODAL FOOTER */}
      <div className="modalBtnFooter">
        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={`pageBtngood ${isSubmitting ? "bg-gray-500" : "bg-green-600"}`}
        >
          {isSubmitting ? 'Adding...' : 'Add Staff'}
        </button>
        <button
          onClick={handlePageClose}
          className="pageBtncancel">
          Close
        </button>
      </div>
      {/*  */}

    </div>
  );
};

export default StaffCreate;