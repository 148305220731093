export interface Staff {
  association_id: number;
  staff_id: string;
  staff_name: string;
  staff_surname: string;
  staff_phone: string;
  staff_email: string;
  staff_title: string;
  staff_id_num: string;
  staff_status: string;
  staff_add_date: Date;
  staff_alternative_name: string;
  staff_alternative_phone: string;
}

export class StaffClass {
  private _association_id: number;
  private _staff_id: string;
  private _staff_name: string;
  private _staff_surname: string;
  private _staff_phone: string;
  private _staff_email: string;
  private _staff_status: string;
  private _staff_title: string;
  private _staff_alternative_name: string;
  private _staff_id_num: string;
  private _staff_alternative_phone: string;
  private _staff_add_date: Date;

  constructor(staff: Staff) {
    this._association_id = staff.association_id;
    this._staff_id = staff.staff_id;
    this._staff_name = staff.staff_name;
    this._staff_surname = staff.staff_surname;
    this._staff_phone = staff.staff_phone;
    this._staff_email = staff.staff_email;
    this._staff_status = staff.staff_status;
    this._staff_title = staff.staff_title;
    this._staff_alternative_name = staff.staff_alternative_name;
    this._staff_id_num = staff.staff_id_num;
    this._staff_alternative_phone = staff.staff_alternative_phone;
    this._staff_add_date = staff.staff_add_date;
  }

  // Getters and Setters
  get staff_id() {
    return this._staff_id;
  }
  set staff_id(value: string) {
    this._staff_id = value;
  }

  get staff_name() {
    return this._staff_name;
  }
  set staff_name(value: string) {
    this._staff_name = value;
  }

  get staff_surname() {
    return this._staff_surname;
  }
  set staff_surname(value: string) {
    this._staff_surname = value;
  }

  get staff_phone() {
    return this._staff_phone;
  }
  set staff_phone(value: string) {
    this._staff_phone = value;
  }

  get staff_email() {
    return this._staff_email;
  }
  set staff_email(value: string) {
    this._staff_email = value;
  }

  get staff_status() {
    return this._staff_status;
  }
  set staff_status(value: string) {
    this._staff_status = value;
  }

  get staff_title() {
    return this._staff_title;
  }
  set staff_title(value: string) {
    this._staff_title = value;
  }

  get staff_alternative_name() {
    return this._staff_alternative_name;
  }
  set staff_alternative_name(value: string) {
    this._staff_alternative_name = value;
  }

  get staff_id_num() {
    return this._staff_id_num;
  }
  set staff_id_num(value: string) {
    this._staff_id_num = value;
  }

  get staff_alternative_phone() {
    return this._staff_alternative_phone;
  }
  set staff_alternative_phone(value: string) {
    this._staff_alternative_phone = value;
  }

  get staff_add_date() {
    return this._staff_add_date;
  }
  set staff_add_date(value: Date) {
    this._staff_add_date = value;
  };

  get association_id() {
    return this._association_id;
  }
  set association_id(value: number) {
    this._association_id = value;
  }
}
