import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Button } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Collectors, Subscriber } from "../../../../../../types/Types";
import useSubscriptionsStore from "../../../../../../stores/SubscribersStore";

import RecSubscriptionModal from "../modals/RecSubscriptionModal";
import { useFetchData } from "../../../../../../hooks/useFetchData";

interface CollectorTableProps {
  subscriptionData: any;
}


const PerSubscriptionTable: React.FC<CollectorTableProps> = ({ subscriptionData }) => {
  const {subscribersData, fetchSubscribersData} = useSubscriptionsStore()
  const [recordSubscriptionModal, setRecordSubscriptionModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Collectors | null>(null);
  const [filteredData, setFilteredData] = useState<Subscriber[]>([]);

  useFetchData(fetchSubscribersData)

  const filteredSubscribersData = useMemo(() => {
    const subscription_id = subscriptionData.subscription_id;
    return subscribersData.filter(subscriber => subscriber.subscription_id === subscription_id);
  }, [subscribersData, subscriptionData]);

  const handleRecordSubscriptionModal = (rowData: Subscriber) => {
    setSelectedRow(rowData);
    setRecordSubscriptionModal(true);
  };

  const filterPaid = () => {
    setFilteredData(subscribersData.filter((subscriber: Subscriber) => subscriber.subscriber_collected > 0));
  };

  const filterUnpaid = () => {
    setFilteredData(subscribersData.filter((subscriber: Subscriber) => subscriber.subscriber_collected === 0));
  };
  const filterAll = () => {
    setFilteredData(subscribersData);
  };

  useEffect(() => {
    setFilteredData(subscribersData);
  }, [subscribersData]);

  const handleExportRows = (rows: MRT_Row<Subscriber>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => [
      row.original.subscriber_name,
      row.original.subscriber_membership,
      `R ${row.original.subscriber_collected.toFixed(2)}`,
    ]);
    const tableHeaders = columns.map((c) => c.header || '');

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      theme: 'grid',
      styles: {
        minCellHeight: 10,
        halign: 'left',
        valign: 'middle',
      },
      didParseCell: function (data) {
        if (data.section === 'head') {
          data.cell.styles.halign = 'center';
        }
      },
    });
    doc.save(`Subscription.pdf`);
  }


  const columns = useMemo<MRT_ColumnDef<Collectors>[]>(
    () => [
      {
        accessorKey: "subscriber_name",
        header: "Subscriber Name",
        size: 100,
      },
      {
        accessorKey: "subscriber_membership",
        header: "Membership Fee",
        size: 100,
        Cell: ({ cell }) => `R ${cell.getValue<number>() || ' --- '}`,
      },
      {
        accessorKey: "subscriber_ownedVehicles",
        header: "Vehicles Owned",
        size: 200,
      },
      {
        accessorKey: "subscriber_perTaxi",
        header: "Amount Per Vehicle",
        size: 200,
        Cell: ({ cell }) => `R ${cell.getValue<number>() || ' 0 '}`,
      },
      {
        accessorKey: "subscriber_total",
        header: "Total Subscription Amount",
        size: 200,
      },
      {
        accessorKey: "subscriber_collected",
        header: "Amount Collected",
        size: 200,
        Cell: ({ cell }) => `R ${cell.getValue<number>() || ' 0 '}`,
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={filteredData || []}
        enableRowActions
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRecordSubscriptionModal(row.original)
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="flex">
            <Button
              disabled={filteredData.length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}>
              Export As PDF
            </Button>
            <button className="pageBtngoodSmall" onClick={filterPaid}>
              Paid
            </button>
            <button className="pageBtngoodSmall" onClick={filterUnpaid}>
              Unpaid
            </button>
            <button className="pageBtngoodSmall" onClick={filterAll}>
              All
            </button>
          </div>
        )}
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
      />
      {recordSubscriptionModal && selectedRow && (
        <div className="absolute top-0 left-0 w-full h-full z-[100] flex items-center justify-center l bg-black bg-opacity-50">
          <RecSubscriptionModal
            setRecordSubscriptionModal={setRecordSubscriptionModal}
            selectedRow={selectedRow}
          />
        </div>
      )}
    </>
  );
};

export default PerSubscriptionTable;
