import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../stores/AuthStore";
import useColletionsStore from "../../../../../../stores/CollectionsStore";

interface DriverModalProps {
  setRecordCollectionModal: (state: boolean) => void;
  selectedRow: {
    user: string;
    collector_id: number;
    vehicle_collection: number;
    owner_collection: number;
    collectors_amount: number;
  };
}

const RecCollectionModal: React.FC<DriverModalProps> = ({ setRecordCollectionModal, selectedRow }) => {
  const { sessionUser } = useAuthStore();
  const { updateCollectorsData } = useColletionsStore();

  const [formData, setFormData] = useState({
    collectorsAmount: selectedRow?.collectors_amount,
  });

  useEffect(() => {
    setFormData({
      collectorsAmount: selectedRow?.collectors_amount,
    });
  }, [selectedRow]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOwnerModalClose = () => {
    setRecordCollectionModal(false);
  };

  const handleCollectionRecord = async () => {
    if (!sessionUser) return;
    try {
      await CollectionUtils.RecordCollection(sessionUser, formData, selectedRow.collector_id);
      toast.success("Collection Recorded");
      updateCollectorsData(sessionUser);
      setRecordCollectionModal(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to record collection");
    }
  };

  return (
    <div className="smallModal">

      <div className="modalHeader">
        <h1 className="modaltext">Make a collection for {selectedRow?.user} </h1>
      </div>

      <div className="modalContent">
        <h2>
          Expected Collection Amount <span className="modaltext">R
            {selectedRow.owner_collection === null
              ? (selectedRow.vehicle_collection)?.toFixed(2)
              : (selectedRow.owner_collection)?.toFixed(2)}
          </span>
        </h2>
        <div className="flex flex-col items-center justify-between ">
          <p className="modaltext">Collection Amount</p>
          <TextField
            name="collectorsAmount"
            value={formData.collectorsAmount}
            onChange={handleInputChange}
            placeholder="Amount"
          />
        </div>
      </div>

      <div className="modalBtnFooter">
        <button className="pageBtngood"
          onClick={() => { handleCollectionRecord() }}>
          Submit
        </button>
        <button className="pageBtncancel"
          onClick={handleOwnerModalClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default RecCollectionModal;
