import React, { useState } from 'react';
import { Expense } from '../../../../../../types/Types';
import useExpensesStore from "../../../../../../stores/ExpenseStore"
import ExpenseTile from './tile/ExpenseTile';
import ExpenseModal from '../modals/ExpenseModal';
import { useFetchData } from '../../../../../../hooks/useFetchData';

const ExpensesContainer = () => {
  const { expensesData, fetchExpensesData } = useExpensesStore();
  const [expenseModal, setExpenseModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);

  useFetchData(fetchExpensesData)

  const handleExpenseClick = (expense: Expense) => {
    setSelectedExpense(expense);
    setExpenseModal(true);
  };

  const handleFinesTableClick = () => {
    window.location.href = '/admin/finance/expenses';
  };

  return (
    <div className='md:w-[35vw] borderThemed h-[50vh] rounded-2xl p-2 my-2'>
      <div className="flex flex-col h-full">
        <div className="cursor-pointer" onClick={handleFinesTableClick}>
          <div className="text-lg p-2">Expenses</div>
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {expensesData && expensesData.length > 0 ? (
            expensesData.map((expense) => (
              <ExpenseTile
                key={expense.expense_id}
                expense={expense}
                onClick={handleExpenseClick}
              />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
      {expenseModal && (
        <div className="modalBackground">
          <ExpenseModal selectedExpense={selectedExpense} setExpenseModal={() => setExpenseModal(false)} />
        </div>
      )}
    </div>
  );
};

export default ExpensesContainer;
