import React, { useState } from 'react'
import RecentComsContainer from './RecentComsContainer'
import CommunicationModal from '../modal/CommunicationModal'
import SentComsContainer from './SentComsContainer'
import ScheduledComsContainer from './ScheduledComsContainer'
import { Access } from '../../../../../../types/Types'
import useAuthStore from '../../../../../../stores/AuthStore'
import useAccess from '../../../../../../hooks/useUserAccess'

function CommunicationPage() {
  const { sessionUser } = useAuthStore();
  const access: any =  useAccess();

  const [comCreate, setComCreate] = useState(false);

  const handleComCreate = () => {
    setComCreate(!comCreate)
  }

  return (

    <div className="pageHeight">
      {/* THE ONLY ONE */}
      {access.Communication.edit && (
        <button className='pageBtngood' onClick={handleComCreate}>Create Com</button>
      )}
      <div className="flex justify-between w-full">
        <div className="">
          <RecentComsContainer />
        </div>
        <div className="">
          <SentComsContainer />
        </div>
        <div className="">
          <ScheduledComsContainer />
        </div>

      </div>
      {comCreate && <div className="modalBackground">
        <CommunicationModal setComCreate={setComCreate} />
      </div>}

    </div>
  )
}

export default CommunicationPage