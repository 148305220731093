import React, { Suspense, useState } from 'react'
import { HashLoader } from 'react-spinners'

import CollectionContainer from '../components/CollectionsContainer';
import SubscriptionsContainer from '../components/SubscriptionsContainer';
import DcFinesContainer from '../components/DcFinesContainer';

function FinanceDash() {
  const [createCollectionModal, setCreateCollectionModal] = useState(false);
  const [createSubscriptionModal, setCreateSubscriptionModal] = useState(false);


  // COLLECTION
  const handleCollectionCreate = () => {
    setCreateCollectionModal(!createCollectionModal);
  };

  // SUBSCRIPTION
  const handleSubscriptionCreate = () => {
    setCreateSubscriptionModal(!createSubscriptionModal)
  };


  return (
    <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>

      {/* THE ONLY ONE */}
      <div className="flex justify-between w-full">
        <div className="flex flex-col">
          <CollectionContainer />
        </div>
        <div className="">
          <SubscriptionsContainer />
        </div>
        <div className="">
          <DcFinesContainer />
        </div>
      </div>

    </Suspense>
  )
}

export default FinanceDash