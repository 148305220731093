const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;

export default class SquadUtils {

  // SQUAD MEMBERS
  static async GetSquadMembers(sessionUser: any) {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/get_squad_members`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch squad members:', error);
      }
    }
  }

  static async DeleteSquadMember(sessionUser: any, squad_member_id: any) {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/delete_squad_member`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ squad_member_id }),
        });

        if (!response.ok) {
          console.error('Error deleting squad Member:', response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error deleting squad Member:', error);
      }
    }
  }

  static async CreateSquadMember(sessionUser: any, formData: any) {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/create_squad_member`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, association: sessionUser.association }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('Vehicle created:');
      } catch (error) {
        console.error('Error creating squad vehicle:', error);
      }
    }
  }

  static UpdateSquadMember = async (sessionUser: any, formData: any,) => {
    if (sessionUser?.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/update_squad_member`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData }),
        });

        if (!response.ok) {
          throw new Error('Failed to update squad car information');
        }

      } catch (error) {
        console.error('Error updating squad car:', error);
        throw error;
      }
    }
  };

  // -- SQUAD VEHICLES -- //
  static async GetSquadCars(sessionUser: any) {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/get_squad_vehicles`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch squad cars:', error);
      }
    }
  }

  static async CreateSquadVehicle(sessionUser: any, formData: any) {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/create_squad_vehicle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, association: sessionUser.association }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('Vehicle created:');
      } catch (error) {
        console.error('Error creating squad vehicle:', error);
        throw error;
      }
    }
  }

  static async DeleteSquadCar(sessionUser: any, squadcarId: any) {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/delete_squad_vehicle`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ squadcarId, association: sessionUser.association }),
        });

        if (!response.ok) {
          console.error('Error deleting squadcar:', response.statusText);
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Error deleting squadcar:', error);
      }
    }
  }

  static UpdateSquadCar = async (sessionUser: any, formData: any,) => {
    if (sessionUser?.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/squad/update_squad_car`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData }),
        });

        if (!response.ok) {
          throw new Error('Failed to update squad car information');
        }

      } catch (error) {
        console.error('Error updating squad car:', error);
        throw error;
      }
    }
  };


}
