import React, { ReactNode } from 'react';
// PROVIDER IMPORTS

import { AdminProvider } from './AdminContext';
import { SessionProvider } from '../../../components/SessionContext';
// COMPONENTS
import AdminSidebar from './AdminSidebar';
import AdminNavbar from './AdminNavbar';

interface FinanceLayoutProps {
  children: ReactNode;
}

const AdminLayout: React.FC<FinanceLayoutProps> = ({ children }) => {
  return (
    <SessionProvider>
      <AdminProvider>
        <div className="flex flex-row w-full h-screen bg-slate-200">
          <AdminSidebar />
          <div className="flex flex-col justify-center w-full h-[97vh] rounded-lg my-4 border-slate-300 border-2 mr-3">
            <AdminNavbar />
            <div className="h-full">
              {children}
            </div>
          </div>
        </div>
      </AdminProvider>
    </SessionProvider>
  );
};

export default AdminLayout;
