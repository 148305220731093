import { Duty, SessionUser } from "@/types/Types";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class DutyUtils {

  static FetchDutyData = async (sessionUser: any,) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/duty/get_duties`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner:', error);
      }

    }
  };

  static FetchDutyHistory = async (sessionUser: any,) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/duty/get_duty_history`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner:', error);
      }

    }
  };


  static UpdateDuty = async (sessionUser: any, dutyId: number, dutyDataState: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/duty/update_duty`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify(
            { association: sessionUser.association, dutyJson: dutyDataState, dutyId: dutyId, dutyOwner: sessionUser.id }),
        });

        if (!response.ok) {
          console.error('Error Updating Duty:', response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error deleting collection:', error);
      }
    }
  };

  static CreateDuty = async (sessionUser: SessionUser, formData: Duty) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/duty/create_duty`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          console.error('Error Creating Duty:', response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error deleting collection:', error);
        throw error;
      }
    }
  };

  static FetchOwnerDutyData = async (sessionUser: any,) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/duty/get_owner_duties`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner:', error);
      }

    }
  };

  static AddDutyHistory = async (sessionUser: SessionUser, dutyId: any, updatedDutyData: any, dutyName: any, dutyDate: any, dutyEnd: any) => {
    if (sessionUser) {


      try {
        const response = await fetch(`${API_BASE_URL}/api/duty/add_duty_history`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ dutyId, updatedDutyData, dutyName, dutyDate, dutyEnd }),
        });

        if (!response.ok) {
          console.error('Error Creating Duty:', response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error deleting collection:', error);
        throw error;
      }
    }
  }


}