import React from 'react';
import { Access, ScheduledCom } from '../../../../../../../types/Types';
import toast from 'react-hot-toast';
import CommunicationUtils from '../../../../../../../utils/CommunicationUtils';
import DeleteDialog from '../../../../../../../components/common/dialogue/DeleteDialogue';
import useAuthStore from '../../../../../../../stores/AuthStore';
import useComsStore from '../../../../../../../stores/ComsStore';

interface ScheduleTileModalProps {
  com: ScheduledCom | null;
  comModal: boolean;
  handleComTileClick: () => void;
}

const ScheduleTileModal: React.FC<ScheduleTileModalProps> = ({ com, comModal, handleComTileClick }) => {
  const { sessionUser } = useAuthStore();
  const { updateScheduledComsData } = useComsStore();
  const access: Access =sessionUser &&  JSON.parse(sessionUser.access);

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [scheduelComToDelete, setScheduelComToDelete] = React.useState<ScheduledCom | null>(null);

  if (!comModal) return null;

  const openDeleteDialog = (com: ScheduledCom) => {
    setScheduelComToDelete(com);
    setDeleteDialogOpen(!deleteDialogOpen);

  };

  const handleComDelete = async () => {
    if (!sessionUser) return;
    try {
      await CommunicationUtils.deleteScheduledCom(sessionUser, scheduelComToDelete?.schedule_com_id);
      toast.success('Com deleted successfully');
      updateScheduledComsData(sessionUser);
      handleComTileClick();
    } catch (error) {
      toast.error('Error deleting com');
      setDeleteDialogOpen(false);
    }
  }


  return (
    <div className=" smallModal">

      <div className="modalHeader">
        <h2 className="modalHeading">Scheduled Communication</h2>
      </div>

      <div className="modalContent">
        <p className="text-gray-700 mt-2">From: {com?.schedule_com_sender}</p>
        <p className="text-gray-700 mt-2">Date: {new Date(com.schedule_com_date).toDateString()} at {new Date(com.schedule_com_date).toLocaleTimeString()}</p>
        <p className="text-gray-700 mt-2">Message: {com?.schedule_com_body}</p>
        <p className="text-gray-700 mt-2">Type: {com?.schedule_com_type}</p>
      </div>

      <div className="modalBtnFooter">
        {access.Communication.edit && (
          <button onClick={() => openDeleteDialog(com)} className="pageBtnWarn">
            Delete
          </button>
        )}
        <button onClick={handleComTileClick} className="pageBtncancel">
          Close
        </button>
      </div>

      {/* Render Delete Dialog */}
      {deleteDialogOpen && (
        <DeleteDialog
          isOpen={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={handleComDelete}
          title="Delete Marshal?"
          description={`Are you sure you want to delete this scheduled communication?`}
        />
      )}
      {/*  */}
    </div>
  );
};

export default ScheduleTileModal;
