import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Event } from '../../../../../../types/Types';
import { ThemeProvider } from '@mui/material';
import { getTheme } from '../../../components/muitheme/MuiTheme';
import EventModal from '../../../components/schedule/modals/EventsModal';
import { useOwner } from '../../../../components/OwnerContext';


const EventTable: React.FC = () => {
  const { sessionUser, isDarkMode, eventData } = useOwner();
  const [eventModal, setEventModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Event | undefined>(undefined);
  const [selectedEvent, setSelectedEvent] = useState<{
    id: number;
    title: string;
    startStr: Date;
    endStr: Date;
    extendedProps: any;
  } | null>(null);


  const handleCloseEventModal = () => {
    setEventModal(false);
    setSelectedEvent(null);
  };

  const handleDriverModal = (rowData: Event) => {
    setSelectedRow(rowData);
    setEventModal(true);
  };


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const columns = useMemo(() => [
    { accessorKey: 'event_name', header: 'Event Name', size: 100 },
    { accessorKey: 'event_category', header: 'Category', size: 200 },
    {
      accessorKey: 'event_start', header: 'Strting Date', size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue<string>()),
    },
    {
      accessorKey: 'event_stop', header: 'Ending Date', size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue<string>()),
    },
  ], []);

  return (
    <div className="p-2">
      <ThemeProvider theme={getTheme(isDarkMode)}>
        <MaterialReactTable
          columns={columns}
          data={eventData}
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleDriverModal(row.original),
            sx: { cursor: 'pointer' },
          })}
        />

        {/* EVENT MODAL */}
        {eventModal && selectedRow && (
          <div className="modalBackground">
            <EventModal setEventModal={handleCloseEventModal} selectedEvent={selectedRow} />
          </div>
        )}
      </ThemeProvider>
    </div>
  );
};

export default EventTable;