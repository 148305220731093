import React, { useState, ChangeEvent, useEffect } from "react";
import { Autocomplete, TextField } from '@mui/material';
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import useAuthStore from "../../../../../../stores/AuthStore";
import useCollectionsStore from "../../../../../../stores/CollectionsStore";
import toast from "react-hot-toast";
import { Collections } from "../../../../../../types/Types";

interface CreateDriverProps {
  setAddCollectionModal: (state: boolean) => void;
  collectionData: any;
}

interface FormData {
  collectorName: string | null;
  collectorAmount: string;
}

const AddCollectionModal: React.FC<CreateDriverProps> = ({ setAddCollectionModal, collectionData }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { updateCollectorsData } = useCollectionsStore();
  // States
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ownerNames, setOwnerNames] = useState<{ name: string; id: number }[]>([]);
  const [selectedOwnerName, setSelectedOwnerName] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    collectorName: '',
    collectorAmount: '',
  });
  const emptyFormData = () => {
    setFormData({ collectorName: null, collectorAmount: '' })
  }

  const handlePageClose = () => {
    emptyFormData();
    setAddCollectionModal(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "number" ? Number(value) : value,
    }));
  };



  const handleAutocompleteChange = (_event: any, newValue: { name: string; id: number } | null) => {
    if (newValue) {
      setFormData({ ...formData, collectorName: newValue.name });
      setSelectedOwnerName(newValue.name);
      console.log(newValue.name);
    }
  };

  const submitCollection = async () => {
    setIsSubmitting(true);
    if (selectedOwnerName === null || sessionUser === null) {
      setError('Please select a collector.');
      return;
    }
    try {
      await CollectionUtils.CreateNewCollector(sessionUser, selectedOwnerName, collectionData.collection_id, formData.collectorAmount);
      updateCollectorsData(sessionUser);
      emptyFormData();
      handlePageClose();
      toast.success('Collection created successfully!');
    } catch (error) {
      console.error('Failed to create collection:', error);
      setError('Failed to create collection. Please try again.');
      toast.error('Failed to create collection. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchOwnerNames = async () => {
      try {
        const ownerNames = await CollectionUtils.GetOwnerDriverNames(sessionUser);
        if (ownerNames) {
          setOwnerNames(ownerNames);
        }
      } catch (error) {
        console.error('Failed to fetch owner names:', error);
      }
    };
    fetchOwnerNames();
  }, [sessionUser]);

  return (
    <div className="xsModal">

      <div className="modalHeader">
        <h1 className="modalHeading">
           Record A New Collection for &apos; {collectionData?.collection_name} &apos;
        </h1>
      </div>

      <div className="modalContent">
        <div className="flex flex-col h-[200px]">
          <div className="flex justify-between">
            <div className="h-[175px] rounded-md m-[2px]">
              <div className="flex flex-col">
                  <div className="m-2">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={ownerNames}
                      getOptionLabel={(option) => option.name}
                      onChange={handleAutocompleteChange}
                      sx={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select A Collector"
                          value={formData.collectorName}
                        />
                      )}
                    />
                  </div>
                  <div className="m-2">
                    <input
                      name="collectorAmount"
                      value={formData.collectorAmount}
                      onChange={handleChange}
                      className="longTextInput"
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modalBtnFooter">
        <button className={"pageBtngood"}
          onClick={() => submitCollection()} >
          {isSubmitting ? 'Adding...' : 'Add Collection'}
        </button>
        <button className="pageBtncancel" onClick={handlePageClose} >
          Close
        </button>
      </div>

    </div>
  );
};

export default AddCollectionModal;
