// utils/themeUtils.ts
import { createTheme } from '@mui/material/styles';

export const getTheme = (isDarkMode: boolean) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: '#FF0000', // Red color for primary elements
    },
    secondary: {
      main: '#FF9999', // Light red for secondary elements
    },
    text: {
      primary: isDarkMode ? '#e0e0e0' : '#333333', // Light or dark text color
    },
    background: {
      default: isDarkMode ? 'rgba(0, 0, 0, 0.5)' : '#fff', // Dark or light background
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.5)' : '#fff', // Dark or light background for the table
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: isDarkMode ? '#e0e0e0' : '#333333', // Light or dark text in table cells
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0)' : '#f5f5f5', // Transparent or light background for rows
          '&:hover': {
            backgroundColor: isDarkMode ? '#333333' : '#e0e0e0', // Darker or lighter on hover
          },
        },
      },
    },
  },
});
