const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class StaffUtils {

  static GetStaff = async (sessionUser: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/staff/get_staff`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch staff:', error);
      }
    }
  }

  static DeleteStaff = async (sessionUser: any, staff_id: string) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/staff/delete_staff`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ staff_id: staff_id }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Failed to Delete Staff:', error);
      }
    }
  }

  static CreateStaff = async (sessionUser: any, formData: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/staff/create_staff`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('staff created:');
      } catch (error) {
        console.error('Error creating staff:', error);
        throw error;
      }
    }
  }

  static UpdateStaff = async (sessionUser: any, formData: any) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/staff/update_staff`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('staff updated:');
      } catch (error) {
        console.error('Error updating staff:', error);
        throw error;
      }
    }
  }

}
