import React, { Suspense, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { HashLoader } from 'react-spinners';
import BucketPage from '../tabs/BucketPage';
import Profile from '../tabs/Profile';
import useUserStore from '../../../../../../stores/UserStore';
import useAuthStore from '../../../../../../stores/AuthStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';
import Admin from '../../../../../admin/Home/Pages/settings/tabs/Admin';

const SecSettingsComponent = () => {
  const { sessionUser } = useAuthStore();
  const { userData, fetchUserData } = useUserStore();
  const [currentUser, setCurrentUser] = React.useState<any>(null);
  const [currentTab, setCurrentTab] = useState<number>(0);

  useFetchData(fetchUserData);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (sessionUser && userData) {
      const findCurrentUser = userData.find((user) => user.user_id === sessionUser.id);
      setCurrentUser(findCurrentUser);
    }
  }, [userData]);

  return (
    <div>
      <Tabs value={currentTab} onChange={handleTabChange}
      >
        <Tab label="User Info" />
        <Tab label="Files" />
        <Tab label="Admin Access" />
      </Tabs>
      <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
        {currentTab === 0 && <Profile />}
        {currentTab === 1 && <BucketPage />}
        {currentTab === 2 && <Admin />}
      </Suspense>
    </div>
  );
};

export default SecSettingsComponent;
