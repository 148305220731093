import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from './components/SessionContext';
import ProtectedRoute from './components/ProtectedRoutes';
import { Toaster } from 'react-hot-toast';
import Protected from './Home/Protected';

// HOME PAGES
import HomePage from './Home/Homepage';
import SignIn from './Home/SignIn';
import ComplaintsPage from './Home/ComplaintsPage';
import VerifyPage from './Home/verifyPage';
import NotFoundPage from './components/NotFound';

// Admin Pages
import FinanceHome from './pages/admin/Home/financeHome';
import FinanceTransport from './pages/admin/Home/Pages/transport/Transport';
import FinanceSchedule from './pages/admin/Home/Pages/schedule/Schedule';
import FinanceFinance from './pages/admin/Home/Pages/finance/Finance';
import FinanceOwners from './pages/admin/Home/Pages/owners/Owners';
import AdminStaff from './pages/admin/Home/Pages/staff/Staff';
import FinanceCommunication from './pages/admin/Home/Pages/communication/Communication';
import FinanceDistrbutionCenter from './pages/admin/Home/Pages/dc/DistributionCenter';
import FinanceSettings from './pages/admin/Home/Pages/settings/Settings';
// subpages
import FinanceCollectionTable from './pages/admin/Home/Pages/finance/sidepage/Collections';
import FinanceSubscriptionTable from './pages/admin/Home/Pages/finance/sidepage/Subscriptions';
import FinanceFineTable from './pages/admin/Home/Pages/finance/sidepage/Fines';
import FinanceExpenses from './pages/admin/Home/Pages/finance/sidepage/Expenses';


// OWNER IMPORTS
import OwnerDash from './pages/Owner/Home/OwnerDash';
import OwnerTransport from './pages/Owner/Home/Pages/transport/Transport';
import OwnerSchedule from './pages/Owner/Home/Pages/schedule/Schedule';
import OwnerFinance from './pages/Owner/Home/Pages/finance/Finance';
import OwnerOwners from './pages/Owner/Home/Pages/owners/Owners';
import OwnerCommunication from './pages/Owner/Home/Pages/communication/Communication';
import OwnerDistrbutionCenter from './pages/Owner/Home/Pages/dc/DistributionCenter';
import OwnerSettings from './pages/Owner/Home/Pages/settings/Settings';


function App() {
  return (
    <SessionProvider>
      <Router>
        <Routes>
          {/* HOME PAGE */}
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path='/complaint' element={<ComplaintsPage />} />
          <Route path='/verify' element={<VerifyPage />} />

          {/* Admin */}
          <Route path="/protected" element={<Protected />} />
          <Route path="/admin/home" element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceHome /></ProtectedRoute>} />
          <Route path='/admin/schedule' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceSchedule /></ProtectedRoute>} />
          <Route path='/admin/transport' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceTransport /></ProtectedRoute>} />
          <Route path='/admin/finance' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceFinance /></ProtectedRoute>} />
          <Route path='/admin/owners' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceOwners /></ProtectedRoute>} />
          <Route path='/admin/staff' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><AdminStaff /></ProtectedRoute>} />
          <Route path='/admin/communication' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceCommunication /></ProtectedRoute>} />
          <Route path='/admin/dc' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceDistrbutionCenter /></ProtectedRoute>} />
          <Route path='/admin/settings' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceSettings /></ProtectedRoute>} />
          {/* sub page */}
          <Route path='/admin/finance/collections' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceCollectionTable /></ProtectedRoute>} />
          <Route path='/admin/finance/subscription' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceSubscriptionTable /></ProtectedRoute>} />
          <Route path='/admin/finance/fines' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceFineTable /></ProtectedRoute>} />
          <Route path='/admin/finance/expenses' element={<ProtectedRoute allowedRoles={['Finance', 'Secretary']}><FinanceExpenses /></ProtectedRoute>} />
          {/*  */}

          {/* OWNER */}
          <Route path='/owner/home' element={<OwnerDash />} />
          <Route path='/owner/schedule' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerSchedule /></ProtectedRoute>} />
          <Route path='/owner/transport' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerTransport /></ProtectedRoute>} />
          <Route path='/owner/finance' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerFinance /></ProtectedRoute>} />
          <Route path='/owner/owners' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerOwners /></ProtectedRoute>} />
          <Route path='/owner/communication' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerCommunication /></ProtectedRoute>} />
          <Route path='/owner/dc' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerDistrbutionCenter /></ProtectedRoute>} />
          <Route path='/owner/settings' element={<ProtectedRoute allowedRoles={['Owner']}><OwnerSettings /></ProtectedRoute>} />

          {/* 404 Page */}
          <Route path="*" element={<NotFoundPage />} />

        </Routes>
        <Toaster
          position='top-right'
          toastOptions={{
            style: { width: '250px', height: '50px', padding: '10px', fontSize: '12px' },
            success: { style: { background: '#17823f', color: '#fff', fontWeight: 'bold' } },
            error: { style: { background: '#f03838', color: '#fff', fontWeight: 'bold' } },
            duration: 3000
          }}
        />
      </Router>
    </SessionProvider>
  );
}

export default App;