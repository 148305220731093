import React from "react";

interface DeleteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
  description: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onDelete,
  title,
  description,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modalBackground">
      <div className="deleteModal">
        <div className="modalHeader">
          <h2 className="modalHeading">{title}</h2>
        </div>
        <div className="modalContent">
          <p className="font-poppins">{description}</p>
        </div>
        <div className="modalBtnFooter">
          <button onClick={onDelete} className="pageBtncancel">
            Continue
          </button>
          <button onClick={onClose} className="pageBtnWarn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
