import React, { useState } from 'react';
import { Complaint, Subscription } from '../../../../../../types/Types';
import ResolvedComplainantModal from '../../dc/modal/ResolvedComplainantModal';
import DcFinesTile from './tile/DcFinesTile';
import { useOwner } from '../../../../components/OwnerContext';

const DcFinesContainer = () => {
  const { dcData, driverData } = useOwner();
  const [complaintModal, setComplaintModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Complaint | null>(null);

  const handleSubscriptionClick = (complaint: Complaint) => {
    setSelectedCase(complaint);
    setComplaintModal(true);
  };

  const finedCases = dcData.filter((cases: any) => cases.dc_fine > 0);

  return (
    <div className='w-[25vw] borderThemed h-[70vh] rounded-2xl p-2'>
      <div className="flex flex-col h-full">
        <div className="text-lg p-2">Fines</div>
        <div className="">
          {/* You can add content here if needed */}
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {finedCases && finedCases.length > 0 ? (
            finedCases.map(fined => (
              <DcFinesTile key={fined.duty_id}
                fined={fined}
                onClick={handleSubscriptionClick}
              />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
      {complaintModal && (
        <div className="modalBackground">
          <ResolvedComplainantModal setComplaintModal={setComplaintModal} selectedRow={selectedCase} />
        </div>
      )}
    </div>
  );
};

export default DcFinesContainer;
