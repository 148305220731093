const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class ExpensesUtils {

  static GetExpenses = async (sessionUser: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/expenses/get_expenses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch expenses:', error);
      }
    }
  }

  static DeleteExpenses = async (sessionUser: any, expense_id: number) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/expenses/delete_expense`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ expense_id: expense_id }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Failed to Delete expense:', error);
      }
    }
  }

  static CreateExpense = async (sessionUser: any, formData: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/expenses/create_expense`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('Expense created:');
      } catch (error) {
        console.error('Error creating Expense:', error);
        throw error;
      }
    }
  }

  static UpdateExpense = async (sessionUser: any, formData: any) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/expenses/update_expense`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('expense updated!');
      } catch (error) {
        console.error('Error updating expense:', error);
        throw error;
      }
    }
  }

}
