import { create } from "zustand";
import { Event, SessionUser } from "../types/Types";
import EventsUtils from "../utils/EventsUtils";

interface EventStoreType {
  eventData: Event[];
  loading: boolean;
  error: string | null;
  fetchEventData: (sessionUser: SessionUser) => Promise<void>;
  updateEventData(sessionUser: SessionUser): unknown;
}

const useEventStore = create<EventStoreType>((set, get) => ({
  eventData: [],
  loading: false,
  error: null,

  // ----- Event Data ----- //

  fetchEventData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateEventData(sessionUser);
  },

  updateEventData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await EventsUtils.FetchEventsData(sessionUser);
      set({ eventData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch event data",
        loading: false
      });
    }
  }

}));

export default useEventStore;
