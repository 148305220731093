import React from 'react'
import { useState, useMemo, lazy, Suspense } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';

import EventTile from '../../../../../../components/common/eventTile';
import DateUtils from '../../../../../../utils/DateUtils';
import EventModal from '../../../components/schedule/modals/EventsModal';
import { useOwner } from '../../../../components/OwnerContext';


function Schedule() {
  const { eventData } = useOwner()

  // HOOKS
  const [eventModal, setEventModal] = useState(false);
  const [createEventModal, setCreateEventModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedEvent, setSelectedEvent] = useState<{
    id: string;
    title: string;
    startStr: Date;
    endStr: Date;
    extendedProps: any;
  } | null>(null);

  const formattedData = useMemo(() => {
    return DateUtils.CalendarEventFormat(eventData);
  }, [eventData]);

  const today = new Date();

  const formattedUpcomingData = useMemo(() => {
    return DateUtils.CalendarEventFormat(eventData)
      .filter(event => new Date(event.start) >= today) // Filter for upcoming events
      .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()); // Sort by start date
  }, [eventData]);

  // FUNCTIONS
  const handleDateClick = (selected: any) => {
    setCreateEventModal(true); // Open the CreateEvents modal
    setSelectedDate(selected); // Set the selected date to pass to the CreateEvents component
  };

  const handleEventClick = (selected: any) => {
    const mappedEvent = {
      id: selected.event.id,
      title: selected.event.title,
      startStr: selected.event.startStr,
      endStr: selected.event.endStr,
      eventCategory: selected.event.extendedProps.category,
      extendedProps: selected.event.extendedProps,
    };
    setSelectedEvent(mappedEvent);
    setEventModal(true);
  };

  const handleCloseEventModal = () => {
    setEventModal(false);
    setSelectedEvent(null);
  };

  return (

    <div className="flex w-[95%] p-4">

      {/* CALENDAR SIDEBAR */}
      <div className="flex flex-col w-[20%] p-[12px]">
        <p className="text-[20px] text-center">Upcoming Events</p>
        {formattedUpcomingData?.length === 0 && <p className="text-center">No upcoming events</p>}
        <div className="borderThemed h-[60vh] rounded-2xl mt-[20px] overflow-y-auto">
          {formattedUpcomingData?.map((event: any) => (
            <EventTile key={event.id} event={event} />
          ))}
        </div>
      </div>
      {/* CALENDAR COMPONENT */}

      <div className="flex-grow">
        <FullCalendar
          expandRows={true}
          timeZone="UTC"
          height={"70vh"}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          headerToolbar={{ left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth' }}
          initialView="dayGridMonth"
          selectable={true}
          select={handleDateClick}
          eventClick={handleEventClick}
          weekNumbers={true}
          events={formattedData}
          nowIndicator={true}
        />
      </div>
      {/* EVENT MODAL */}
      {eventModal && selectedEvent && (
        <div className="modalBackground">
          <EventModal setEventModal={handleCloseEventModal} selectedEvent={selectedEvent} />
        </div>
      )}
    </div>

  )
}

export default Schedule