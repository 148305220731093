import { SessionUser } from "@/types/Types";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class UserUtils {

  static signInUser = async (idNumber: string, password: string) => {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idNumber, password }),
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    return await response.json();
  };

  static FetchUserData = async (sessionUser: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/owner/get_users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok at FetchUserData');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  static CreateUser = async (sessionUser: SessionUser, formData: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/create_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ formData }),
      });

      if (!response.ok) {
        console.error('Error creating user:', response.statusText);
        throw new Error('Network response was not ok @ UserUtils.CreateUser');
      }

    } catch (error) {
      console.error('Error creating User:', error);
      throw error;
    }
  };

  static GetUserAccess = async (sessionUser: SessionUser) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/get_user_access`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
      });

      if (!response.ok) {
        console.error('Error getting user access:', response.statusText);
        throw new Error('Network response was not ok @ UserUtils.GetUserData');
      }

      const data = await response.json();
      return (data);

    } catch (error) {
      console.error('Error getting User access:', error);
      throw error;
    }
  };

  static UpdateUserAccess = async (sessionUser: SessionUser, accessData: any, user_id: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/update_user_access`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify(
          {
            accessData: accessData,
            user_id: user_id
          }
        ),
      });

      if (!response.ok) {
        console.error('Error getting user access:', response.statusText);
        throw new Error('Network response was not ok @ UserUtils.GetUserData');
      }

      const data = await response.json();
      return (data);

    } catch (error) {
      console.error('Error getting User access:', error);
      throw error;
    }
  };

  static UpdateUserDetails = async (sessionUser: SessionUser, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/update_user`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          throw new Error('Failed to update User');
        }
      } catch (error) {
        console.error('Error updating User:', error);
        throw error;
      }
    }
  };

  static UpdateUserPassword = async (sessionUser: SessionUser, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/change_password`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error('Error updating User:', error);
        throw error;
      }
    }
  };

  static ResetUserPassword = async (sessionUser: SessionUser, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/pass_reset`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
          console.log(response.statusText);
        }
      } catch (error) {
        console.error('Error Reseting Password:', error);
        throw error;
      }
    }
  };

  static DeleteUser = async (sessionUser: SessionUser, user_id: any,) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/delete_user`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ user_id }),
      });

      if (!response.ok) {
        console.error('Error deleting user:', response.statusText);
        throw new Error('Network response at DeleteUser');
      }

    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };




}