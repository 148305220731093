import React, { useState, useEffect, ChangeEvent } from "react";
import toast from "react-hot-toast";
import { Staff } from "../../../../../../types/Types";
import StaffUtils from "../../../../../../utils/StaffUtils";
import { StaffClass } from "../../../../../../types/formClasses/StaffClass";
import useAuthStore from "../../../../../../stores/AuthStore";
import useStaffStore from "../../../../../../stores/StaffStore";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";

interface StaffModalProps {
  setStaffModal: (state: boolean) => void;
  selectedRow: Staff;
}

const StaffModal: React.FC<StaffModalProps> = ({ setStaffModal, selectedRow }) => {
  const { sessionUser } = useAuthStore();
  const { updateStaffData } = useStaffStore();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState(new StaffClass(selectedRow));

  useEffect(() => {
    setFormData(new StaffClass(selectedRow));
  }, [selectedRow]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const newData = new StaffClass(prevState);
      (newData as any)[name] = value;  // Dynamically set the field
      return newData;
    });
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };


  const handleModalClose = () => {
    setStaffModal(false);
  };

  const handleStaffUpdate = async () => {
    setIsSubmitting(true);
    if (!sessionUser) return;
    try {
      await StaffUtils.UpdateStaff(sessionUser, formData);
      updateStaffData(sessionUser);
      toast.success("Staff updated successfully!");
      setStaffModal(false);
    } catch (error) {
      toast.error("Failed to update Staff");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStaffDelete = async () => {
    if (!sessionUser) return;
    try {
      await StaffUtils.DeleteStaff(sessionUser, selectedRow.staff_id);
      updateStaffData(sessionUser);
      setStaffModal(false);
      toast.success("Staff deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error("Staff to delete marshal");
    }
    setDeleteDialogOpen(false);
  };

  return (
    <div className="smallModal">

      {/* MODAL HEADER */}
      <div className="modalHeader">
        <h1 className="modalHeading">Staff Information</h1>
      </div>
      {/*  */}

      {/* MODAL CONTENT */}
      <div className="modalContent">
        <div className="">
          <p className="modaltext">Staff Name</p>
          <input className="longTextInput"
            name="staff_name"
            value={formData.staff_name}
            onChange={handleInputChange}
            placeholder="Name"
          />
        </div>
        <div className="">
          <p className="modaltext">Surname</p>
          <input className="longTextInput"
            name="staff_surname"
            value={formData.staff_surname}
            onChange={handleInputChange}
            placeholder="Surname" />
        </div>
        <div className="">
          <p className="modaltext">Email</p>
          <input className="longTextInput"
            name="staff_email"
            value={formData.staff_email}
            onChange={handleInputChange}
            placeholder="Email" />
        </div>
        <div className="">
          <p className="modaltext">ID Number</p>
          <input className="longTextInput"
            type="number"
            name="staff_id_num"
            value={formData.staff_id_num}
            onChange={handleInputChange}
            placeholder="ID Number" />
        </div>


        <div className="">
          <p className="modaltext">Phone</p>
          <input className="longTextInput"
            type="number"
            name="staff_phone"
            value={formData.staff_phone}
            onChange={handleInputChange}
            placeholder="Phone"
          />
        </div>
        <div className="">
          <p className="modaltext">Staff position</p>
          <input className="longTextInput"
            name="staff_title"
            value={formData.staff_title}
            onChange={handleInputChange}
            placeholder="Phone"
          />
        </div>


        <div className="">
          <p className="modaltext">Alternative Name</p>
          <input className="longTextInput"
            name="staff_alternative_name"
            value={formData.staff_alternative_name}
            onChange={handleInputChange}
            placeholder="Name"
          />
        </div>
        <div className="">
          <p className="modaltext">Alternative Phone</p>
          <input className="longTextInput"
            type="number"
            name="staff_alternative_phone"
            value={formData.staff_alternative_phone}
            onChange={handleInputChange}
            placeholder="Phone"
          />
        </div>


        <div className="">
          <p className="modaltext">Staff Status</p>
          <select className="longTextInput"
            name="staff_status"
            value={formData.staff_status}
            onChange={handleInputChange}>
            <option value="Active">Active</option>
            <option value="On Leave">On Leave</option>
            <option value="Suspended">Suspended</option>
          </select>
        </div>


      </div>
      {/*  */}

      {/* MODAL FOOTER */}
      <div className="modalBtnFooter">
        <button onClick={openDeleteDialog} className="pageBtnWarn" disabled={isSubmitting}>
          Delete
        </button>

        <button onClick={handleStaffUpdate} className="pageBtngood" disabled={isSubmitting}>
          Save
        </button>

        <button onClick={handleModalClose} className="pageBtncancel">
          Close
        </button>
      </div>
      {/*  */}

      {/* Render Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleStaffDelete}
        title="Delete Staff?"
        description={`Are you sure you want to delete ${selectedRow?.staff_name}?`}
      />
      {/*  */}

    </div>
  );
};

export default StaffModal;