import React, { useState, useEffect, Suspense } from "react";

// CONTEXT IMPORT
// UTILS
import DateUtils from "../../../../../../utils/DateUtils";
import EventsUtils from "../../../../../../utils/EventsUtils";
import { Tab, Tabs} from "@mui/material";
import toast from "react-hot-toast";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import EventDetails from "./tabs/EventDetails";
import { HashLoader } from "react-spinners";
import EventExpense from "./tabs/EventExpense";
import useEventStore from "../../../../../../stores/EventStore";
import useAuthStore from "../../../../../../stores/AuthStore";

///--///

interface EventModalProps {
  setEventModal: (state: boolean) => void;
  selectedEvent: any;
};

interface EventsAssigned {
  association_id: number;
  events_assigned_id: number;
  events_assigned_vehicle: string;
  events_assigned_driver: string;
  event_id: number;
  event_assign_id: number;
  event_assign_vehicle: string;
  event_assign_driver: string;
}

const EventModal: React.FC<EventModalProps> = ({ setEventModal, selectedEvent }) => {
  // Stores
  const { sessionUser } = useAuthStore()
  const { updateEventData } = useEventStore();
  // State
  const [currentTab, setCurrentTab] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventsAssigned, setEventsAssigned] = useState<EventsAssigned[]>([]);

  const formatDate = (date: Date) => DateUtils.FormatSqlDate(date);
  
  const [formData, setFormData] = useState({
    eventId: selectedEvent?.id || selectedEvent?.event_id,
    eventName: selectedEvent?.title || selectedEvent?.event_name,
    eventCategory: selectedEvent?.extendedProps?.category || selectedEvent?.event_category,
    eventDesc: selectedEvent?.extendedProps?.description || selectedEvent?.event_desc,
    eventStart: selectedEvent?.startStr ? formatDate(new Date(selectedEvent.startStr)) : selectedEvent?.event_start ? formatDate(new Date(selectedEvent.event_start)) : "",
    eventEnd: selectedEvent?.endStr ? formatDate(new Date(selectedEvent.endStr)) : selectedEvent?.event_end ? formatDate(new Date(selectedEvent.event_end)) : "",
  });


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    setFormData({
      eventId: selectedEvent?.id || selectedEvent.event_id,
      eventName: selectedEvent?.title || selectedEvent.event_name,
      eventDesc: selectedEvent?.extendedProps?.description || selectedEvent.event_desc,
      eventCategory: selectedEvent?.extendedProps?.category || selectedEvent.event_category,
      eventStart: selectedEvent?.startStr ? formatDate(new Date(selectedEvent.startStr)) : selectedEvent?.event_start ? formatDate(new Date(selectedEvent.event_start)) : "",
      eventEnd: selectedEvent?.endStr ? formatDate(new Date(selectedEvent.endStr)) : selectedEvent?.event_end ? formatDate(new Date(selectedEvent.event_end)) : "",
    });
    FetchEventsAssigned();
  }, [selectedEvent]);


  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleEventModalClose = () => {
    setEventModal(false);
  };

  const handleEventDelete = async () => {
    if (!sessionUser) return;
    try {
      await EventsUtils.DeleteEvent(sessionUser, selectedEvent.id);
      toast.success("Event deleted successfully!");
      updateEventData(sessionUser);
      setEventModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const FetchEventsAssigned = async () => {
    try {
      const eventsAssigned = await EventsUtils.EventsAssigned(sessionUser);
      setEventsAssigned(eventsAssigned);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="mediumModal">

      <div className="modalContent">
        <div className="w-full h-full">
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="Details" />
            <Tab label="Expenses" />
          </Tabs>
          <Suspense fallback={<div> <HashLoader color="#36d7b7" /> </div>}>
            {currentTab === 0 && <div className=""> <EventDetails selectedEvent={selectedEvent} /></div>}
            {currentTab === 1 && <div className=""> <EventExpense selectedEvent={selectedEvent} /></div>}
          </Suspense>
        </div>

      </div>

      <div className="modalBtnFooter">

        <button onClick={openDeleteDialog} className="pageBtnWarn">
          Delete Event
        </button>

        <button onClick={handleEventModalClose} className="pageBtncancel">
          Close
        </button>
      </div>

      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleEventDelete}
        title="Delete Event?"
        description={`Are you sure you want to delete " ${selectedEvent?.title} " ?, 
        This action cannot be undone.`}
      />

    </div>
  );
};

export default EventModal;