import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, MRT_Row } from "material-react-table";
import {useFetchData} from "../../../../../../hooks/useFetchData";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import toast from "react-hot-toast";
import useExpensesStore from "../../../../../../stores/ExpenseStore";
import CreateExpense from "../modals/CreateExpenseModal";
import ExpenseModal from "../modals/ExpenseModal";
import useAuthStore from "../../../../../../stores/AuthStore";

interface Collections {
  collection_id: number,
  collection_name: string,
  collection_desc: string,
  collection_amount: number
};

const ExpensesTable: React.FC = () => {
  const { sessionUser } = useAuthStore();
  const { expensesData, fetchExpensesData } = useExpensesStore();
  const [selectedExpense, setSelectedExpense] = useState<Collections | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // For controlling delete dialog
  const [rowToDelete, setRowToDelete] = useState<Collections | null>(null);
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);

  useFetchData( fetchExpensesData );


  const handleExpenseModal = (rowData: any) => {
    setSelectedExpense(rowData);
    setSelectedCase(rowData);
    setExpenseModal(true);
  };

  // Open delete dialog
  const openDeleteDialog = (rowData: any) => {
    setRowToDelete(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCreateExpenseModal = () => {
    setCreateExpenseModal(true);
  }

  const handleCollectionDelete = async () => {
    if (rowToDelete) {
      try {
        await CollectionUtils.DeleteCollection(sessionUser, rowToDelete.collection_id);
        toast.success("Collection deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete collection");
      }
      setDeleteDialogOpen(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "expense_name",
        header: "Expense Name",
        size: 100,
      },
      {
        accessorKey: "expense_category",
        header: "Category",
        size: 200,
      },
      {
        accessorKey: "expense_amount",
        header: "Amount",
        size: 200,
      },
    ],
    []
  );

  const handleExportRows = (rows: MRT_Row<Collections>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('mrt-pdf-example.pdf');
  };


  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={expensesData}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleExpenseModal(row.original),
          sx: {
            cursor: "pointer",
          },
        })}
        renderRowActionMenuItems={({ row, table }) => [
          // eslint-disable-next-line react/jsx-pascal-case
          <MRT_ActionMenuItem
            key="delete"
            label="Delete"
            table={table}
            onClick={() => openDeleteDialog(row.original)} icon={undefined} />,
        ]}
        renderTopToolbarCustomActions={({ table }) => (
          <div>
            <Button
              disabled={expensesData.length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export As PDF
            </Button>
            <button className="pageBtngood" onClick={handleCreateExpenseModal}>
              Add Expense
            </button>

          </div>
        )}
      />

      {/* CREATE MODAL */}
      {expenseModal && (
        <div className="modalBackground">
          <ExpenseModal setExpenseModal={() => setExpenseModal(false)} selectedExpense={selectedExpense} />
        </div>
      )}
      {/*  */}

      {/* CREATE MODAL */}
      {createExpenseModal && (
        <div className="absolute top-0 flex items-center justify-center left-0 z-[100] w-full h-full bg-black bg-opacity-50">
          <CreateExpense setCreateExpenseModal={setCreateExpenseModal} />
        </div>
      )}
      {/*  */}

      {/* DELETE MODAL */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleCollectionDelete}
        title="Delete Marshal?"
        description={`Are you sure you want to delete ${rowToDelete?.collection_name}?`}
      />
      {/*  */}
    </>
  );
};

export default ExpensesTable;
