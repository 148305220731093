import React, { useState } from 'react'
import CalendarComponent from './tabs/Calendar'
import { Tab, Tabs } from '@mui/material'
import EventsTable from './tabs/EventTable'
import { OwnerProvider } from '../../../components/OwnerContext'
import OwnerLayout from '../../../components/OwnerLayout'

function Schedule() {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <OwnerProvider>
      <OwnerLayout>
        <div className='pageHeight'>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="Calendar" />
            <Tab label="Events" />
          </Tabs>
          {currentTab === 0 && <CalendarComponent />}
          {currentTab === 1 && <EventsTable />}
        </div>
      </OwnerLayout>
    </OwnerProvider>
  )
}

export default Schedule