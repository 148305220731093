import React, { Suspense, useState } from 'react'
import { HashLoader } from 'react-spinners'
// import AppComms from './components/modals/AppComms'
// import EmailComms from './components/modals/EmailComms'
// import SmsComms from './components/modals/SmsComms'
import RecentComsContainer from './components/RecentComsContainer'
import CommunicationModal from './modal/CommunicationModal'
import SentComsContainer from './components/SentComsContainer'
import ScheduledComsContainer from './components/ScheduledComsContainer'
import { AdminProvider } from '../../../components/AdminContext'
import AdminLayout from '../../../components/AdminLayout'
import CommunicationPage from './components/CommunicationPage'

function Communications() {
  const [comCreate, setComCreate] = useState(false);

  const handleComCreate = () => {
    setComCreate(!comCreate)
  }

  return (
    <AdminProvider>
      <AdminLayout>
        <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
          <CommunicationPage />
        </Suspense>
      </AdminLayout>
    </AdminProvider>
  )
}

export default Communications