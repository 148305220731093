import React from 'react';
import ScheduleTileModal from './modals/ScheduleTileModal';

interface ScheduledComTileProps {
  com: any;
}

const ScheduledComTile: React.FC<ScheduledComTileProps> = ({ com }) => {
  const [comModal, setComModal] = React.useState(false);

  const comDate = new Date(com.schedule_com_date).toDateString();

  const handleCScheduleTileClick = () => {
    setComModal(!comModal);
  };

  return (
    <div onClick={handleCScheduleTileClick} className="flex flex-row borderThemed justify-between p-2 rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200">
      <div className="">
        <h3 className="text-md font-bold">{com.schedule_com_subject}</h3>
        <p className="modaltext">{com.schedule_com_body}</p>
      </div>
      <p className="modaltext">{comDate}</p>
      {comModal &&
        <div className="modalBackground">
          <ScheduleTileModal com={com} comModal={comModal} handleComTileClick={handleCScheduleTileClick} />
        </div>
      }
    </div>
  );
};

export default ScheduledComTile;