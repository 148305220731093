import React from 'react';
import { useNavigate } from 'react-router-dom';
import loadingTaxi from '../assets/images/loading.png';
import toast from 'react-hot-toast';
import DcUtils from '../utils/DcUtils';

export default function HomePage() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateVehicleRegistration = (vehicleReg) => {
    return vehicleReg && !vehicleReg.includes(' ') && vehicleReg.length >= 8;
  };

  const handleComplaintSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const complaint = {
      customerName: formData.get('customerName'),
      customerSurname: formData.get('customerSurname'),
      customerPhone: formData.get('customerPhone'),
      customerEmail: formData.get('customerEmail'),
      vehicleReg: formData.get('vehicleReg')?.toString().toUpperCase(),
      complaintTitle: formData.get('complaintTitle'),
      complaintDescription: formData.get('complaintDescription'),
    };

    if (!validatePhoneNumber(complaint.customerPhone)) {
      toast.error('Phone number must be a 10-digit number starting with 0 and no spaces.');
      return;
    }

    if (!validateVehicleRegistration(complaint.vehicleReg)) {
      toast.error('Please Enter A Valid Vehicle Registration.');
      return;
    }

    try {
      await DcUtils.CreateComplaint(complaint);
      toast.success('Complaint submitted successfully!');
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit complaint. Invalid Registration.');
    } finally {
      navigate('/');
    }
  };

  return (
    <main className="flex flex-col w-full min-h-screen bg-slate-200 items-center">

      {/* HEADER */}
      <header className="p-4 flex justify-between items-center w-full bg-white shadow-md fixed top-0 z-10">
        <div className="text-3xl font-semibold text-slate-700 cursor-pointer" onClick={goHome}>
          Taxi Admin
        </div>
      </header>

      {/* MAIN CONTENT */}
      <div className="flex flex-col w-full p-4 mt-[120px] items-center">
        <section className="flex flex-col md:flex-row w-full md:w-3/4 lg:w-2/3 items-center bg-white shadow-md rounded-xl p-6">
          <div className="md:w-1/2 p-4">
            <img src={loadingTaxi} alt="Taxi loading" className="w-full h-auto rounded-xl shadow" />
          </div>

          <div className="md:w-1/2 p-6">
            <h3 className="text-2xl md:text-3xl font-bold mb-4">Have A Complaint?</h3>
            <p className="text-md md:text-lg mb-6">
              <span className="text-green-700 font-semibold">Log a Complaint</span> against any driver or vehicle to have your complaint resolved.
            </p>

            <form onSubmit={handleComplaintSubmit} className="flex flex-col gap-4">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col w-full">
                  <label className="font-medium">Name</label>
                  <input
                    className="longTextInput md:normalTextInput"
                    type="text"
                    name="customerName"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="font-medium">Surname</label>
                  <input
                    className="longTextInput md:normalTextInput"
                    type="text"
                    name="customerSurname"
                    placeholder="Surname"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col w-full">
                  <label className="font-medium">Phone Number</label>
                  <input
                    className="longTextInput md:normalTextInput"
                    type="text"
                    name="customerPhone"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="font-medium">Email</label>
                  <input
                    className="longTextInput md:normalTextInput"
                    type="email"
                    name="customerEmail"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col w-full">
                  <label className="font-medium">Vehicle Registration</label>
                  <input
                    className="longTextInput md:normalTextInput"
                    type="text"
                    name="vehicleReg"
                    placeholder="Registration"
                    required
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="font-medium">Complaint Title</label>
                  <select
                    name="complaintTitle"
                    className="longTextInput md:normalTextInput"
                    required
                  >
                    <option value="Reckless Driver">Bad Driving</option>
                    <option value="Bad Behaviour">Bad Behaviour</option>
                    <option value="Poor Taxi Condition">Poor Taxi Condition</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-center">
                <button type="submit" className="btn-primary">
                  Submit Complaint
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>

      <footer className="flex w-full h-[80px] items-center justify-center bg-white mt-auto shadow-md">
        Taxi Admin 2024 © All Rights Reserved
      </footer>

    </main>
  );
}
