import React from 'react';
import { Suspense } from 'react';
import { HashLoader } from 'react-spinners';
import DashboardTile from '../../../../../components/dashboard/dashboardCard';
import { MdEvent, MdOutlineDirectionsBus } from 'react-icons/md';
import { FaRegAddressCard } from 'react-icons/fa';
import { GrMoney } from 'react-icons/gr';
import NotificationTile from './components/NotificationTile';
import { useAdmin } from '../../../components/AdminContext';
import useCollectionStore from '../../../../../stores/CollectionsStore'
import useEventStore from '../../../../../stores/EventStore'
import useOwnerStore from '../../../../../stores/OwnerStore'
import { useFetchData } from '../../../../../hooks/useFetchData'
import useAuthStore from '../../../../../stores/AuthStore';

const DashboardPage = (): JSX.Element => {
  // Stores
  const { activeVehicles, notificationData } = useAdmin();
  const { sessionUser } = useAuthStore();
  const { eventData, fetchEventData } = useEventStore();
  const { collectionsData, fetchCollectionsData }  = useCollectionStore();
  const { ownerData, fetchOwnerData } = useOwnerStore()
  // Data Fetch
  useFetchData(fetchEventData);
  useFetchData(fetchCollectionsData);
  useFetchData(fetchOwnerData);

  // Sort notifications by the latest time
  const sortedNotifications = [...notificationData].sort((a, b) => {
    return new Date(b.noti_date).getTime() - new Date(a.noti_date).getTime();
  });

  // COLLECTIONS //
  const totalCollection = collectionsData.reduce((acc, collection) => acc + collection.collection_amount, 0);
  //--//

  // EVENTS //
  const totalEvents = eventData.length;
  //--//

  // OWNERS //
  const activeOwners = ownerData.filter((owner) => owner.user_status === "Active").length;
  //--//

  return (
    <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>

      <div className="flex flex-col pageHeight">

        <div className="flex items-center h-[20vh] borderThemed rounded-md">
          <h1 className="text-xl font-bold p-2">Welcome back, {sessionUser?.name}</h1>
          <p className='px-2'>{new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', hour12: true }).format(new Date())}</p>
        </div>

        <div className="flex flex-col md:flex-row justify-between w-full">

          {/* HEADER ICONS */}
          <div className="flex flex-col sm:flex-row justify-around md:w-[50vw]">
            <div className="flex flex-col justify-between my-2 items-center">
              <DashboardTile title={"Active Vehicles"} period={'Today'} icon={<MdOutlineDirectionsBus size={30} />} figure={activeVehicles} percentage={"10%"} suffix={"Vehicles"} navigateTo={'/admin/transport'} />

              <DashboardTile title={"Active Owners"} period={'Active'} icon={<FaRegAddressCard size={30} />} figure={activeOwners} percentage={"10%"} suffix={"Owners"} navigateTo={'/admin/owners'} />
            </div>

            <div className="flex flex-col justify-between my-2 items-center">
              <DashboardTile title={"Collections"} period={'Monthly'} icon={<GrMoney size={30} />} figure={`R${totalCollection.toFixed(2)}`} percentage={"10%"} suffix={""} navigateTo='/admin/finance' />

              <DashboardTile title={"Events"} period={'Events'} icon={<MdEvent size={30} />} figure={totalEvents} percentage={"10%"} suffix={"Events"} navigateTo='/admin/schedule' />
            </div>
          </div>
          {/*  */}

          {/* NOTIFICATION SECTION */}
          <div className="borderThemed rounded-xl overflow-y-auto h-[56vh] md:w-[25vw] my-4">
            <h4 className="text-xl font-bold p-2 text-center">Notifications</h4>
            <hr />
            {sortedNotifications.map((notification) => (
              <NotificationTile key={notification.id} notification={notification} />
            ))}
          </div>
          {/*  */}

        </div>
      </div>
    </Suspense>
  );
};

export default DashboardPage;
