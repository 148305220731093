import { Tab, Tabs } from '@mui/material'
import React, { Suspense, useState } from 'react'
import SmsComms from './tabs/SmsComms';
import EmailComms from './tabs/EmailComms';
import AppComs from './tabs/AppComms';
import { HashLoader } from 'react-spinners';

const CommunicationModal = ({setComCreate}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };


  return (
    <div className='mediumModal'>
      <div className="modalContent">
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Sms" />
          <Tab label="Email" />
          <Tab label="In App" />
        </Tabs>

        <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
          {currentTab === 0 && <SmsComms/>}
          {currentTab === 1 && <EmailComms/>}
          {currentTab === 2 && <AppComs/>}
        </Suspense>
      </div>

      <div className="modalBtnFooter">
        <button className='pageBtncancel' onClick={() => setComCreate(false)} >Close</button>
      </div>
    </div>
  )
}

export default CommunicationModal