import React from 'react'
import ExpensesTable from '../tables/ExpensesTable'
import { AdminProvider } from '../../../../components/AdminContext'
import AdminLayout from '../../../../components/AdminLayout';

const Fines = () => {
  return (
    <AdminProvider>
      <AdminLayout>
        <div className="p-2">
          <ExpensesTable />
        </div>
      </AdminLayout>

    </AdminProvider>
  )
}

export default Fines