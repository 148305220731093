import React, { FormEvent, useState } from 'react';
import CommonUtils from '../../../../../../utils/CommonUtils';
import DcUtils from '../../../../../../utils/DcUtils';
import toast from 'react-hot-toast';
import { Autocomplete, TextField } from '@mui/material';
import useAuthStore from '../../../../../../stores/AuthStore';
import useVehicleStore from '../../../../../../stores/VehicleStore';
import useDCStore from '../../../../../../stores/DCStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

interface CreateComplaintModalProps {
  onClose: () => void;
}

const CreateComplaintModal: React.FC<CreateComplaintModalProps> = ({ onClose }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { vehicleData, fetchVehicleData } = useVehicleStore();
  const { updateDcData, fetchDcData} = useDCStore();
  const [newTaxi, setNewTaxi] = useState<string | null>(null);

  useFetchData(fetchVehicleData);
  useFetchData(fetchDcData);

  // Handle Autocomplete change
  const handleAutocompleteChange = (
    _event: any,
    newValue: { label: string; id: number } | null
  ) => {
    setNewTaxi(newValue ? newValue.label : null);
  };

  // Handle form submission
  const handleComplaintSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!sessionUser) return;

    const form = event.currentTarget;
    const formData = new FormData(form);

    const complaint = {
      customerName: sessionUser.name,
      customerPhone: '',
      customerEmail: '',
      vehicleReg: formData.get('vehicleReg')?.toString().toUpperCase(),
      complaintTitle: formData.get('complaintTitle')?.toString() || '',
      complaintDescription: formData.get('complaintDescription')?.toString() || '',
    };

    // Validate vehicle registration
    if (!CommonUtils.validateVehicleRegistration(complaint.vehicleReg)) {
      toast.error('Please Enter A Valid Vehicle Registration.');
      return;
    }

    try {
      await DcUtils.CreateComplaint(complaint);
      updateDcData(sessionUser);
      toast.success('Complaint submitted successfully!');
      onClose(); // Close modal after successful submission
    } catch (error) {
      console.error('Complaint submission failed:', error);
      toast.error('Failed to submit complaint. Invalid Registration.');
    }
  };

  // Vehicle options for Autocomplete
  const vehicleOptions = vehicleData.map((vehicle) => ({
    label: vehicle.vehicle_registration,
    id: vehicle.vehicle_id,
  }));

  return (
    <div className='smallModal'>
      <div className="modalHeader">
        <h1 className="modalHeading">Special Case</h1>
      </div>

      <div className="modalContent">
        <form onSubmit={handleComplaintSubmit} className="flex flex-col borderThemed rounded-2xl p-4">
          <div className="flex flex-col justify-around p-4">
            <p className='modaltext'>Registration</p>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.label}
              id="vehicle-autocomplete-new"
              options={vehicleOptions}
              value={vehicleOptions.find(option => option.label === newTaxi) || null}
              sx={{ width: 250 }}
              onChange={handleAutocompleteChange}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={(params) => (
                <TextField {...params} label="Select Vehicle" name="vehicleReg" required />
              )}
            />

            <div className="">
              <p className='modaltext'>Complaint Title</p>
              <select name="complaintTitle" id="" className="longTextInput" required>
                <option value="Reckless Driver">Bad Driving</option>
                <option value="Reckless Driver">Bad Behaviour</option>
                <option value="Reckless Driver">Poor Taxi Condition</option>
                <option value="Reckless Driver">Other</option>
              </select>
            </div>
          </div>

          <div className="modalBtnFooter">
            <button type="submit" className='pageBtngood'>Submit Complaint</button>
            <button type="button" onClick={onClose} className="pageBtncancel">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateComplaintModal;
