import React, { ChangeEvent, useState } from 'react';
import CommunicationUtils from '../../../../../../../utils/CommunicationUtils';
import toast from 'react-hot-toast';
import useAuthStore from '../../../../../../../stores/AuthStore';
import useComsStore from '../../../../../../../stores/ComsStore';

interface AppCommsProps {
}

const AppComs: React.FC<AppCommsProps> = () => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { updateComsData } = useComsStore();
  // States
  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState({
    comSubject: '',
    comBody: '',
    comFinance: false,
    comOwner: false,
    comMarshal: false,
  });

  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, type, value } = e.target;
    const checked = 'checked' in e.target ? (e.target as HTMLInputElement).checked : false;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCommSend = async () => {
    if (!sessionUser) return;
    setIsSending(true);
    try {
      const communicationData = {
        association_id: sessionUser.association,
        com_subject: formData.comSubject,
        com_message: formData.comBody,
        com_sender: sessionUser.id,
        com_date: new Date().toISOString(),
        com_finance: formData.comFinance ? 1 : 0,
        com_owner: formData.comOwner ? 1 : 0,
        com_marshal: formData.comMarshal ? 1 : 0,
        com_type: 'email',
      };

      await CommunicationUtils.SendAppComm(sessionUser, communicationData);
      updateComsData(sessionUser);
      toast.success('Communication sent successfully!');
    } catch (error) {
      toast.error('Error sending Communication: ' + error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="">
      <h1 className="modalHeading">Send App Communication.</h1>
      <p className='modaltext'>Reserved For Users With TaxiAdmin Access.</p>


      <div className="flex flex-col items-center justify-around p-2 h-[50%] w-[90%] borderThemed rounded-xl">
        <div className="flex flex-col items-center">

          <div className="flex flex-col items-center">
            <input
              className='h-[50px] w-[500px] rounded-xl p-2 borderThemed'
              id="comSubject"
              placeholder="Communication Subject"
              name="comSubject"
              value={formData.comSubject}
              onChange={handleChange}
            />
            <textarea
              className='h-[150px] m-2 w-[500px] rounded-xl p-2 borderThemed'
              id="comBody"
              placeholder="Communication Text"
              name="comBody"
              value={formData.comBody}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col w-[64%] items-center m-2 borderThemed rounded-lg">
            <div className="flex flex-row justify-between w-[300px]">
              <div className="flex flex-col items-center">
                <label htmlFor="ownerEmail" className="text-sm font-semibold">Owners</label>
                <input
                  checked={formData.comOwner}
                  onChange={handleChange}
                  type="checkbox"
                  id="comOwner"
                  name="comOwner"
                  className="mr-2 h-[25px] w-[25px]"
                />
              </div>
              <div className="flex flex-col items-center">
                <label htmlFor="financeEmail" className="text-sm font-semibold">Finance</label>
                <input
                  checked={formData.comFinance}
                  onChange={handleChange}
                  type="checkbox"
                  id="comFinance"
                  name="comFinance"
                  className="mr-2 h-[25px] w-[25px]"
                />
              </div>
            </div>
            <button className="pageBtngood" onClick={handleCommSend} disabled={isSending}>
              {isSending ? 'Sending...' : 'Send'}
            </button>

          </div>
        </div>

      </div>
    </div>
  );
};

export default AppComs;
