import React from 'react';
import { Expense } from '../../../../../../../types/Types';

interface SubscriptionTitleProps {
  expense: Expense;
  onClick: (Expense: Expense) => void;
}

const ExpenseTile: React.FC<SubscriptionTitleProps> = ({ expense, onClick }) => {
  const expenseDate = new Date(expense.expense_timestamp).toDateString();

  const handleComTileClick = () => {
    onClick(expense);
  };

  return (
    <div onClick={handleComTileClick} className="flex flex-row borderThemed justify-between p-2 rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200">
      <div className="w-[60%]">
        <p className="modaltextsmallBold"> {expense?.expense_name}</p>
        <h3 className="text-md font-bold">{expense.expense_category}</h3>
        <p className="modaltextsmallBold">{expense.expense_amount}</p>
      </div>
      <div className="flex flex-col w-[40%]">
        <p className='modaltextsmall'>Date</p>
        <p className="modaltextsmallBold text-center">{expenseDate}</p>
      </div>
    </div>
  );
};

export default ExpenseTile;
