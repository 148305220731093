import { create } from "zustand";
import { SessionUser, User } from "../types/Types";
import UserUtils from "../utils/UserUtils";

interface UserStoreType {
  userAccess: any[];
  userData: User[];
  loading: boolean;
  error: string | null;
  // Fetch
  fetchUserAccess: (sessionUser: SessionUser) => Promise<void>;
  fetchUserData: (sessionUser: SessionUser) => Promise<void>;
  // Update
  updateUserData(sessionUser: SessionUser): unknown;
}

const useUserStore = create<UserStoreType>((set, get) => ({
  userAccess: [],
  userData: [],
  loading: false,
  error: null,

  // ----- User Access ----- //

  fetchUserAccess: async (sessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await UserUtils.GetUserAccess(sessionUser);
      set({ userAccess: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch user access",
        loading: false
      });
    }
  },

    // ------------------ User Data ------------------ //
    fetchUserData: async (sessionUser: SessionUser) => {
      set({ loading: true, error: null });
      await get().updateUserData(sessionUser);
    },
  
    updateUserData: async (sessionUser: SessionUser) => {
      set({ loading: true, error: null });
      try {
        const data = await UserUtils.FetchUserData(sessionUser);
        set({ userData: data, loading: false });
      } catch (error) {
        console.error(error);
        set({
          error: "Failed to fetch user data",
          loading: false
        });
      }
    }


}));

export default useUserStore;