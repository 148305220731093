import { create } from "zustand";
import { SessionUser, User } from "../types/Types";
import OwnerUtils from "../utils/OwnerUtils";

interface useOwnerStoreType {
  loading: boolean;
  error: string | null;
  ownerData: User[];
  ownerVehicleCount: any[];
  userData: User[];
  // Fetch
  fetchOwnerData: (sessionUser: SessionUser) => Promise<void>;
  fetchOwnerVehicleCountData: (sessionUser: SessionUser) => Promise<void>;
  fetchUserData: (sessionUser: SessionUser) => Promise<void>;
  // Update
  updateOwnerData(sessionUser: SessionUser): unknown;
  updateUserData(sessionUser: SessionUser): unknown;
  updateOwnerVehicleCountData(sessionUser: SessionUser): unknown;
}

const useOwnerStore = create<useOwnerStoreType>((set, get) => ({
  ownerData: [],
  ownerVehicleCount: [],
  userData: [],
  loading: false,
  error: null,

   // ----- Owner Data ----- //

  fetchOwnerData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateOwnerData(sessionUser);
  },

  updateOwnerData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await OwnerUtils.FetchOwnerData(sessionUser);
      set({ ownerData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch owner access",
        loading: false
      });
    }
  },

  // ------------------ owner vehicle count ------------------ //
  fetchOwnerVehicleCountData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateOwnerVehicleCountData(sessionUser);
  },

  updateOwnerVehicleCountData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await OwnerUtils.OwnerVehicleCount(sessionUser);
      set({ ownerVehicleCount: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch owner vhehicle count",
        loading: false
      });
    }
  },

  // ------------------ User Data ------------------ //
  fetchUserData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateUserData(sessionUser);
  },

  updateUserData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await OwnerUtils.FetchUserData(sessionUser);
      set({ userData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch user data",
        loading: false
      });
    }
  }


}));

export default useOwnerStore;
