import React from 'react';
import { Notification } from '../../../../../../types/Types';

interface NotificationTileProps {
  notification: Notification;
}

const NotificationTile: React.FC<NotificationTileProps> = ({ notification }) => {
  const [comModal, setComModal] = React.useState(false);

  const notificationDate = new Date(notification.noti_date).toDateString();
  const notificationTime = new Date(notification.noti_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const handleComTileClick = () => {
    setComModal(!comModal);
  };

  return (
    <div
      onClick={handleComTileClick}
      className="flex flex-row items-center borderThemed justify-between p-2 m-2 border rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200"
    >
      <div className="">
        <h3 className="text-md font-bold text-sm">{notification.notification_title}</h3>
        <p className="text-gray-700 text-sm text-center">{notification.notification}</p>
        <hr />
        <p className="text-gray-700 text-xs text-center">{notificationDate} at {notificationTime}</p>
      </div>
    </div>
  );
};

export default NotificationTile;
