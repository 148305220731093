import React, { ReactNode } from 'react';
// PROVIDER IMPORTS

import { OwnerProvider } from './OwnerContext';

import { SessionProvider } from '../../../components/SessionContext';
// COMPONENTS
import OwnerSidebar from './OwnerSidebar';
import OwnerNavbar from './OwnerNavbar';

interface OwnerLayoutProps {
  children: ReactNode;
};

const OwnerLayout: React.FC<OwnerLayoutProps> = ({ children }) => {
  return (
    <SessionProvider>
      <OwnerProvider>
        <div className="flex flex-row w-full h-full ">
          <OwnerSidebar />
          <div className="flex flex-col w-full h-[100vh]">
            <OwnerNavbar />
            <div className="layout h-full">
              {children}
            </div>
          </div>
        </div>
      </OwnerProvider>
    </SessionProvider>
  );
};

export default OwnerLayout;
