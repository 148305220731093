import React, { useState } from 'react';
import AccessControl from '../../components/AccessControl';
import UserUtils from '../../../../../../../utils/UserUtils'
import toast from 'react-hot-toast';
import DeleteDialog from '../../../../../../../components/common/dialogue/DeleteDialogue';
import useAuthStore from '../../../../../../../stores/AuthStore';
import useUserStore from '../../../../../../../stores/UserStore';

interface UserModalProps {
  onClose: () => void;
  user: any;
  onSave: (formData: any) => void;
}

const UserModal: React.FC<UserModalProps> = ({ onClose, user, onSave }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { updateUserData } = useUserStore()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    user_id: user.user_id,
    user_name: user.user_name,
    user_surname: user.user_surname,
    user_email: user.user_email,
    user_status: user.user_status,
    user_phone: user.user_phone,
    user_city: user.user_city,
    user_idNum: user.user_idNum,
    user_gender: user.user_gender,
    alternative_name: user.alternative_name,
    alternative_number: user.alternative_number,
    user_role: user.user_role,
    user_membership: user.user_membership,
    user_title: user.user_title,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(formData);
  };

  const handleModalClose = () => {
    onClose();
  };

  const openResetPasswordDialog = () => {
    setResetDialogOpen(true);
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    if (!sessionUser) return;
    try {
      await UserUtils.DeleteUser(sessionUser, formData.user_id);
      toast.success("User deleted successfully");
      handleModalClose();
      setDeleteDialogOpen(false);
      updateUserData(sessionUser);
    } catch (error) {
      toast.error("Failed to delete user");
    }
  };

  const handleResetPassword = async () => {
    try {
      await UserUtils.ResetUserPassword(sessionUser, formData);
      toast.success("User password reset successfully");
      handleModalClose();
    } catch (error) {
      toast.error("Failed to reset user password");
    }
  };

  const handelAccessEdit = async (accessData: any) => {
    try {
      await UserUtils.UpdateUserAccess(sessionUser, accessData, formData.user_id);
      toast.success("Access updated successfully");
    } catch (error) {
      toast.error("Failed to update access");
    }
  };

  return (
    <div className="mediumModal">
      {/* Modal Header */}
      <div className="modalHeader">
        <h1 className="modalHeading">User Details</h1>
      </div>

      {/* Modal Content */}
      <div className="modalContent">
        {/* Left Data */}
        <div className="flex flex-col md:flex-row ">
          <div className="">

              <div className="m-2">
                <p className="modaltext">User Name</p>
                <input className="longTextInput"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleInputChange}
                  placeholder="User Name"
                />
              </div>

              <div className="m-2">
                <p className="modaltext">Surname</p>
                <input className="longTextInput"
                  name="user_surname"
                  value={formData.user_surname}
                  onChange={handleInputChange}
                  placeholder="Surname"
                />
              </div>

              <div className="m-2">
                <p className="modaltext">User Email</p>
                <input className="longTextInput"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleInputChange}
                  placeholder="User Email"
                />
              </div>


              <div className="m-2">
                <p className="modaltext">User Phone</p>
                <input className="longTextInput"
                  type='number'
                  name="user_phone"
                  value={formData.user_phone}
                  onChange={handleInputChange}
                  placeholder="User Phone"
                />
              </div>

              <div className="m-2">
                <p className="modaltext">User Status</p>
                <select className="longTextInput"
                  name="user_status"
                  value={formData.user_status}
                  onChange={handleInputChange}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>


              <div className="m-2">
                <p className="modaltext">User Gender</p>
                <select className="longTextInput"
                  name="user_gender"
                  value={formData.user_gender}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="m-2">
                <p className="modaltext">Title</p>
                <input className="longTextInput"
                  name="user_title"
                  value={formData.user_title}
                  onChange={handleInputChange}
                  placeholder="Title"
                />
              </div>
          </div>
          <div className="">
            <AccessControl
              userId={formData.user_id}
              onSave={handelAccessEdit}
            />
          </div>

        </div>
        <hr className="border-slate-700" />

        {formData.user_role !== "Secretary" && (
          <div className="">
            <button className='pageBtngood'
              onClick={openResetPasswordDialog}
            >Reset Password</button>
          </div>
        )}


      </div>

      {/* Modal Footer */}
      <div className="modalBtnFooter">
        <button onClick={openDeleteDialog} className="pageBtnWarn">
          Delete User
        </button>
        <button onClick={handleSave} className="pageBtngood">
          Save
        </button>
        <button onClick={handleModalClose} className="pageBtncancel">
          Close
        </button>
      </div>

      <DeleteDialog
        isOpen={resetDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleResetPassword}
        title="Change Password?"
        description={`Are you sure you want to change the password for ${formData?.user_name}?
        an sms with the new password will be sent to the user`}
      />

      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleDelete}
        title="Delete User?"
        description={`Are you sure you want to delete ${formData?.user_name}?`}
      />
    </div>
  );
};

export default UserModal;
