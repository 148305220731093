import React, { Suspense, useState } from 'react'
import { HashLoader } from 'react-spinners'
import RecentComsContainer from './components/RecentComsContainer'
import { OwnerProvider } from '../../../components/OwnerContext'
import OwnerLayout from '../../../components/OwnerLayout'

function Communications() {

  return (
    <OwnerProvider>
      <OwnerLayout>
        <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
          <div className="pageHeight">
            <div className="flex md:flex-row flex-col justify-between w-full gap-2">
              <div className="w-full">
                <RecentComsContainer />
              </div>
            </div>
          </div>
        </Suspense>
      </OwnerLayout>
    </OwnerProvider>
  )
}

export default Communications