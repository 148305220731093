import React from 'react';
import CommunicationUtils from '../../../../../utils/CommunicationUtils';
import toast from 'react-hot-toast';
import useAuthStore from '../../../../../stores/AuthStore';

interface EventCommunicationProps {
  setEventComm: (state: boolean) => void;
  selectedEvent: any;
  filteredEvents: any[];
}

const EventCommunication: React.FC<EventCommunicationProps> = ({ setEventComm, selectedEvent, filteredEvents }) => {
  const { sessionUser } = useAuthStore();

  // API CALLS //
  const handleSendCommunication = async () => {
    try {
      toast.promise(
        CommunicationUtils.SendEventAssignedComs(sessionUser, filteredEvents, selectedEvent),
        {
          loading: 'Sending communication...',
          success: 'Communication sent successfully!',
          error: 'Failed to send communication. Please try again',
        }
      )
    } catch (error) {
      console.log(error);
    } finally {
      setEventComm(false);
    }
  }

  return (
    <div className='bg-white flex flex-col h-[400px] w-[600px] rounded-lg p-4'>
      <button className='pageBtncancel self-end' onClick={() => setEventComm(false)}>Close</button>
      <div className="font-bold mb-4">Send Communication for Event: {selectedEvent.title}</div>
      <div className="overflow-y-auto">
        {filteredEvents.map((eventItem, index) => (
          <div key={index} className="p-2 border-b border-gray-200">
            <div><strong>Owner Name:</strong> {eventItem.owner_name}</div>
            <div><strong>Owner Number:</strong> {eventItem.owner_number}</div>
            <div><strong>Driver Name:</strong> {eventItem.event_assign_driver}</div>
            <div><strong>Driver Number:</strong> {eventItem.driver_number}</div>
          </div>
        ))}
      </div>
      <button className='pageBtngood text-sm ' onClick={handleSendCommunication}>Send</button>
    </div>
  );
}

export default EventCommunication;
