import React, { useState } from 'react';
import CollectionTile from './tile/CollectionTile';
import CollectionModal from '../modals/CollectionModal';
import { Collections } from '../../../../../../types/Types';
import useColletionsStore from '../../../../../../stores/CollectionsStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

const CollectionContainer = () => {
  const { collectionsData, fetchCollectionsData } = useColletionsStore();
  const [collectionModal, setCollectionModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<Collections | null>(null);

  useFetchData(fetchCollectionsData)

  const handleCollectionClick = (collection: Collections) => {
    setSelectedCollection(collection);
    setCollectionModal(true);
  };

  const handleCollectionTableClick = () => {
    window.location.href = '/admin/finance/collections';
  };

  return (
    <div className='md:w-[35vw] borderThemed h-[50vh] rounded-2xl p-2 my-2'>
      <div className="flex flex-col h-full cursor-pointer">
        <div className="" onClick={handleCollectionTableClick}>
          <div className="text-lg p-2">Collections</div>
        </div>
        <hr className='m-2 border-slate-700 rounded-lg' />
        <div className="borderThemed overflow-y-auto h-[100%] p-2 rounded-lg">
          {/* Map over the filtered data */}
          {collectionsData && collectionsData.length > 0 ? (
            collectionsData.map(collection => (
              <CollectionTile
                key={collection.collection_id}
                collection={collection}
                onClick={handleCollectionClick}
              />
            ))
          ) : (
            <div>No communications found.</div>
          )}
        </div>
      </div>
      {collectionModal && (
        <div className="modalBackground">
          <CollectionModal selectedRow={selectedCollection} setCollectionModal={setCollectionModal} />
        </div>
      )}
    </div>
  );
};

export default CollectionContainer;