import React from 'react'
import StaffTable from './table/StaffTable'
import { AdminProvider } from '../../../components/AdminContext'
import AdminLayout from '../../../components/AdminLayout'

const Staff = () => {
  return (
    <AdminProvider>
      <AdminLayout>
        <div className="p-2">
          <StaffTable />
        </div>
      </AdminLayout>
    </AdminProvider>
  )
}

export default Staff