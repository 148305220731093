const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API; 
export default class RankUtils {


  static FetchRankData = async( sessionUser:any,) =>{
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/rank/get_ranks`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return(data);
      } catch (error) {
        console.error('Failed to fetch ranks:', error);
      }
    }
  }

      // UPDATE/EDIT DRIVER DETAILS
    static CreateNewRank = async (sessionUser:any,formData:any) => {
        if (sessionUser.association) {
          try {
            const response = await fetch(`${API_BASE_URL}/api/rank/create_rank`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionUser.accessToken}`,
              },
              body: JSON.stringify({ ...formData}),
            });
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            console.log('Rank created:');
          } catch (error) {
            console.error('Error creating rank:', error);
            throw error;
          }
        }
      };

     
}