const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class SubscriptionUtils {

  static FetchSubscriptionData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/subscriptions/get_subscriptions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner:', error);
      }
    }
  };

  static FetchSubscribersData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/subscriptions/get_subscribers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner:', error);
      }
    }
  };

  static UpdateSubscription = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/subscriptions/update_subscription`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify(formData),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner:', error);
      }
    }
  };

  static CreateNewSubscription = async (sessionUser: any, subscriptionId: string, formData: any, thatTHICCdata: any, newPlan: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/subscriptions/create_subscription`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscriptionId, ...formData, newPlan }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Loop through the data and insert each owner's data individually
        for (const ownerData of thatTHICCdata) {
          try {
            const subscribersData = await fetch(`${API_BASE_URL}/api/subscriptions/create_subscriber`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionUser.accessToken}`,
              },
              body: JSON.stringify({ subscriptionId, ...ownerData }),
            });

            if (!subscribersData.ok) {
              throw new Error('Network response was not ok');
            }
          } catch (error) {
            console.error('Error creating subscriber:', error);
            throw error;
          }
        }

      } catch (error) {
        console.error('Error creating collection:', error);
        throw error;
      }
    }
  };


  static DeleteSubscription = async (sessionUser: any, subscriptionId: any) => {
    if (sessionUser) {
      try {
        if (sessionUser) {
          const response = await fetch(`${API_BASE_URL}/api/subscriptions/delete_subscription`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ subscriptionId: subscriptionId }),
          });

          if (!response.ok) {
            console.error('Error deleting collection:', response.statusText);
            throw new Error('Network response was not ok');
          }
        }
      } catch (error) {
        console.error('Error deleting collection:', error);
      }
    }
  }

  static RecordSubscription = async (sessionUser: any, formData: any, selectedRow: any) => {
    if (sessionUser) {
      try {
        if (sessionUser) {
          const response = await fetch(`${API_BASE_URL}/api/subscriptions/record_subscription`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({
              subscriber_id: selectedRow.subscriber_id,
              subscriber_collected: formData.subscriber_collected,
            }),
          });
          if (!response.ok) {
            console.error('Error recording subscription:', response.statusText);
            throw new Error('Network response was not ok');
          }
        }
      } catch (error) {
        console.error('Error recording subscription:', error);
      }
    }
  }


  static FetchSubscriptionPlan = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/subscriptions/get_subscription_plans`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          console.error('Error fetching subscription plan:', response.statusText);
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching subscription plan:', error);
        throw error;
      }
    }
  }

  static UpdateSubscriptionPlan =
    async (sessionUser: any, planId: number, plans: any
    ) => {
      if (sessionUser) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/subscriptions/update_subsription_plan`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify(
              { association: sessionUser.association, plans: plans, planId: planId, planOwner: sessionUser.id }),
          });

          if (!response.ok) {
            console.error('Error deleting collection:', response.statusText);
            throw new Error('Network response was not ok');
          }
        } catch (error) {
          console.error('Error deleting collection:', error);
          throw error;
        }
      }
    }

  static CreateNewSubscriptionPlan =
    async (sessionUser: any, plans: any
    ) => {
      if (sessionUser) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/subscriptions/update_subsription_plan`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify(
              { association: sessionUser.association, plans: plans, planOwner: sessionUser.id }),
          });

          if (!response.ok) {
            console.error('Error deleting collection:', response.statusText);
            throw new Error('Network response was not ok');
          }
        } catch (error) {
          console.error('Error deleting collection:', error);
        }
      }
    }

}