import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_Row } from "material-react-table";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useFetchData } from '../../../../../../hooks/useFetchData';
import ComplainantModal from "../modal/ComplainantModal";
import useDcStore  from "../../../../../../stores/DCStore";
import { Access } from "../../../../../../types/Types";
import CreateComplaintModal from "../modal/CreateComplaintModal";
import useAccess from "../../../../../../hooks/useUserAccess";

interface Collections {
  collection_id: number,
  collection_name: string,
  collection_desc: string,
  collection_amount: number
}

const DcTable: React.FC = () => {
  // Stores
  const { dcDataData, fetchDcData } = useDcStore();
  // States
  const [createComplainantModal, setCreateComplainantModal] = useState(false);
  const access: any =  useAccess();
  const [complaintModal, setComplaintModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Collections | undefined>(undefined);
  // Fetch
  useFetchData(fetchDcData);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const NewCases = dcDataData.filter((cases: any) => cases.dc_status === "New Case");

  const columns = useMemo<MRT_ColumnDef<Collections>[]>(
    () => [
      {
        accessorKey: "customer_name",
        header: "Complainant Name",
        size: 100,
      },
      {
        accessorKey: "complaint_title",
        header: "Complaint Title",
        size: 200,
      },
      {
        accessorKey: "dc_status",
        header: "Complaint Status",
        size: 100,
      },
      {
        accessorKey: "dc_date",
        header: "Complaint Date",
        size: 100,
        Cell: ({ cell }) => formatDate(cell.getValue<string>()), // Custom cell renderer
      },
    ],
    []
  );

  const handleComplaintModal = (row: Collections) => {
    setSelectedRow(row);
    setComplaintModal(!complaintModal);
  };

  const handleAddComplaint = () => {
    setCreateComplainantModal(!createComplainantModal);
  };

  const handleExportRows = (rows: MRT_Row<Collections>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('mrt-pdf-example.pdf');
  };

  return (
    <div className="p-2">
        <MaterialReactTable
          columns={columns}
          data={NewCases}
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleComplaintModal(row.original),
            sx: {
              cursor: "pointer",
            },
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <div >
              <Button
                disabled={dcDataData.length === 0}
                onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                startIcon={<FileDownloadIcon />}>
                Export As PDF
              </Button>
              {access.DC.edit && (
                <button className="pageBtngood"
                  onClick={handleAddComplaint}>
                  Add Special Case
                </button>
              )}
            </div>
          )}
          enableDensityToggle={false}
          initialState={{ density: "compact" }}
        />

        {/* MODALS */}
        {selectedRow && (
          <div
            className={`${complaintModal ? "modalBackground" : "hidden"}`}>
            <ComplainantModal
              setComplaintModal={setComplaintModal}
              selectedRow={selectedRow}
            />
          </div>
        )}

        {createComplainantModal && (
          <div className="modalBackground">
            <CreateComplaintModal
              onClose={() => setCreateComplainantModal(false)} />
          </div>
        )}
        {/*  */}
    </div>
  );
};

export default DcTable;
