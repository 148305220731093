import React from 'react'
import CollectionsTable from '../tables/Collections'
import { AdminProvider } from '../../../../components/AdminContext'
import AdminLayout from '../../../../components/AdminLayout'

const Collections = () => {
  return (
    <AdminProvider>
      <AdminLayout>
        <div className="p-2">
          <CollectionsTable />
        </div>
      </AdminLayout>

    </AdminProvider>
  )
}

export default Collections