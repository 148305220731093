import { create } from "zustand";
import { SessionUser } from "../types/Types";
import EventFinanceUtils from "../utils/EventsFinanceUtils";

interface EventFinanceStoreType {
  eventFinanceData: any[];
  loading: boolean;
  error: string | null;
  fetchEventFinanceData: (sessionUser: SessionUser) => Promise<void>;
  updateEventFinanceData: (sessionUser: SessionUser) => Promise<void>;
}

const useEventFinanceStore = create<EventFinanceStoreType>((set, get) => ({
  eventFinanceData: [],
  loading: false,
  error: null,

  // ----- Event Finance Data ----- //

  fetchEventFinanceData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateEventFinanceData(sessionUser);
  },

  updateEventFinanceData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await EventFinanceUtils.FetchEventFinances(sessionUser);
      set({ eventFinanceData: data, loading: false });
    } catch (error) {
      console.error('Error fetching event finance data:', error);
      set({
        error: "Failed to fetch event finances",
        loading: false
      });
    }
  }
}));

export default useEventFinanceStore;
