import { create } from "zustand";
import { SessionUser, Vehicle } from "../types/Types";
import VehicleUtils from "../utils/VehicleUtils";

interface UserStoreType {
  updateVehicleData(sessionUser: SessionUser): unknown;
  vehicleData: Vehicle[];
  loading: boolean;
  error: string | null;
  fetchVehicleData: (sessionUser: SessionUser) => Promise<void>;
}

const useVehicleStore = create<UserStoreType>((set, get) => ({
  vehicleData: [],
  loading: false,
  error: null,

  // ----- Vehicle Data ----- //

  fetchVehicleData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateVehicleData(sessionUser);
  },

  updateVehicleData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await VehicleUtils.FetchVehicles(sessionUser);
      set({ vehicleData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch user access",
        loading: false
      });
    }
  },

}));

export default useVehicleStore;
