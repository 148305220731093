import React from 'react';
import { Link } from 'react-router-dom';

interface SidebarItemProps {
  title: string;
  link: string;
  icon: React.ReactNode;
  isActive: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ title, link, icon, isActive }) => {
  return (
    <Link 
      to={link} 
      className={`group flex hover:text-white border-[1px] border-transparent items-center p-1 my-1 rounded-lg ${isActive ? 'border-white shadow-lg bg-[#79be9e] text-white' : 'text-gray-300'} hover:bg-[#2daf73] hover:border-white hover:duration-200`}
    >
      {icon}
      <div className="ml-2">
        <span className={`sideBarText ${isActive ? 'text-white' : 'text-gray-800 group-hover:text-white'}`}>
          {title}
        </span>
      </div>
    </Link>
  );
};

export default SidebarItem;
