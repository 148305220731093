import React, { useState, useEffect, useRef } from 'react';
import useSubscriptionsStore from '../../../../../../stores/SubscribersStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

interface PaymentDetails {
  membership: number;
  perTaxi: number;
}

const PaymentPlanComponent: React.FC = () => {
  const { subscriptionPlan, fetchSubscriptionsPlanData } = useSubscriptionsStore();

  useFetchData(fetchSubscriptionsPlanData);

  const processedData = subscriptionPlan?.map((item: { sub_plan_id: number; sub_plan_model: string }) => {
    try {
      const parsedModel = item.sub_plan_model ? JSON.parse(item.sub_plan_model) : {};
      return {
        sub_plan_id: item.sub_plan_id,
        sub_plan_model: parsedModel,
      };
    } catch (error) {
      console.error('Failed to parse subscription plan model:', error);
      return {
        sub_plan_id: item.sub_plan_id,
        sub_plan_model: {},
      };
    }
  }) || [];

  const newPlan = processedData[0]?.sub_plan_model ?? {};

  const [plans, setPlans] = useState<{ [key: string]: PaymentDetails }>(newPlan);
  const [planId, setPlanId] = useState<number | null>(processedData[0]?.sub_plan_id || null);

  const isInitialMount = useRef(true);



  const handleKeyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    oldKey: string
  ) => {
    const newKey = event.target.value;
    const { [oldKey]: value, ...rest } = plans;
    setPlans({
      ...rest,
      [newKey]: value,
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    field: 'membership' | 'perTaxi'
  ) => {
    const { value } = event.target;
    setPlans({
      ...plans,
      [key]: {
        ...plans[key],
        [field]: parseFloat(value) || 0,
      },
    });
  };

  const deleteSubscriptionPlan = (key: string) => {
    const { [key]: _, ...rest } = plans;
    setPlans(rest);
  };

  const handleAddPlan = () => {
    const newKey = (Object.keys(plans).length * 2 + 2)?.toString();
    setPlans({
      ...plans,
      [newKey]: { membership: 0, perTaxi: 0 },
    });
  };

  useEffect(() => {
    setPlans(processedData[0]?.sub_plan_model || {});
    setPlanId(processedData[0]?.sub_plan_id || null);
    isInitialMount.current = false;
  }, []);

  return (
    <div className='borderThemed flex flex-col items-center rounded-lg p-2 my-2 full w-full'>
      <div className="modalHeading">
        <h2 className='modaltext'>Payment Plans</h2>
      </div>

      <ul className='flex flex-col items-center overflow-y-scroll h-[400px] w-full'>
        {Object.entries(plans).map(([key, value]) => (
          <li key={key} className='flex flex-col items-center justify-between w-[90%] border-[1px] p-2 m-1 rounded-md'>
            <div className="flex flex-row items-center justify-between px-4 w-[70%]">
              <h3>Taxis:</h3>
              <input
                type="number"
                className="tinyNumberInput"
                value={key}
                onChange={(event) => handleKeyChange(event, key)}
              />
              <button onClick={() => deleteSubscriptionPlan(key)} className='px-2 py-1 bg-red-500 text-white rounded-md'>
                Delete
              </button>
            </div>
            <div className="flex flex-row w-[80%] justify-between px-4">
              <div className="flex flex-col items-center">
                <label htmlFor={`membership-${key}`}>Membership</label>
                <input
                  id={`membership-${key}`}
                  type="number"
                  className="tinyNumberInput"
                  value={value.membership}
                  onChange={(event) => handleInputChange(event, key, 'membership')}
                />
              </div>
              <div className="flex flex-col items-center">
                <label htmlFor={`perTaxi-${key}`}>Per Taxi</label>
                <input
                  id={`perTaxi-${key}`}
                  type="number"
                  className="tinyNumberInput"
                  value={value.perTaxi}
                  onChange={(event) => handleInputChange(event, key, 'perTaxi')}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="modalBtnFooter">
        <button className='pageBtngood' onClick={handleAddPlan}>
          Add New Plan
        </button>
      </div>
    </div>
  );
};

export default PaymentPlanComponent;
