import React from 'react';
import { EventData } from '../../types/Types';

interface EventTileProps {
  event: EventData;
}

function EventTile({ event }: EventTileProps) {

  return (
  <div className=' bg-green-500 border-black h-[100px] border-[2px] p-2 m-2 rounded-lg cursor-pointer text-gray-200 hover:bg-green-500 duration-200'>
    <p className="text-sm text-center text-slate-900"> {event.title}</p>
    <p className='text-sm text-center text-slate-900'>{event.date}</p>
    <hr/>
    <div className="text-sm text-center text-slate-900"> {event.description} </div>
  </div>
  );
}

export default EventTile;
