import React, { useState } from 'react';
import { Access, Complaint } from '../../../../../../types/Types';
import NewCaseModal from './subModal/NewCaseModal';
import useAuthStore from '../../../../../../stores/AuthStore';
import useOwnerStore from '../../../../../../stores/OwnerStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';
import useVehicleStore from '../../../../../../stores/VehicleStore';
import useDriverStore from '../../../../../../stores/DriverStore';
import useAccess from '../../../../../../hooks/useUserAccess';

interface ComplaintModalProps {
  setComplaintModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: Complaint;
}

const ComplainantModal: React.FC<ComplaintModalProps> = ({ setComplaintModal, selectedRow }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { ownerData, fetchOwnerData } = useOwnerStore();
  const { vehicleData, fetchVehicleData } = useVehicleStore();
  const { driverData, fetchDriverData } = useDriverStore();
  // State
  const [newCaseModal, setNewCaseModal] = useState<boolean>(false);
  // Params
  const access: any =  useAccess();
  // Data Fetch
  useFetchData(fetchOwnerData);
  useFetchData(fetchVehicleData);
  useFetchData(fetchDriverData);


  const owner = ownerData.find((owner) => (owner.user_id)?.toString() === (selectedRow.dc_owner)?.toString());
  const vehicle = vehicleData.find((vehicle) => vehicle.vehicle_id === (selectedRow.dc_vehicle)?.toString());
  const driver = driverData.find((driver) => driver.driver_id === `${selectedRow.dc_driver}`);

  const date = new Date(selectedRow.dc_date);

  const day = date.getDate(); // Get day of the month (1-31)
  const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
  const year = date.getFullYear(); // Get full year

  const handleNewCaseModal = () => {
    setNewCaseModal(!newCaseModal);
  }


  return (
    <div className='mediumModal'>

      {/* MODAL HEADER */}
      <div className="modalHeader">
        <h1 className="modalHeading">New Complaint Information</h1>
      </div>
      {/*  */}

      <div className="modalContent">

        <div className="flex justify-between w-full">
          <div className="flex flex-col w-[48%]">
            <div className="">
              <h4 className='modaltext'>Complainant Information</h4>
              <div className="borderThemed rounded-xl flex justify-between w-full p-2">
                <div className=" w-[50%]">
                  <p>Name :</p>
                  <p>Phone Number :</p>
                  <p>Email :</p>
                </div>

                <div className=" w-[50%]">
                  <p>{selectedRow.customer_name}</p>
                  <p>{selectedRow.customer_phone}</p>
                  <p> {selectedRow.customer_email}</p>
                </div>

              </div>
            </div>

            {/* COMPLAINT INFORMATION */}
            <div className="">
              <h4 className='modaltext'>Complaint:</h4>
              <div className="borderThemed rounded-xl flex justify-between w-full p-2 h-full">
                <div className="w-[50%]">
                  <p>Title :</p>
                  <p>Date :</p>
                  <p>Description :</p>
                </div>

                <div className="w-[50%]">
                  <p> {selectedRow.complaint_title}</p>
                  <p>{`${day} ${month} ${year}`}</p>
                  <p>{selectedRow.complaint_desc}</p>
                </div>

              </div>
            </div>



          </div>
          <div className=" w-[48%] ">
            <h4 className='modaltext'>Vehicle / Driver Information</h4>
            <div className="borderThemed rounded-xl flex justify-between w-full p-2">
              <div className="">
                <p>Registration :</p>
                <p>Make :</p>
                <p>Model :</p>
                <p>Year :</p>
                <p>Owner Name :</p>
                <p>Owner Phone :</p>
                <p>Owner Email :</p>
                <p>Driver Name</p>
                <p>Driver Phone</p>
              </div>
              <div className="">
                <p>{vehicle ? vehicle.vehicle_registration : 'N/A'}</p>
                <p>{vehicle ? vehicle.vehicle_make : 'N/A'}</p>
                <p>{vehicle ? vehicle.vehicle_model : 'N/A'}</p>
                <p>{vehicle ? vehicle.vehicle_year : 'N/A'}</p>
                <p>{owner ? owner.user_name + " " + owner.user_surname : 'N/A'}</p>
                <p>{owner ? owner.user_phone : 'N/A'}</p>
                <p>{owner ? owner.user_email : 'N/A'}</p>
                <p>{driver ? driver.driver_name : 'N/A'}</p>
                <p>{driver ? driver.driver_phone : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modalBtnFooter">
        {access.DC.edit && (
          <button className='pageBtnWarn'
            onClick={handleNewCaseModal}>Progress Case</button>

        )}
        <button className='pageBtncancel' onClick={() => setComplaintModal(false)}>Close</button>
      </div>

      {newCaseModal &&
        <div className="modalBackground">
          <NewCaseModal setNewCaseModal={setNewCaseModal} selectedRow={selectedRow} />
        </div>
      }

    </div>
  );
};

export default ComplainantModal;
