import { create } from "zustand";
import { Collections, Collectors, SessionUser } from "../types/Types";
import CollectionUtils from "../utils/CollectionUtils";

interface UserStoreType {
  collectionsData: Collections[];
  collectorsData: Collectors[];
  loading: boolean;
  error: string | null;
  fetchCollectionsData: (sessionUser: SessionUser) => Promise<void>;
  fetchCollectorsData: (sessionUser: SessionUser) => Promise<void>;
  updateCollectionsData(sessionUser: SessionUser): unknown;
  updateCollectorsData(sessionUser: SessionUser): unknown;
}

const useColletionsStore = create<UserStoreType>((set, get) => ({
  collectionsData: [],
  collectorsData: [],
  loading: false,
  error: null,

  // ----- Collections Data ----- //
  fetchCollectionsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateCollectionsData(sessionUser);
  },

  updateCollectionsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await CollectionUtils.FetchCollectionData(sessionUser);
      set({ collectionsData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch collections",
        loading: false
      });
    }
  },

  // ----- Collectors Data ----- //
  fetchCollectorsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateCollectorsData(sessionUser);
  },

  updateCollectorsData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await CollectionUtils.FetchCollectorsData(sessionUser);
      set({ collectorsData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch collectors",
        loading: false
      });
    }
  }
}));

export default useColletionsStore;
