import OwnerUtils from "./OwnerUtils";
import VehicleUtils from "./VehicleUtils";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;

export default class CollectionUtils {

  // FETCH DRIVER DATA USING THE ASSOCIATION
  static FetchCollectionData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/get_collections`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  }

  static UpdateCollection = async (formData: any, sessionUser: any) => {
      if (sessionUser) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/collection/update_collection`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ ...formData, }),
          });

          if (!response.ok) {
            throw new Error('Failed to update driver information');
          }
          // Handle successful update

        } catch (error) {
          console.error('Error updating driver:', error);
          throw error;
        }
      }
    };

  // THIS IS THE COLLECTORS DATA
  static FetchCollectorsData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/get_collection_data`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch Collectors:', error);
      }
    }
  }

  static CreateNewCollection = async (sessionUser: any, collectionId: string, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/create_collection`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ collectionId, ...formData }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // VEHICLE COLLECTIONS
        if (formData.vehicleCollection === true) {
          const vehicleData = await VehicleUtils.FetchVehicles(sessionUser);
          console.log(vehicleData);

          for (const vehicle of vehicleData) {
            const vehicleResponse = await fetch(`${API_BASE_URL}/api/collection/create_collection_data`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionUser.accessToken}`,
              },
              body: JSON.stringify({
                collectionId,
                vehicleCollection: parseInt(formData.vehicleAmount),
                user: `${vehicle.vehicle_registration} (Vehicle) `,
                userId: vehicle.vehicle_id,
              }),
            });

            if (!vehicleResponse.ok) {
              throw new Error('Network response was not ok for vehicle registration: ' + vehicle.vehicle_registration);
            }
          }
        }

        // OWNER COLLECTIONS
        if (formData.ownerCollection === true) {
          const OwnerData = await OwnerUtils.FetchOwnerData(sessionUser);

          for (const owner of OwnerData) {
            const ownerResponse = await fetch(`${API_BASE_URL}/api/collection/create_collection_data`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionUser.accessToken}`,
              },
              body: JSON.stringify({
                collectionId,
                ownerCollection: parseInt(formData.ownerAmount),
                user: `${owner.user_name} (Owner) `,
                userId: owner.user_id,
              }),
            });

            if (!ownerResponse.ok) {
              throw new Error('Network response was not ok for vehicle registration: ' + owner.user_name);
            }
          }
        }

      } catch (error) {
        console.error('Error creating collection:', error);
        throw error;
      }
    }
  }

  // THIS IS TO DELETE THE COLLECTION
  static DeleteCollection = async (sessionUser: any, collectionId: any,) => {
    if (sessionUser) {
      try {
        if (sessionUser) {
          const response = await fetch(`${API_BASE_URL}/api/collection/delete_collection`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ collectionId: collectionId }),
          });

          if (!response.ok) {
            console.error('Error deleting collection:', response.statusText);
            throw new Error('Network response was not ok');
          }
        }
      } catch (error) {
        console.error('Error deleting collection:', error);
      }
    }
  }

  static RecordCollection = async (sessionUser: any, formData: any, collectorId: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/update_collection_data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({
            ...formData,
            collectorId: collectorId
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to update driver information');
        }

      } catch (error) {
        console.error('Error updating driver:', error);
        throw error;
      }
    }
  };

  static GetOwnerDriverNames = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/owner_driver_collection`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to update driver information');
        }
        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Error updating driver:', error);
      }
    }
  };

  // FOR THE COLLECTIONS MODAL    
  static DeleteMadeCollector = async (sessionUser: any, collectorId: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/delete_collection_data`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({
            collectorId: collectorId,
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to update driver information');
        }
      } catch (error) {
        console.error('Error updating driver:', error);
      }
    }
  };

  // RECORDING A NEW COLLECTION    
  static CreateNewCollector = async (sessionUser: any, user: any, collectionId: string, amount: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/create_collection_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({
            collectionId: collectionId,
            user: user,
            collectionAmount: parseInt(amount)
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to update driver information');
        }
      } catch (error) {
        console.error('Error updating driver:', error);
      }
    }
  };

  //-- OWNER UTILS --//
  static FetchOwnerCollectorsData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/collection/get_owner_collectors`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch owners collections:', error);
        throw error;
      }
    }
  }

}