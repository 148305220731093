import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../components/SessionContext';
import { HashLoader } from 'react-spinners';

const Protected = (): JSX.Element => {
  const { user } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/signin');
    } else {
      // Redirect based on user role
      if (user.role === 'Secretary') {
        navigate('/admin/home');
      } else if (user.role === 'Owner') {
        navigate('/owner/home');
      } else if (user.role === 'Finance') {
        navigate('/admin/home');
      } else {
        navigate('/');
      }
    }
  }, [user, navigate]);

  return (
    <div className='absoluteLoader'>
      <HashLoader color="#36d7b7" />
    </div>
  );
};

export default Protected;
