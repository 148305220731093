import React, { useState, useEffect } from 'react';
import useAuthStore from '../../../../../../stores/AuthStore';
import useUserStore from '../../../../../../stores/UserStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

interface AccessControlProps {
  userId: string;
  onSave: (updatedAccess: any) => void; // Callback function to handle saving the updated access
}

interface AccessCategory {
  view: boolean;
  edit: boolean;
}

interface UserAccess {
  Secretary: AccessCategory;
  Finance: AccessCategory;
  Transport: AccessCategory;
  Communication: AccessCategory;
  Staff: AccessCategory;
  Schedule: AccessCategory;
  DC: AccessCategory;
  Owners: AccessCategory;
  Settings: AccessCategory;
}

const AccessControl: React.FC<AccessControlProps> = ({ userId, onSave }) => {
  const { sessionUser } = useAuthStore();
  const { userAccess, fetchUserAccess } = useUserStore();
  const [accessData, setAccessData] = useState<UserAccess | null>(null);

  useFetchData(fetchUserAccess);

  // Fetch user's access data based on userId
  useEffect(() => {
    const access = userAccess?.find((access: any) => access.user_id === userId);
    if (access) {
      setAccessData(JSON.parse(access.user_access_data)); // Assuming the permissions are stored in access.permissions
    }
  }, [userAccess, userId]);

  // Handle change in access for view or edit permissions
  const handleAccessChange = (category: keyof UserAccess, permissionType: keyof AccessCategory) => {
    if (accessData) {
      setAccessData({
        ...accessData,
        [category]: {
          ...accessData[category],
          [permissionType]: !accessData[category][permissionType],
        },
      });
    }
  };

  // Save the updated access
  const handleSave = () => {
    if (accessData) {
      onSave(accessData);
    }
  };

  if (!accessData ) {
    if (!sessionUser) return null;
    return <div>
      {sessionUser.role === 'Secretary' ?
        <div className="modaltext">Secretary Access</div>
        :
        <div className='modaltext'> Default Owner Access</div>
      }
    </div>
  }

  return (
    <div className="accessControlContainer">
      <h2>User Access Control</h2>

      {Object.keys(accessData).map((category) => (
        <div key={category} className="accessCategory">
          <div className="flex justify-between w-[20vw] m-2">
            <h3 className='modaltext'>{category}</h3>

            <div className="flex justify-between w-[9vw]">
              <label>
                <input
                  type="checkbox"
                  checked={accessData[category as keyof UserAccess].view}
                  onChange={() => handleAccessChange(category as keyof UserAccess, 'view')}
                />
                View
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={accessData[category as keyof UserAccess].edit}
                  onChange={() => handleAccessChange(category as keyof UserAccess, 'edit')}
                />
                Edit
              </label>

            </div>
          </div>
        </div>
      ))}

      <div className="modalBtnFooter">
        <button onClick={handleSave} className="pageBtngood">
          Edit Access
        </button>
      </div>
    </div>
  );
};

export default AccessControl;
