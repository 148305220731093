import React, { useState } from 'react';
import toast from 'react-hot-toast';
import FileUtils from '../../../../../../utils/FileUtils';
import useDocumentStore from '../../../../../../stores/DocumentStore';
import useAuthStore from '../../../../../../stores/AuthStore';

interface BucketUploadProps {
  onClose: () => void;
}

const BucketUpload: React.FC<BucketUploadProps> = ({ onClose }) => {
  const { sessionUser } = useAuthStore()
  const { updateOwnerDocsData } = useDocumentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [documentType, setDocumentType] = useState<string>('');
  const [documentName, setDocumentName] = useState<string>(''); // State to store document name

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setDocumentType(type);
    }
  };

  // Handle document name change
  const handleDocumentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
  };

  // Upload document to Firebase
  const handleDocumentUpload = async () => {
    if (!selectedFile || !documentType || !documentName || !sessionUser) {
      toast.error('Please select a file, document type, and provide a document name.');
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('type', documentType);
      formData.append('name', documentName); // Append document name

      // Upload document to Firebase (or the storage utility you're using)
      await FileUtils.uploadUserBucket(sessionUser, formData);

      toast.success('Document uploaded successfully.');
      updateOwnerDocsData(sessionUser); // Refresh the documents after upload

      onClose(); // Close the modal after successful upload
    } catch (error) {
      console.error('Error uploading document:', error);
      toast.error('Failed to upload document. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='smallModal'>
      <div className="modalHeader">
        <h1 className="modalHeading">Upload Document</h1>
      </div>

      <div className="modalContent">
        {/* File input */}
        <input
          className="w-[200px]"
          type="file"
          accept=".pdf,.jpg,.jpeg,.png"
          onChange={(e) => handleFileChange(e, 'DriverLicense')} // Replace 'DriverLicense' with the correct document type as needed
        />

        {/* Document name input */}
        <div>
          <p className='modaltext'>Document Name</p>
          <input
            className='normalTextInput'
            type="text"
            value={documentName}
            onChange={handleDocumentNameChange} // Capture document name
            placeholder="Enter document name"
          />
        </div>
      </div>

      <div className="modalBtnFooter">
        <button
          onClick={handleDocumentUpload}
          className="pageBtngood"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Uploading...' : 'Upload'}
        </button>
        <button onClick={onClose} className="pageBtncancel">
          Close
        </button>
      </div>
    </div>
  );
};

export default BucketUpload;
