import { SessionUser } from "@/types/Types";
import CommonUtils from "./CommonUtils";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;

export default class NotificationUtils {

  // FETCH DRIVER DATA USING THE ASSOCIATION
  static FetchNotificationData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/notification/get_notifications`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (failed to fetch notifications)');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        throw error;
      }
    }
  };

  static UpdateDriverNotification = async (sessionUser: SessionUser, formData: any, userData: any) => {
    if (sessionUser) {
      try {
        const notificationId = CommonUtils.GenerateRandomString();
        const title = `Update from ${sessionUser.name}`;
        const notification = `Driver '${formData.fullName}' is now ${formData.driverStatus}.`;

        const response = await fetch(`${API_BASE_URL}/api/notification/create_notification`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ notificationId, title, notification }),
        });

        if (!response.ok) {
          throw new Error('Failed to update driver information');
        }

        // OCCUPY READ NOTIFICATION HERE
        try {
          for (const user of userData) {
            const readResponse = await fetch(`${API_BASE_URL}/api/notification/new_read_notifications`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionUser.accessToken}`,
              },
              body: JSON.stringify({ notificationId, userId: user.user_id }),
            });

            if (!readResponse.ok) {
              throw new Error(`Failed to mark notification as read for user ${user.user_id}`);
            }
          }
        } catch (error) {
          console.error('Error marking notifications as read:', error);
          throw error;
        }
      } catch (error) {
        console.error('Error updating driver:', error);
        throw error;
      }
    }
  };


  // THIS IS TO DELETE THE COLLECTION
  static DeleteCollection = async (sessionUser: SessionUser, collectionId: any,) => {
    if (sessionUser) {
      try {
        if (sessionUser) {
          const response = await fetch(`${API_BASE_URL}/api/collection/delete_collection`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ collectionId: collectionId }),
          });

          if (!response.ok) {
            console.error('Error deleting collection:', response.statusText);
            throw new Error('Network response was not ok');
          }

          alert('Collection Deleted successfully');
        }
      } catch (error) {
        console.error('Error deleting collection:', error);
      }
    }
  };


}