import React, { useState, useEffect } from "react";
import PerSubscriptionTable from "../tables/PerSubsriptionTable";
import CommonUtils from "../../../../../../utils/CommonUtils";

interface SubscriptionModalProps {
  setSubscriptionModal: (state: boolean) => void;
  selectedRow: {
    subscription_id: string;
    subscription_name: string;
    subscription_desc: string;
    subscription_period: string;
    subscription_status: string;
    subscriptions_start: string;
    subscriptions_due: string;
    subscription_made: string;
  };
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ setSubscriptionModal, selectedRow }) => {

  const [formData, setFormData] = useState({
    subscription_id: selectedRow.subscription_id,
    subscription_name: selectedRow.subscription_name,
    subscription_desc: selectedRow.subscription_desc,
    subscription_period: selectedRow.subscription_period,
    subscription_status: selectedRow.subscription_status,
    subscriptions_start: new Date(selectedRow.subscriptions_start),
    subscriptions_due: new Date(selectedRow.subscriptions_due),
    subscription_made: new Date(selectedRow.subscription_made),
  });


  const handleCollectionModalClose = () => {
    setSubscriptionModal(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const dueDate = CommonUtils.formatDate(formData.subscriptions_due);

  // --- //

  useEffect(() => {
    setFormData({
      subscription_id: selectedRow.subscription_id,
      subscription_name: selectedRow.subscription_name,
      subscription_desc: selectedRow.subscription_desc,
      subscription_period: selectedRow.subscription_period,
      subscriptions_due: new Date(selectedRow.subscriptions_due),
      subscription_made: new Date(selectedRow.subscription_made),
      subscription_status: selectedRow.subscription_status,
      subscriptions_start: new Date(selectedRow.subscriptions_start),
    });
  }, [selectedRow]);

  return (
    <div className="largeModal">
      <div className="modalHeader">
        <h1 className="modalHeading">Subscription Information</h1>
      </div>

      <div className="modalContent">
        <div className="h-[100px] rounded-md m-[2px]">
          <div className="flex md:flex-row flex-col">
            <div className="m-2">
              <p className="modaltext">Subscription Name</p>
              <input
                name="subscription_name"
                value={formData.subscription_name}
                onChange={handleInputChange}
                placeholder="Name"
                className="normalTextInput"
              />
            </div>
            <div className="m-2">
              <p className="modaltext">Subscription Description</p>
              <input
                name="subscription_desc"
                value={formData.subscription_desc}
                onChange={handleInputChange}
                placeholder="Description"
                className="longTextInput"
              />
            </div>
            <div className="">
              <p>
                Next Due: {dueDate}
              </p>
              <p>
                Status: {formData.subscription_status}
              </p>
              <p>
                interval: {formData.subscription_period}
              </p>
              <p>
                Subscription Made: {CommonUtils.formatDate(formData.subscription_made)}
              </p>
            </div>
          </div>
        </div>

        <div className="h-[300px] w-full md:mt-0 mt-[230px]">
          <PerSubscriptionTable subscriptionData={selectedRow} />
        </div>
      </div>

      <div className="modalBtnFooter">

        <button className="pageBtncancel" onClick={handleCollectionModalClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
