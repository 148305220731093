import React, { useState, lazy, Suspense } from 'react'
// LAYOUT IMPORT
import { Tab, Tabs } from '@mui/material';
import { HashLoader } from 'react-spinners';
import OwnerLayout from '../../../components/OwnerLayout';
const SecretaryVehicles = lazy(() => import('./tabs/Vehicles'));
const Drivers = lazy(() => import('./tabs/Drivers'));
const Duty = lazy(() => import('./tabs/duty/Duty'));
// MAIN FUNCTION
function OwnerTransport() {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <OwnerLayout >
      <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
        <div className="pageHeight">
          <Tabs value={currentTab} onChange={handleTabChange} centered>
            <Tab label="Vehicles" />
            <Tab label="Drivers" />
            <Tab label="Duty" />
          </Tabs>
          <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>
            {currentTab === 0 && <SecretaryVehicles />}
            {currentTab === 1 && <Drivers />}
            {currentTab === 2 && <Duty />}
          </Suspense>
        </div>
      </Suspense>
    </OwnerLayout>
  )
}

export default OwnerTransport;
