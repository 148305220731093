import React, { useState } from 'react';
import BucketUpload from '../modal/BucketUpload';
import DeleteDialog from '../../../../../../components/common/dialogue/DeleteDialogue';
import FileUtils from '../../../../../../utils/FileUtils';
import toast from 'react-hot-toast';
import { useOwner } from '../../../../components/OwnerContext';

const BucketPage = () => {
  const { sessionUser, userDocs, fetchUserDocs } = useOwner();
  const [uploadModal, setUploadModal] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState<string>('');

  const usersDocs = userDocs.filter((doc: any) => doc.user_id === sessionUser.id);

  const handleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  // Open delete dialog
  const openDeleteDialog = (docId: string) => {
    setDocToDelete(docId);
    setDeleteDialogOpen(true);
  };

  const handleDocDelete = async () => {

    try {
      await FileUtils.DeleteUserDoc(docToDelete, sessionUser);
      toast.success("Document deleted successfully");
      setDeleteDialogOpen(false);
      fetchUserDocs();
    } catch (error) {
      toast.error("Error deleting document");
      setDeleteDialogOpen(false);
    } finally {
      setDocToDelete('');
    }


  }
  return (
    <div>
      <div className="modalHeader">
        <div className="flex items-center">
          <h2 className='modalHeading m-2'>User Files</h2>
          <button className='pageBtngood' onClick={() => handleUploadModal()}>Upload</button>
        </div>
      </div>

      <div>
        <div className='grid grid-cols-4 borderThemed h-[60vh] overflow-y-auto p-2 rounded-md'>
          {usersDocs.map((doc: any) => (
            <div key={doc.id} className="flex flex-col justify-center items-center w-[250px] h-[120px] borderThemed rounded-xl p-2">
              <div className="flex items-center w-full">
                <p className='text-center w-full'>{doc.user_doc_name}</p>
              </div>
              {/* Display formatted date */}
              <p>{new Date(doc.user_doc_date).toDateString()}</p>

              {/* Download Button */}
              <div className="flex">
                <a href={doc.user_doc_link} download={doc.user_doc_name} target="_blank" rel="noopener noreferrer">
                  <button className='pageBtngoodSmall'>Download</button>
                </a>
                <button className='pageBtncancelsmall' onClick={() => openDeleteDialog(doc)}>Delete</button>

              </div>

            </div>
          ))}
        </div>
      </div>
      {uploadModal && (
        <div className="modalBackground">
          <BucketUpload onClose={handleUploadModal} />
        </div>
      )

      }

      {/* Render Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleDocDelete}
        title="Delete Doccument?"
        description={`Are you sure you want to delete ' ${docToDelete?.user_doc_name} ' ?`}
      />
      {/*  */}
    </div>
  );
};

export default BucketPage;
