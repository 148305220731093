
const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;

export default class FileUtils {

  static FetchDriverDocs = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/get_driver_docs`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  };

  static FetchOwnerDocs = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/get_owner_docs`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch owner docs:', error);
        throw error;
      }
    }
  };
  static FetchUserDocs = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/get_user_docs`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  };

  static FetchVehicelDocs = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/get_vehicle_docs`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  }

  static UploadImage = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/upload_image`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        alert('Image uploaded successfully');
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
        throw error;
      }
    }
  };

  static UploadImages = async (
    sessionUser: any,
    selectedRow: any,
    vehiclePic1Link: File | null,
    vehiclePic2Link: File | null,
    vehiclePic3Link: File | null,
    vehiclePic4Link: File | null
  ) => {
    if (sessionUser) {
      try {
        const formData = new FormData();

        // Append the selected vehicle data as a JSON string
        formData.append('vehicle', JSON.stringify(selectedRow));

        // Append each file to the form data with the correct field names
        if (vehiclePic1Link) formData.append('vehicle_pic1', vehiclePic1Link);
        if (vehiclePic2Link) formData.append('vehicle_pic2', vehiclePic2Link);
        if (vehiclePic3Link) formData.append('vehicle_pic3', vehiclePic3Link);
        if (vehiclePic4Link) formData.append('vehicle_pic4', vehiclePic4Link);

        const response = await fetch(`${API_BASE_URL}/api/file/upload_vehicle_images`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Failed to upload images:', error);
        throw error;
      }
    }
  };

  static DeleteVehicleImage = async (sessionUser: any, vehicle_id: string, picture: string) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/delete_vehicle_image`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ vehicle_id, picture }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Failed to delete image:', error);
        throw error;
      }
    }
  };






  static UploadPdf = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/upload_pdf`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }


      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static uploadUserBucket = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/upload_bucket_doc`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }

      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static DeleteUserDoc = async (formData: any, sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/delete_bucket_doc`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }

      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static UploadVehicleRegistrationPdf = async (sessionUser: any, formData: any, selectedRow: any) => {
    if (sessionUser) {
      try {
        formData.append('vehicle', JSON.stringify(selectedRow));
        const response = await fetch(`${API_BASE_URL}/api/file/upload_vehicle_registration`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }


      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  }

  static UploadDriverLicensePdf = async (sessionUser: any, formData: any, driver: any) => {
    if (sessionUser) {
      try {
        // Append driverId to the formData
        formData.append('driver', JSON.stringify(driver));


        // UPLOAD PDF
        const response = await fetch(`${API_BASE_URL}/api/file/upload_driver_license`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }

      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static UploadDriverProfile = async (sessionUser: any, formData: any, driver: any) => {
    if (sessionUser) {
      try {
        // Append driverId to the formData
        formData.append('driver', JSON.stringify(driver));


        // UPLOAD IMAGE
        const response = await fetch(`${API_BASE_URL}/api/file/upload_driver_profile`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }

      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static UploadUserProfile = async (sessionUser: any, formData: any, user: any) => {
    if (sessionUser) {
      try {
        // Append userId to the formData
        formData.append('user', JSON.stringify(user));

        // UPLOAD IMAGE
        const response = await fetch(`${API_BASE_URL}/api/file/upload_user_profile`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }

      } catch (error) {
        console.error('Failed to Upload user profile picture:', error);
        throw error;
      }
    }
  };

  static UploadDriverIdPdf = async (sessionUser: any, formData: any, driver: any) => {
    if (sessionUser) {
      try {
        // Append driverId to the formData
        formData.append('driver', JSON.stringify(driver));


        // UPLOAD PDF
        const response = await fetch(`${API_BASE_URL}/api/file/upload_driver_id`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }

      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  // OWNER DOCCUMENTS

  static UploadPermitDoc = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/owner_permit_upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }


      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static UploadSarsDoc = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/owner_sars_upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }


      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };

  static UploadOwnerIdPdf = async (sessionUser: any, formData: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/file/owner_id_upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok (UploadPdf)');
        }


      } catch (error) {
        console.error('Failed to Upload Pdf:', error);
        throw error;
      }
    }
  };




}