import React, { useEffect, useState } from "react";
import { Owner } from "../../../../../../types/Types";
import toast from "react-hot-toast";
import CommonUtils from "../../../../../../utils/CommonUtils";
import OwnerUtils from "../../../../../../utils/OwnerUtils";
import UserUtils from "../../../../../../utils/UserUtils";
import useAuthStore  from "../../../../../../stores/AuthStore";

interface UserInfoProps {
  currentUser: Owner;
}

const UserInfo: React.FC<UserInfoProps> = ({ currentUser }) => {
  const { sessionUser } = useAuthStore();
  const [formData, setFormData] = useState({
    user_id: currentUser?.user_id,
    user_name: currentUser?.user_name,
    user_surname: currentUser?.user_surname,
    user_email: currentUser?.user_email,
    user_status: currentUser?.user_status,
    user_phone: currentUser?.user_phone,
    user_idNum: currentUser?.user_idNum,
    user_gender: currentUser?.user_gender,
    alternative_name: currentUser?.alternative_name,
    alternative_number: currentUser?.alternative_number,
    user_membership: currentUser?.user_membership,
    user_password: '',
    user_new_password: '',
    user_new_confirm_password: '',
  });

  useEffect(() => {
    setFormData({
      user_id: currentUser?.user_id,
      user_name: currentUser?.user_name,
      user_surname: currentUser?.user_surname,
      user_email: currentUser?.user_email,
      user_status: currentUser?.user_status,
      user_phone: currentUser?.user_phone,
      user_idNum: currentUser?.user_idNum,
      user_gender: currentUser?.user_gender,
      alternative_name: currentUser?.alternative_name,
      alternative_number: currentUser?.alternative_number,
      user_membership: currentUser?.user_membership,
      user_password: '',
      user_new_password: '',
      user_new_confirm_password: '',
    });
  }, [currentUser?.alternative_name, currentUser?.alternative_number, currentUser?.user_email, currentUser?.user_gender, currentUser?.user_id, currentUser?.user_idNum, currentUser?.user_membership, currentUser?.user_name, currentUser?.user_phone, currentUser?.user_status, currentUser?.user_surname]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { user_name, user_surname, user_phone, user_idNum, user_gender, alternative_number } = formData;
    if (!user_name || !user_surname || !user_phone || !user_idNum || !user_gender) {
      toast.error("Please fill in all required fields");
      return false;
    };
    if (formData.user_email && !CommonUtils.validateEmail(formData.user_email)) {
      toast.error("Please enter a valid email address");
      return false;
    };
    if (!CommonUtils.validatePhoneNumber(user_phone) || (alternative_number && !CommonUtils.validatePhoneNumber(alternative_number))) {
      toast.error("Phone number must be a 10-digit number starting with 0 and no spaces.");
      return false;
    };
    if (!CommonUtils.validateIdNumber(user_idNum)) {
      toast.error("Please enter a valid ID number");
      return false;
    }
    return true;
  };

  const handleUserUpdate = () => {
    if (!validateForm()) return;
    try {
      OwnerUtils.UpdateUserDetails(sessionUser, formData);
      toast.success("User details updated successfully");
    } catch (error) {
      toast.error("Failed to update user details");
      console.log(error);
    }

    console.log(formData);
  };

  const handlePasswordUpdate = () => {
    if (formData.user_new_password !== formData.user_new_confirm_password) {
      toast.error("Passwords do not match");
      return;
    };
    if (!formData.user_new_password || !formData.user_new_confirm_password) {
      toast.error("Please fill in all Password fields");
      return;
    }
    try {
      UserUtils.UpdateUserPassword(sessionUser, formData);
      toast.success("User password updated successfully");
    } catch (error) {
      toast.error("Failed to update user password");
    };
  }

  return (
    <div className="">
      <div className="modalHeader">
      </div>

      <div className="modalContent">
        <div className="flex flex-col md:flex-row justify-between">
          {/* <div className="">
            <UserMedia selectedRow={currentUser} />
          </div> */}

          <div className="flex flex-col">
            <h2 className="modaltext">Personal Information</h2>
            <div className="flex flex-row justify-between ">
              <div className="h-[100%] m-[2px]">

                <div className="modalTextRow">
                  <div className="">
                    <p className="font-semibold">Full Name</p>
                    <input className=" longTextInput"
                      name="user_name"
                      value={formData.user_name} // Use formData
                      onChange={handleInputChange}
                      placeholder="Name"
                    />
                  </div>
                  <div className="">
                    <p className="font-semibold">Surname</p>
                    <input className=" longTextInput"
                      name="user_surname"
                      value={formData.user_surname} // Use formData
                      onChange={handleInputChange}
                      placeholder="Name"
                    />
                  </div>

                </div>

                <div className="modalTextRow">
                  <div className="">
                    <p className="modaltext">Email</p>
                    <input className=" longTextInput"
                      name="user_email"
                      value={formData.user_email} // Use formData
                      onChange={handleInputChange}
                      placeholder="Email"
                    />
                  </div>
                  <div className="">
                    <p className="font-semibold">Phone Number</p>
                    <input className=" longTextInput"
                      type="text"
                      name="user_phone"
                      value={formData.user_phone} // Use formData
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                    />
                  </div>

                </div>


                <div className="modalTextRow">
                  <div className="">
                    <p className="font-semibold">ID Number</p>
                    <input className="longTextInput"
                      type="number"
                      name="user_idNum"
                      value={formData.user_idNum} // Use formData
                      onChange={handleInputChange}
                      placeholder="ID Number"
                      disabled
                    />
                  </div>
                  <div className="">
                    <p className="font-semibold">Gender</p>
                    <select className="longTextInput"
                      name="user_gender"
                      value={formData.user_gender} // Use formData
                      onChange={handleInputChange}
                    >
                      <option value="">Select </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>

                </div>

                <div className="modalTextRow">
                  <div className=" ">
                    <p className="font-semibold">Status</p>
                    <select className="longTextInput"
                      name="user_status"
                      value={formData.user_status} // Use formData
                      onChange={handleInputChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Suspended">Suspended</option>
                    </select>
                  </div>
                  <div className="">
                    <p className="font-semibold">Membership Type</p>
                    <select className="longTextInput"
                      name="user_membership"
                      value={formData?.user_membership}
                      onChange={handleInputChange}>
                      <option value="None">None</option>
                      <option value="Full Member">Full Member</option>
                      <option value="Dual Member">Dual Member</option>
                      <option value="Special">Special</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <button onClick={handleUserUpdate} className="pageBtngood">
          Save Changes
        </button>
        {/*  */}
        <hr className="border-slate-700" />

        {/* Password Section */}
        <div className="">
          <h2 className="modaltext">Change Password</h2>
          <div className="flex flex-row justify-between ">
            <div className="h-[100%] m-[2px]">
  
                <div className="">
                  <p className="font-semibold">Current Password</p>
                  <input className="longTextInput"
                    value={''}
                    type="password"
                    name="user_password"
                    onChange={handleInputChange}
                    placeholder="Current Password"
                  />
                </div>
                <div className="">
                  <p className="font-semibold">New Password</p>
                  <input className="longTextInput"
                    type="password"
                    name="user_new_password"
                    value={formData.user_new_password} // Use formData
                    onChange={handleInputChange}
                    placeholder="New Password"

                  />
                </div>
                <div className="">
                  <p className="font-semibold">Confirm Password</p>
                  <input className="longTextInput"
                    type="password"
                    name="user_new_confirm_password"
                    value={formData.user_new_confirm_password} // Use formData
                    onChange={handleInputChange}
                    placeholder="Confirm Password"
                  />
                </div>
              
              <button className="pageBtnWarn"
                onClick={handlePasswordUpdate}>Change Password</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
