import React, { useState } from 'react';
import { SessionUser } from '../../../../../../../../types/Types';
import CommunicationUtils from '../../../../../../../../utils/CommunicationUtils';
import toast from 'react-hot-toast';
import useAuthStore from '../../../../../../../../stores/AuthStore';
import useComsStore from '../../../../../../../../stores/ComsStore';

interface ScheduleComModalProps {
  onClose: () => void;
  com: any;
  type: string;
  body: any;
  recipient: any;
  sender: SessionUser;
  title: string;
  selectedRoles: any
}

const ScheduleComModal: React.FC<ScheduleComModalProps> = ({ onClose, com, type, recipient, sender, body, title, selectedRoles }) => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { updateScheduledComsData } = useComsStore();
  // State
  const [scheduleDate, setScheduleDate] = useState<string>('');

  const handleClose = () => {
    onClose();
  };

  const handleComSchedule = async () => {
    if (!sessionUser) return;
    try {
      await CommunicationUtils.ScheduleComm(sessionUser, {
        target: recipient,
        body: body,
        sender: sender,
        type: type,
        title: title,
        date: scheduleDate,
        selectedRoles: selectedRoles
      });
      updateScheduledComsData(sessionUser);
      handleClose();
      toast.success('Communication scheduled successfully!');
    } catch (error) {
      console.log(error);
      toast.error('Failed to schedule communication');
    }
  };

  return (
    <div className='smallModal'>
      <div className="modalHeader">
        <h1 className="modalHeading">Schedule {type}</h1>
      </div>

      <div className="modalContent">
        <div className="">
          <p>{com?.smsBody || com.emailSubject}</p>

        </div>
        <div className="flex flex-col justify-center items-center w-full h-[80%]">
          <label htmlFor="scheduleDate" className='modaltext'>Schedule Date:</label>
          <input
            className='normalTextInput'
            type="datetime-local"
            name="scheduleDate"
            id="scheduleDate"
            value={scheduleDate}
            onChange={(e) => setScheduleDate(e.target.value)}
          />
        </div>
      </div>

      <div className="modalBtnFooter">
        <button className='pageBtngood' onClick={handleComSchedule}>Schedule</button>
        <button className='pageBtncancel' onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default ScheduleComModal;
