export default class DateUtils {

  static FormatSqlDate = (date: Date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };


  static getDaysBetweenDates(date1: Date, date2: Date) {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  static FormatEventDate(dateStr: string): string {
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(new Date(dateStr));
  };

  static CalendarEventFormat(originalData: any) {
    if (Array.isArray(originalData)) {
      return originalData.map((event: any) => ({
        id: event.event_id?.toString(),
        title: event.event_name,
        description: event.event_desc,
        category: event.event_category,
        start: new Date(event.event_start),
        end: new Date(event.event_stop),
      }));
    } else {
      console.warn('originalData is undefined or not an array');
      return [];
    }
  };

  static shiftDateByOneDay(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0];
  };
};