import React, { useEffect } from 'react'
import UserInfo from '../components/UserInfo'
import { useFetchData } from '../../../../../../hooks/useFetchData';
import useAuthStore from '../../../../../../stores/AuthStore';
import useUserStore from '../../../../../../stores/UserStore';

const Profile = () => {
  const { sessionUser } = useAuthStore();
  const { userData, fetchUserData } = useUserStore();
  const [currentUser, setCurrentUser] = React.useState<any>(null);

  useFetchData(fetchUserData);

  useEffect(() => {
    if (sessionUser && userData) {
      const findCurrentUser = userData.find((user) => user.user_id === sessionUser.id);
      setCurrentUser(findCurrentUser);
    }
  }, [userData]);
  return (
    <div>
      <UserInfo currentUser={currentUser} />
    </div>
  )
}

export default Profile