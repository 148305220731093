// src/components/NotFoundPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col items-center justify-center w-[100vw] h-[100vh]'>
      <div className="flex flex-col items-center">
        <h1 className='text-3xl'>404 - Page Not Found</h1>
        <p className='text-xl'>Sorry, The page you are looking for does not exist.</p>
      </div>
      <div className="">
        <button className='homeScreenBtn' onClick={() => navigate('/')}>Return Home</button>
      </div>
    </div>
  );
};

export default NotFoundPage;
