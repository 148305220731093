import React, { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import DeleteDialog from '../../../../../../components/common/dialogue/DeleteDialogue';
import useExpensesStore from '../../../../../../stores/ExpenseStore';
import ExpensesUtils from '../../../../../../utils/ExpenseUtils';
import useAuthStore from '../../../../../../stores/AuthStore';

interface EventExpenseModalProps {
  setExpenseModal: () => void;
  selectedExpense: any;
}

const ExpenseModal: React.FC<EventExpenseModalProps> = ({ setExpenseModal, selectedExpense }) => {
  // store
  const { sessionUser } = useAuthStore();
  const { fetchExpensesData } = useExpensesStore();
  // state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formData, setFormData] = React.useState({
    expense_id: selectedExpense?.expense_id || 0,
    expense_name: selectedExpense?.expense_name || 0,
    expense_category: selectedExpense?.expense_category || 0,
    expense_amount: selectedExpense?.expense_amount || 0,
    expense_timestamp: selectedExpense?.expense_timestamp || new Date(),
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleEventExpenseDelete = async () => {
    if (!sessionUser) return;
    setIsSubmitting(true);
    try {
      await ExpensesUtils.DeleteExpenses(sessionUser, formData.expense_id);
      fetchExpensesData(sessionUser);
      setExpenseModal();
      toast.success("expense deleted successfully");
    } catch (error) {
      console.error("Failed to delete expense:", error);
      toast.error("Failed to delete expense. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEventExpenseUpdate = async () => {
    if (!sessionUser) return;
    setIsSubmitting(true);
    try {
      await ExpensesUtils.UpdateExpense(sessionUser, formData);
      fetchExpensesData(sessionUser);
      setExpenseModal();
      toast.success("Event expense updated successfully");
    } catch (error) {
      console.error("Failed to update event expense:", error);
      toast.error("Failed to update event expense. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setFormData(selectedExpense);
  }, [selectedExpense]);

  return (
    <div className="smallModal">
      {/* Modal Header */}
      <div className="modalHeader">
        <h1 className="modalHeading">Expense Information</h1>
      </div>

      {/* Modal Content */}
      <div className="modalContent">

        <div className="flex flex-col md:flex-row">
          <div className="">
            <div className="m-2">
              <p className="modaltext">Name</p>
              <input
                className="longTextInput"
                name="expense_name"
                value={formData.expense_name}
                onChange={handleInputChange}
                placeholder="Name"
              />
            </div>
            <div className="m-2">
              <p className="modaltext">Description</p>
              <input
                className="longTextInput"
                name="expense_category"
                value={formData.expense_category}
                onChange={handleInputChange}
                placeholder="Description"
              />
            </div>
            <div className="m-2">
              <p className="modaltext">Amount</p>
              <input
                className="longTextInput"
                name="expense_amount"
                type="number"
                value={formData.expense_amount}
                onChange={handleInputChange}
                placeholder="Amount"
              />
            </div>


          </div>
        </div>

      </div>

      {/* Modal Footer */}
      <div className="modalBtnFooter">
        <button onClick={openDeleteDialog} className="pageBtnWarn">
          Delete
        </button>
        <button onClick={handleEventExpenseUpdate} className="pageBtngood" disabled={isSubmitting}>
          Save Changes
        </button>
        <button onClick={setExpenseModal} className="pageBtncancel">
          Close
        </button>
      </div>

      {/* Render Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleEventExpenseDelete}
        title="Delete Expense?"
        description={`Are you sure you want to delete expense: ${selectedExpense?.expense_name}?`}
      />
      {/*  */}

    </div>
  );
};

export default ExpenseModal;
