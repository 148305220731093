import { Driver, SquadMember } from "@/types/Types";

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;
export default class EventsUtils {

  static CreateNewEvent = async (sessionUser: any, formData: any,) => {
    if (sessionUser?.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/create_event`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, association: sessionUser.association }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log('Event created:');
      } catch (error) {
        console.error('Error creating Event:', error);
      }
    }
  }

  // FETCH DRIVER DATA USING THE ASSOCIATION
  static FetchEventsData = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/get_events`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        };

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };
  };

  // UPDATE/EDIT DRIVER DETAILS
  static UpdateEventsDetails = async (formData: any, sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/update_event`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({
            ...formData,
            association: sessionUser.association,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to update Event information');
        }

      } catch (error) {
        console.error('Error updating driver:', error);
        throw error;
      }
    }
  };

  // THIS IS TO DELETE THE EVENT
  static DeleteEvent = async (sessionUser: any, eventId: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/delete_event`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ eventId: eventId, association: sessionUser.association }),
        });
        if (!response.ok) {
          console.error('Error deleting event:', response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error deleting event:', error);
        throw error;
      }
    } else {
      console.error('No association found in session');
    }
  }
  // THIS IS GET THE EVENTS ASSIGNED
  static EventsAssigned = async (sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/get_events_assigned`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });
        if (!response.ok) {
          console.error('Error getting event assigned data :', response.statusText);
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching assigned events for event:', error);
      }
    } else {
      console.error('No association found in session');
    }
  }

  // THIS IS GET THE EVENTS ASSIGNED
  static AssignToEvents = async (assignForm: any, driverInfo: Driver | SquadMember, eventId: any, sessionUser: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/create_event_assigned`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...assignForm, driverInfo, eventId: eventId }),
        });
        if (!response.ok) {
          console.error('Error getting event assigned data :', response.statusText);
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Error fetching assigned events for event:', error);
        throw error;
      }
    } else {
      console.error('No association found in session');
    }
  }

  // THIS IS TO DELETE THE ASSIGNED DRIVER AND VEHICLE
  static DeleteEventAssigned = async (sessionUser: any, eventAssignId: any) => {
    if (sessionUser) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/event/delete_event_assigned`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ eventAssignId: eventAssignId, association: sessionUser.association }),
        });
        if (!response.ok) {
          console.error('Error deleting event:', response.statusText);
          throw new Error('Network response was not ok');
        }

      } catch (error) {
        console.error('Error deleting event:', error);
        throw error;
      }
    } else {
      console.error('No association found in session');
    }
  }

}