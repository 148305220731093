import { create } from "zustand";
import { SessionUser } from "../types/Types";
import DcUtils from "../utils/DcUtils";

interface UserStoreType {
  dcDataData: any[];
  loading: boolean;
  error: string | null;
  fetchDcData: (sessionUser: SessionUser) => Promise<void>;
  updateDcData(sessionUser: SessionUser): unknown;
}

const useDCStore = create<UserStoreType>((set, get) => ({
  dcDataData: [],
  loading: false,
  error: null,

  // ----- DC Data ----- //
  fetchDcData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateDcData(sessionUser);
  },

  updateDcData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await DcUtils.FetchDcData(sessionUser);
      set({ dcDataData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch dc data",
        loading: false
      });
    }
  }

}));

export default useDCStore;
