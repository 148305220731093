import React, { useState, ChangeEvent } from "react";
import DcUtils from "../../../../../../../utils/DcUtils";
import toast from "react-hot-toast";
import { useOwner } from "../../../../../components/OwnerContext";

interface PendingCaseModalProps {
  setNewCaseModal: (state: boolean) => void;
  selectedRow: any;
}

const PendingCaseModal: React.FC<PendingCaseModalProps> = ({ setNewCaseModal, selectedRow }) => {
  const { sessionUser, fetchCollectorsData, fetchDcData } = useOwner();

  const [findings, setFindings] = useState<string>(''); // State for findings
  const [fines, setFines] = useState<string>(''); // State for findings
  const [status, setStatus] = useState<string>('Resolved Case');  // State for status
  const [error, setError] = useState<string | null>(null); // State for error messages
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

  const handlePageClose = () => {
    setNewCaseModal(false); // Close the modal
  };

  const handleFindingsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFindings(e.target.value); // Update findings state
  };

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value); // Update status state
  };
  const handelFineChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFines(e.target.value); // Update status state
  };

  const handleCaseProgress = async () => {
    setIsSubmitting(true); // Start submission
    setError(null); // Reset error

    try {
      // Send data to the utility method
      await DcUtils.ResolveComplaint(sessionUser, selectedRow.dc_id, findings, status, fines);
      fetchCollectorsData(); // Refresh collectors data
      toast.success('Case progressed successfully!'); // Show success message
      fetchDcData();
      handlePageClose(); // Close modal
    } catch (error) {
      console.error('Failed to progress case:', error);
      setError('Failed to progress case. Please try again.'); // Show error
    } finally {
      setIsSubmitting(false); // End submission
    }
  };

  return (
    <div className="smallModal">
      <div className="modalContent">
        <div className="flex flex-col justify-between">
          <p className="modaltext">Please type in your Pending findings:</p>
          <textarea className="textAreaSmall"
            required
            name="findings"
            id="findings"
            value={findings}
            onChange={handleFindingsChange} // Handling findings input
          />
          <div className="flex">
            <div className="m-2">
              <p className="modaltext">Status</p>
              <select
                name="status"
                id="status"
                className="normalSelectInput"
                value={status}
                onChange={handleStatusChange} // Handling status selection
              >
                <option value="Resolved Case">Resolve</option>
                <option value="Closed Case">Close</option>
              </select>
            </div>

            <div className="m-2">
              <p className="modaltext">Fine</p>
              <input className="normalSelectInput"
                name="fines"
                value={fines}
                onChange={handelFineChange}
                type="number" />
            </div>
          </div>
        </div>
        {error && <p className="errorText">{error}</p>} {/* Display error message */}
      </div>

      <div className="modalBtnFooter">
        <button
          onClick={handleCaseProgress}
          className="pageBtngood"
          disabled={isSubmitting} // Disable the button during submission
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        <button
          onClick={handlePageClose}
          className="pageBtncancel"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PendingCaseModal;
