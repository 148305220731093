import React, { Suspense, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Event } from '../../../../../../types/Types';
import { HashLoader } from 'react-spinners';
import { useFetchData } from '../../../../../../hooks/useFetchData';
import CreateEvents from '../../../components/schedule/modals/EventsCreate';
import EventModal from '../modals/EventsModal';
import useEventStore from '../../../../../../stores/EventStore';
import useAccess from '../../../../../../hooks/useUserAccess';


const EventTable: React.FC = () => {
  const { eventData, fetchEventData } = useEventStore()
  const [eventModal, setEventModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Event | undefined>(undefined);
  const [createEventModal, setCreateEventModal] = useState(false);
  const access: any =  useAccess();

  useFetchData(fetchEventData);

  const [selectedEvent, setSelectedEvent] = useState<{
    id: number;
    title: string;
    startStr: Date;
    endStr: Date;
    extendedProps: any;
  } | null>(null);


  const handleCloseEventModal = () => {
    setEventModal(false);
    setSelectedEvent(null);
  };

  const handleEventModal = (rowData: Event) => {
    setSelectedRow(rowData);
    setEventModal(true);
  };

  const handleCreateEventModal = () => {
    setCreateEventModal(!createEventModal)
  };

  const handleCloseCreateEventModal = () => {
    setCreateEventModal(false);
  };


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const columns = useMemo(() => [
    { accessorKey: 'event_name', header: 'Event Name', size: 100 },
    { accessorKey: 'event_category', header: 'Category', size: 200 },
    {
      accessorKey: 'event_start', header: 'Strting Date', size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue<string>()),
    },
    {
      accessorKey: 'event_stop', header: 'Ending Date', size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue<string>()),
    },
  ], []);

  return (
    <div className="p-2">
        <MaterialReactTable
          columns={columns}
          data={eventData}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleEventModal(row.original),
            sx: { cursor: 'pointer' },
          })}
          renderTopToolbarCustomActions={() => (

            <div className="flex">
              {access?.Schedule?.edit && (
                <button className="pageBtngood" onClick={handleCreateEventModal}>
                  Create Event
                </button>
              )}
            </div>
          )}
        />

        {/* EVENT MODAL */}
        {access?.Schedule?.edit && eventModal && (
          <div className="modalBackground">
            <EventModal setEventModal={handleCloseEventModal} selectedEvent={selectedRow} />
          </div>
        )}

        {access?.Schedule?.edit && createEventModal && (
          <div className="modalBackground">
            <Suspense fallback={<div><HashLoader color="#36d7b7" /></div>}>
              <CreateEvents setCreateEventModal={handleCloseCreateEventModal} selectedDate={selectedRow} />
            </Suspense>
          </div>
        )}
    </div>
  );
};

export default EventTable;
