/* eslint-disable no-restricted-globals */
import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, MRT_Row } from "material-react-table";
import CollectionModal from "../modals/CollectionModal";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import CreateCollection from "../modals/CreateCollection";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../stores/AuthStore";
import useColletionsStore from "../../../../../../stores/CollectionsStore";
import { useFetchData } from "../../../../../../hooks/useFetchData";
import { Collections } from "../../../../../../types/Types";



const CollectionsTable: React.FC = () => {
  // Stores
  const { sessionUser } = useAuthStore();
  const { collectionsData, fetchCollectionsData, updateCollectionsData } = useColletionsStore();
  // States
  const [collectionModal, setCollectionModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Collections | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<Collections | null>(null);
  const [createCollectionModal, setCreateCollectionModal] = useState(false);

  useFetchData(fetchCollectionsData);

  const handleCreateCollectionModal = () => {
    setCreateCollectionModal(!createCollectionModal);
  };
  const handleCollectionModal = (rowData: Collections) => {
    setSelectedRow(rowData);
    setCollectionModal(true);
  };

  // Open delete dialog
  const openDeleteDialog = (rowData: Collections) => {
    setRowToDelete(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCollectionDelete = async () => {

    if (rowToDelete && sessionUser) {
      try {
        await CollectionUtils.DeleteCollection(sessionUser, rowToDelete.collection_id);
        updateCollectionsData(sessionUser);
        toast.success("Collection deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete collection");
      }
      setDeleteDialogOpen(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Collections>[]>(
    () => [
      {
        accessorKey: "collection_name",
        header: "Collection Name",
        size: 100,
      },
      {
        accessorKey: "collection_desc",
        header: "Description",
        size: 200,
      },
      {
        accessorKey: "collection_amount",
        header: "Collection Amount",
        size: 100,
        Cell: ({ cell }) => `R ${cell.getValue<number>().toFixed(2)}`,
      },
    ],
    []
  );

  const handleExportRows = (rows: MRT_Row<Collections>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('mrt-pdf-example.pdf');
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={collectionsData}
        enableRowActions
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleCollectionModal(row.original),
          sx: {
            cursor: "pointer",
          },
        })}
        renderRowActionMenuItems={({ row, table }) => [
          // eslint-disable-next-line react/jsx-pascal-case
          <MRT_ActionMenuItem
            key="delete"
            label="Delete"
            table={table}
            onClick={() => openDeleteDialog(row.original)} icon={undefined} />,
        ]}
        renderTopToolbarCustomActions={({ table }) => (
          <div>
            <Button
              disabled={collectionsData.length === 0}
              onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export As PDF
            </Button>
            <button className="pageBtngood" onClick={handleCreateCollectionModal}>
              Add Collection
            </button>

          </div>
        )}
      />

      {/* MODLAS */}
      {selectedRow && (
        <div className={`${collectionModal ? "modalBackground" : "hidden"}`}>
          <CollectionModal
            setCollectionModal={setCollectionModal}
            selectedRow={selectedRow} />
        </div>
      )}
      {/*  */}

      {/* CREATE MODAL */}
      {createCollectionModal && (
        <div className="absolute top-0 flex items-center justify-center left-0 z-[100] w-full h-full bg-black bg-opacity-50">
          <CreateCollection setCreateCollectionModal={setCreateCollectionModal} />
        </div>
      )}
      {/*  */}

      {/* DELETE MODAL */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleCollectionDelete}
        title="Delete Marshal?"
        description={`Are you sure you want to delete ${rowToDelete?.collection_name}?`}
      />
      {/*  */}
    </>
  );
};

export default CollectionsTable;
