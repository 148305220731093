import React, { Suspense } from 'react'
import { HashLoader } from 'react-spinners'

import CollectionContainer from '../components/CollectionsContainer';
import SubscriptionsContainer from '../components/SubscriptionsContainer';
import DcFinesContainer from '../components/DcFinesContainer';
import ExpensesContainer from '../components/ExpensesContainer';

function FinanceDash() {

  return (
    <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /> </div>}>

      {/* THE ONLY ONE */}
      <div className="flex flex-col gap-2 md:justify-between w-full">
        <div className=" flex md:flex-row flex-col w-full justify-around">
          <div className="flex ">
            <CollectionContainer />
          </div>
          <div className="flex">
            <SubscriptionsContainer />
          </div>

        </div>
        <div className=" flex md:flex-row flex-col w-full justify-around">
          <div className="flex">
            <DcFinesContainer />
          </div>

          <div className="flex">
            <ExpensesContainer />
          </div>

        </div>
      </div>

    </Suspense>
  )
}

export default FinanceDash