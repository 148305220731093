import React, { useState, ChangeEvent } from "react";
import DcUtils from "../../../../../../../utils/DcUtils";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../../stores/AuthStore";
import useCollectionsStore from "../../../../../../../stores/CollectionsStore";
import useDcStore from "../../../../../../../stores/DCStore";

interface CreateDriverProps {
  setNewCaseModal: (state: boolean) => void;
  selectedRow: any;
}

const NewCaseModal: React.FC<CreateDriverProps> = ({ setNewCaseModal, selectedRow }) => {
  // Store
  const { sessionUser } = useAuthStore();
  const { updateCollectorsData } = useCollectionsStore();
  const { updateDcData } = useDcStore();
  // State
  const [findings, setFindings] = useState<string>('');
  const [status, setStatus] = useState<string>('Pending Case');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePageClose = () => {
    setNewCaseModal(false); // Close the modal
  };

  const handleFindingsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFindings(e.target.value); // Update findings state
  };

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value); // Update status state
  };

  const handleCaseProgress = async () => {
    setIsSubmitting(true); 
    setError(null); 
    if (!sessionUser) return;

    try {
      await DcUtils.UpdateComplaint(sessionUser, selectedRow.dc_id, findings, status);
      updateCollectorsData(sessionUser); 
      updateDcData(sessionUser);
      toast.success('Case progressed successfully!');
      handlePageClose();
    } catch (error) {
      console.error('Failed to progress case:', error);
      setError('Failed to progress case. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="smallModal">
      <div className="modalContent">
        <div className="flex flex-col justify-between">
          <p className="modaltext">Please type in your findings:</p>
          <textarea
            name="findings"
            id="findings"
            className="textAreaSmall"
            value={findings}
            onChange={handleFindingsChange} // Handling findings input
          />
          <select
            name="status"
            id="status"
            className="normalSelectInput"
            value={status}
            onChange={handleStatusChange} // Handling status selection
          >
            <option value="Pending Case">Pending</option>
            <option value="Closed Case">Close</option>
          </select>
        </div>
        {error && <p className="errorText">{error}</p>}
      </div>

      <div className="modalBtnFooter">
        <button
          onClick={handleCaseProgress}
          className="pageBtngood"
          disabled={isSubmitting} // Disable the button during submission
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        <button
          onClick={handlePageClose}
          className="pageBtncancel"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewCaseModal;
