

const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API;

export default class DcUtils {

  // FETCH DRIVER DATA USING THE ASSOCIATION
  static CreateComplaint = async (formData: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dc/create_complaint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return (data);
    } catch (error) {
      console.error('Failed to fetch drivers:', error);
      throw error;
    }
  };

  static UpdateComplaint = async (sessionUser: any, dc_case: any, findings: string, dc_status: string,) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dc/update_complaint`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ dc_case, dc_status, findings }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return (data);
    } catch (error) {
      console.error('Failed to fetch drivers:', error);
      throw error;
    }
  };

  static ResolveComplaint = async (sessionUser: any, dc_case: any, findings: string, dc_status: string, fines: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dc/resolve_complaint`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({ dc_case, dc_status, findings, fines }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return (data);
    } catch (error) {
      console.error('Failed to fetch drivers:', error);
      throw error;
    }
  };


  static FetchDcData = async (sessionUser: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/dc/get_dc_data`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch ranks:', error);
      }
    }
  }

  static FetchOwnerDcData = async (sessionUser: any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/dc/get_owner_dc_data`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return (data);
      } catch (error) {
        console.error('Failed to fetch ranks:', error);
      }
    }
  };

  static RecordFine = async (sessionUser: any, dc_id: any, amount: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dc/record_fine`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionUser.accessToken}`,
        },
        body: JSON.stringify({
          dc_id: dc_id,
          amount: amount
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return (data);
    } catch (error) {
      console.error('Failed to fetch drivers:', error);
      throw error;
    }
  };

  static verifyVehicle = async (vehicleReg: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dc/verify_vehicle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ vehicleReg }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return (data);
    } catch (error) {
      console.error('Failed to fetch drivers:', error);
      throw error;
    }
  };


}