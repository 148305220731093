import { create } from "zustand";
import { SessionUser, SquadCar, SquadMember } from "../types/Types";
import SquadUtils from "../utils/SquadUtils";

interface UserStoreType {
  squadCarData: SquadCar[];
  squadMembersData: SquadMember[];
  loading: boolean;
  error: string | null;
  fetchSquadMembersData: (sessionUser: SessionUser) => Promise<void>;
  fetchSquadCarData: (sessionUser: SessionUser) => Promise<void>;
  updateSquadMembersData(sessionUser: SessionUser): unknown;
  updateSquadCarData(sessionUser: SessionUser): unknown;
}

const useSquadStore = create<UserStoreType>((set, get) => ({
  squadCarData: [],
  squadMembersData: [],
  loading: false,
  error: null,

  // ----- Squad Members ----- //

  fetchSquadMembersData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateSquadMembersData(sessionUser);
  },

  updateSquadMembersData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await SquadUtils.GetSquadMembers(sessionUser);
      set({ squadMembersData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch squad members data",
        loading: false
      });
    }
  },

  // ----- Squad Cars ----- //

  fetchSquadCarData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    await get().updateSquadCarData(sessionUser);
  },

  updateSquadCarData: async (sessionUser: SessionUser) => {
    set({ loading: true, error: null });
    try {
      const data = await SquadUtils.GetSquadCars(sessionUser);
      set({ squadCarData: data, loading: false });

    } catch (error) {
      console.error(error);
      set({
        error: "Failed to fetch squad car data",
        loading: false
      });
    }
  }

}));

export default useSquadStore;