import React, { ChangeEvent, useState } from 'react';
import CommunicationUtils from '../../../../../../../utils/CommunicationUtils';
import toast from 'react-hot-toast';
import ScheduleComModal from './submodal/ScheduleComModal';
import useComsStore from '../../../../../../../stores/ComsStore';
import useAuthStore from '../../../../../../../stores/AuthStore';
import {useFetchData} from '../../../../../../../hooks/useFetchData';
interface EmailCommsProps {
}

const SmsComms: React.FC<EmailCommsProps> = () => {
  const { sessionUser } = useAuthStore(); 
  const { contactData, fetchContactData } = useComsStore();
  const [isSending, setIsSending] = useState(false);
  const [scheduleCom, setScheduleCom] = useState(false)
  const [formData, setFormData] = useState({
    smsBody: '',
    ownerSms: false,
    employeeSms: false,
    driverSms: false,
    marshalSms: false,
    SquadSms: false,
    commuterSms: false,
    financeSms: false
  });

  useFetchData(fetchContactData);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, type, value } = e.target;
    const checked = 'checked' in e.target ? (e.target as HTMLInputElement).checked : false;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleScheduleSmsModal = () => {
    setScheduleCom(!scheduleCom)
  };

  const validateForm = () => {
    if (!formData.smsBody) {
      toast.error("Please fill in SMS body field");
      return false;
    };
    return true
  };

  const filteredNumbers = contactData.filter(phoneEntry => {
    if (formData.ownerSms && phoneEntry.role === 'Owner') return true;
    if (formData.SquadSms && phoneEntry.role === 'Finance') return true;
    if (formData.marshalSms && phoneEntry.role === 'Marshal') return true;
    if (formData.commuterSms && phoneEntry.role === 'Commuters') return true;
    if (formData.employeeSms && phoneEntry.role === 'Staff') return true;
    if (formData.SquadSms && phoneEntry.role === 'Squad') return true;
    if (formData.driverSms && phoneEntry.role === 'Driver') return true;
    return false;
  }).map(phoneEntry => phoneEntry.phone).filter(phone => phone !== null);

  const selectedRoles = {
    com_owner: formData.ownerSms ? 1 : 0,
    com_marshal: formData.marshalSms ? 1 : 0,
    com_driver: formData.driverSms ? 1 : 0,
    com_commuter: formData.commuterSms ? 1 : 0,
    com_staff: formData.employeeSms ? 1 : 0,
    com_squad: formData.SquadSms ? 1 : 0,
    com_finance: formData.financeSms ? 1 : 0
  };

  const handleEmailSend = async () => {
    if (!validateForm()) return
    setIsSending(true);
    try {
      // Filter sms based on selected checkboxes

      await CommunicationUtils.SendSmsComm(sessionUser, selectedRoles, {
        body: formData.smsBody,
        recipients: filteredNumbers,
      });
      toast.success('Sms sent successfully');

    } catch (error) {
      toast.error('Error sending sms');
    } finally {
      setIsSending(false);
    }
  };

  return (

    <div className="">
      <h1 className="modalHeading">Send SMS</h1>

      <div className=" flex flex-col items-center justify-around p-2 h-[60%] w-[90%] borderThemed rounded-xl">
        <div className="flex flex-col rounded-lg">
          <textarea
            className='h-[200px] m-2 w-[500px] borderThemed  rounded-xl p-2'
            id="smsBody"
            placeholder="Sms Body"
            name="smsBody"
            value={formData.smsBody}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col w-[64%] items-center m-2 borderThemed rounded-lg">
          <div className="flex flex-row justify-between items-center w-[500px]">
            <div className="flex flex-col items-center">
              <label htmlFor="ownerSms" className="text-sm font-semibold">Owners</label>
              <input
                checked={formData.ownerSms}
                onChange={handleChange}
                type="checkbox"
                id="ownerSms"
                name="ownerSms"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="employeeSms" className="text-sm font-semibold">Staff</label>
              <input
                checked={formData.employeeSms}
                onChange={handleChange}
                type="checkbox"
                id="employeeSms"
                name="employeeSms"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="driverSms" className="text-md font-semibold">Drivers</label>
              <input
                checked={formData.driverSms}
                onChange={handleChange}
                type="checkbox"
                id="driverSms"
                name="driverSms"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="marshalSms" className="text-md font-semibold">Marshals</label>
              <input
                checked={formData.marshalSms}
                onChange={handleChange}
                type="checkbox"
                id="marshalSms"
                name="marshalSms"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="SquadSms" className="text-md font-semibold">Squad</label>
              <input
                checked={formData.SquadSms}
                onChange={handleChange}
                type="checkbox"
                id="SquadSms"
                name="SquadSms"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="commuterSms" className="text-md font-semibold">Commuters</label>
              <input
                checked={formData.commuterSms}
                onChange={handleChange}
                type="checkbox"
                id="commuterSms"
                name="commuterSms"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
          </div>
          <div className="">
            <button className="pageBtngood" onClick={handleEmailSend} disabled={isSending}>
              {isSending ? 'Sending...' : 'Send SMS'}
            </button>
            <button className="pageBtngood" onClick={handleScheduleSmsModal} disabled={isSending}>
              {isSending ? 'Sending...' : 'Schedule Sms'}
            </button>

          </div>
        </div>

      </div>
      {scheduleCom && sessionUser && (
        <div className="modalBackground">
          <ScheduleComModal
            onClose={handleScheduleSmsModal}
            type="Sms"
            com={formData}
            body={formData.smsBody}
            recipient={filteredNumbers}
            sender={sessionUser.id}
            title={"Sms"}
            selectedRoles={selectedRoles}
          />
        </div>
      )}
    </div>
  );
};

export default SmsComms;
