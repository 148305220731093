import React, { useEffect, useState } from 'react';
import { useAdmin, } from './AdminContext';
import { Owner } from '../../../types/Types';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../../components/SessionContext';
import useAuthStore from '../../../stores/AuthStore';


function AdminNavbar() {
  const { ownerDocs } = useAdmin();
  const { sessionUser } = useAuthStore()
  const [navMenu, setHamMenu] = useState(false);
  const navigate = useNavigate();
  const { logout } = useSession();

  const handleMenuClick = () => {
    setHamMenu(!navMenu);
  };

  const handleSignOut = async () => {
    try {
      logout();
      navigate('/');
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  useEffect(() => {
    if (ownerDocs && sessionUser) {
      const userDocsForSelectedUser = ownerDocs.find((doc: Owner) => doc.user_id === sessionUser.id);
      if (userDocsForSelectedUser) {
      }
    }
  }, [ownerDocs]);

  return (
    <div className='navBar'>
      <div className="flex items-center">
      </div>
      <div className="flex items-center mr-[20px] w-full ">

        <div className="flex items-center justify-center px-[20px] mx-[10px] rounded-md p-[2px] w-full">

          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col items-center">
              <p className='navBarText' >{sessionUser?.association_name}</p>
              <div className="flex">
                <p className='mx-2'>{sessionUser?.name}</p>
                -
                <p className='mx-2'>{sessionUser?.role}</p>
              </div>
            </div>
            <button className="pageBtngreen"
              onClick={handleSignOut}>
              Log Out
            </button>
          </div>
          <div className="cursor-pointer" onClick={handleMenuClick}>
            <div className="p-2 w-full">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminNavbar;
