import React, { useState, useEffect } from "react";
import AddCollectionModal from "./AddCollectionModal";
import CollectorTable from "../tables/CollectorTable";
import CollectionUtils from "../../../../../../utils/CollectionUtils";
import DeleteDialog from "../../../../../../components/common/dialogue/DeleteDialogue";
import { Collections } from "../../../../../../types/Types";
import toast from "react-hot-toast";
import useAuthStore from "../../../../../../stores/AuthStore";
import useColletionsStore from "../../../../../../stores/CollectionsStore";

interface DriverModalProps {
  setCollectionModal: (state: boolean) => void;
  selectedRow: Collections;
};


const CollectionModal: React.FC<DriverModalProps> = ({ setCollectionModal, selectedRow }) => {
  // Stores
  const { sessionUser} = useAuthStore();
  const { updateCollectionsData } = useColletionsStore();
  // States
  const [isEditing, setIsEditing] = useState(false);
  const [addCollectionModal, setAddCollectionModal] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState<Collections | null>(null);

  const [formData, setFormData] = useState({
    collection_id: selectedRow?.collection_id,
    collection_name: selectedRow?.collection_name,
    collection_desc: selectedRow?.collection_desc,
    collection_amount: selectedRow?.collection_amount,
  });

  const openDeleteDialog = (selectedRow: any) => {
    setCollectionToDelete(selectedRow);
    setDeleteDialogOpen(true);
  };

  const handleCollectionModalClose = () => {
    setCollectionModal(false);
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // OPERATIONS //

  const handleCollectionUpdate = async () => {
    if (!sessionUser) return;    
    try {
      await CollectionUtils.UpdateCollection(formData, sessionUser);
      updateCollectionsData(sessionUser);
      setIsEditing(false);
      setCollectionModal(false);
    } catch (error) {
      toast.error("Failed to update collection");
      console.error(error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleCollectionDelete = async () => {
    if (collectionToDelete) {
      if (!sessionUser) return;
      try {
        await CollectionUtils.DeleteCollection(sessionUser, collectionToDelete.collection_id);
        updateCollectionsData(sessionUser);
        setCollectionModal(false);
        toast.success("Collection deleted successfully");
      } catch (error) {
        console.log(error);
        toast.error("Failed to delete collection");
      }
      setDeleteDialogOpen(false);
    }
  };
  // -- // 


  useEffect(() => {
    setFormData({
      collection_id: selectedRow?.collection_id,
      collection_name: selectedRow?.collection_name,
      collection_desc: selectedRow?.collection_desc,
      collection_amount: selectedRow?.collection_amount,

    });
  }, [selectedRow]);

  return (
    <div className="mediumModal">

      <div className="modalHeader">
        <h1 className="modalHeading">Collection Information</h1>
      </div>

      <div className="modalContent">
        <div className="h-[100px] rounded-md m-[2px]">
          <div className="flex">
            <div className="m-2 ">
              <p className="font-semibold">Collection Name</p>
              <input className="normalTextInput"
                name="collection_name"
                value={formData.collection_name}
                onChange={handleInputChange}
                placeholder="Name"
                disabled={!isEditing}
              />
            </div>
            <div className="m-2 ">
              <p className="font-semibold">Collection Amount</p>
              <input className="normalTextInput"
                name="collection_amount"
                value={formData.collection_amount}
                type="number"
                onChange={handleInputChange}
                placeholder="Name"
                disabled={!isEditing}
              />
            </div>
            <div className="m-2 ">
              <p className="font-semibold">Collection Description</p>
              <input className="longTextInput"
                name="collection_desc"
                value={formData.collection_desc}
                onChange={handleInputChange}
                placeholder="Description"
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>

        <div className="h-[300px] w-full">
          <div className="flex justify-center">
          
          </div>
          <CollectorTable collectionData={selectedRow} />
        </div>

      </div>

      {/* MODAL FOOTER */}
      <div className="modalBtnFooter">
        <button
          onClick={() => openDeleteDialog(selectedRow)}
          className="pageBtnWarn">
          Delete
        </button>
        <button
          onClick={handleCollectionUpdate}
          className="pageBtngood">
          Save Changes
        </button>
        <button
          onClick={handleCollectionModalClose}
          className="pageBtncancel">
          Close
        </button>
      </div>
      {/*  */}

      {/* Delete Dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleCollectionDelete}
        title="Delete Collection?"
        description={`Are you sure you want to delete: ${collectionToDelete?.collection_name}?`}
      />
      {/*  */}

      { addCollectionModal && formData && (
        <div className="modalBackground">
          <AddCollectionModal setAddCollectionModal={setAddCollectionModal} collectionData={formData} />
        </div>)
      }

    </div>
  );
};

export default CollectionModal;