import React, { lazy, Suspense } from 'react';
// LAYOUT IMPORT
import { HashLoader } from 'react-spinners';
import { AdminProvider } from '../../../components/AdminContext';
import AdminLayout from '../../../components/AdminLayout';
// COMPONENTS
const OwnerTable = lazy(() => import('./table/OwnerTable'));

const OwnerDashboard: React.FC = () => {

  return (
    <AdminProvider>
      <AdminLayout>
        <Suspense fallback={<div className='loaderBackground'> <HashLoader color="#36d7b7" /></div>}>
          <div className="p-4 rounded bg-slate-400 pageHeight w-[100%]">
            <div className="p-4">
            </div>
            <OwnerTable />
          </div>
        </Suspense>
      </AdminLayout>
    </AdminProvider>
  );
}

export default OwnerDashboard;