const API_BASE_URL = process.env.REACT_APP_API_URL || process.env.REACT_APP_LOCAL_API; 
export default class MarshalUtils {

  static GetMarshals = async( sessionUser:any,) =>{
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/marshal/get_marshals`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ association: sessionUser.association }), // Send association in the request body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return(data);
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    }
  }

  static DeleteMarshals = async(sessionUser:any, marshalId: number,) =>{
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/marshal/delete_marshal`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ marshalId: marshalId ,association: sessionUser.association }), // Send association in the request body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    }
  }

  static CreateNewMarshal = async (sessionUser:any,formData:any,) => {
    if (sessionUser.association) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/marshal/create_marshal`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionUser.accessToken}`,
          },
          body: JSON.stringify({ ...formData, association: sessionUser.association }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('Vehicle created:');
      } catch (error) {
        console.error('Error creating driver:', error);
        throw error;
      }
    }
  }

    static UpdateMarshal = async ( sessionUser:any, formData:any) => {
      if (sessionUser.association) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/marshal/update_marshal`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionUser.accessToken}`,
            },
            body: JSON.stringify({ ...formData, association: sessionUser.association }),
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          console.log('Vehicle created:');
        } catch (error) {
          console.error('Error creating driver:', error);
          throw error;
        }
      }
    }

}
