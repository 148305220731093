import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../components/SessionContext'; // Adjust the import to your context file
import UserUtils from '../utils/UserUtils';
import toast from 'react-hot-toast';
import useAuthStore from '../stores/AuthStore';

function SignIn() {
  const navigate = useNavigate();
  const { setUser } = useSession(); // Use the setUser function from the context
  const [userInfo, setUserInfo] = useState({ idNumber: '', password: '' });
  const [loading, setLoading] = useState(false);
  const { login } = useAuthStore()

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!userInfo.idNumber || !userInfo.password) {
      toast.error('Please enter both username and password.');
      setLoading(false);
      return;
    }

    try {
      const userData = await UserUtils.signInUser(userInfo.idNumber, userInfo.password);
      localStorage.setItem('user', JSON.stringify(userData));
      login({
        access: userData.access,
        id: userData.id,
        name: userData.name,
        email: userData.email,
        accessToken: userData.accessToken,
        role: userData.role,
        surname: userData.surname,
        association: userData.association,
        association_name: userData.association_name,
      })
      setUser(userData);
      navigate('/protected');
    } catch (error) {
      toast.error('Login Failed, please try again.');
      console.log('Sign-in error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="flex flex-col w-full h-screen bg-slate-200 items-center justify-center">
      <header className="p-4 flex flex-row justify-between items-center w-full h-[90px]" >
        <div className="text-3xl font-semibold text-slate-700 cursor-pointer" onClick={() => navigate('/')}>Taxi Admin</div>
        <div className=" flex w-[20%] justify-between">
        </div>
      </header>

      <div className="flex flex-row items-center justify-center bg-slate-300 p-2 m-2 h-[80%] w-full rounded-xl">
        <form onSubmit={handleFormSubmit}
          className="flex flex-col border-slate-700 border-[2px] h-[60vh] w-[30vw]  items-center justify-between p-2 rounded-xl bg-green-400 bg-opacity-50 hover:bg-opacity-100">
          <h1 className="text-slate-700 text-2xl font-semibold">Login To Your Account</h1>
          <div className="flex flex-col">
            <label className="text-xl font-semibold text-slate-700">User Name</label>
            <input className="loginForm"
              type="idNumber"
              placeholder="Enter User name"
              value={userInfo.idNumber}
              onChange={({ target }) => setUserInfo({ ...userInfo, idNumber: target.value })} />
          </div>
          <div className="flex flex-col">
            <label className="text-xl font-semibold text-slate-700">Password</label>
            <input
              type="password"
              placeholder="Enter password"
              className="loginForm"
              value={userInfo.password}
              onChange={({ target }) => setUserInfo({ ...userInfo, password: target.value })} />
          </div>
          <button
            type="submit"
            className="loginBtn"
            disabled={loading}>
            {loading ? 'Loading...' : 'Login'}
          </button>
        </form>
      </div>
    </main>
  );
}

export default SignIn;
