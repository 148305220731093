import React from 'react';
import DashboardPage from './Pages/dashboard/DashboardPage';
import { AdminProvider } from '../components/AdminContext';
import AdminLayout from '../components/AdminLayout';

const FinanceHome = (): JSX.Element => {

    return (
        <AdminProvider>
            <AdminLayout>
                <div className="">
                    <DashboardPage />
                </div>
            </AdminLayout>
        </AdminProvider>
    );
};

export default FinanceHome;