import React, { useState, useEffect, lazy, Suspense } from "react";

// CONTEXT IMPORT
// UTILS
import DateUtils from "../../../../../../utils/DateUtils";
import EventsUtils from "../../../../../../utils/EventsUtils";
import { Autocomplete, TextField } from "@mui/material";
import toast from "react-hot-toast";
import { Driver, SquadCar, SquadMember } from "../../../../../../types/Types";
import { useOwner } from "../../../../components/OwnerContext";

const AssignedEventTile = lazy(() => import("./AssignedEventTile"));

///--///

interface EventModalProps {
  setEventModal: (state: boolean) => void;
  selectedEvent: {
    event_name: string;
    event_desc: any;
    event_category: any;
    event_start: string;
    event_end: string;
    event_id: string;
    id: string;
    title: string;
    startStr: Date;
    endStr: Date;
    extendedProps: any;
  };
}

interface EventsAssigned {
  association_id: number;
  events_assigned_id: number;
  events_assigned_vehicle: string;
  events_assigned_driver: string;
  event_id: string;
  event_assign_id: number;
  event_assign_vehicle: string;
  event_assign_driver: string;
};

const EventModal: React.FC<EventModalProps> = ({ setEventModal, selectedEvent }) => {
  const { sessionUser, fetchEventData, driverData, squadCarData, squadMembersData, vehicleData } = useOwner();
  const [isEditing, setIsEditing] = useState(false);
  const [eventComm, setEventComm] = useState(false);
  const [eventsAssigned, setEventsAssigned] = useState<EventsAssigned[]>([]);

  const formatDate = (date: Date) => DateUtils.FormatSqlDate(date);
  const [formData, setFormData] = useState({
    eventId: selectedEvent?.id,
    eventName: selectedEvent?.title,
    eventCategory: selectedEvent?.extendedProps?.category,
    eventDesc: selectedEvent?.extendedProps?.description,
    eventStart: selectedEvent?.startStr ? formatDate(selectedEvent.startStr) : "",
    eventEnd: selectedEvent?.endStr ? formatDate(selectedEvent.endStr) : "",
  });

  const [assignForm, setAssignForm] = useState({
    eventId: selectedEvent?.id,
    squad_car: "",
    squad_car_id: '',
    vehicle: "",
  });

  // FUNCTIONS

  useEffect(() => {
    setFormData({
      eventId: selectedEvent?.id || selectedEvent.event_id,
      eventName: selectedEvent?.title || selectedEvent.event_name,
      eventDesc: selectedEvent?.extendedProps?.description || selectedEvent.event_desc,
      eventCategory: selectedEvent?.extendedProps?.category || selectedEvent.event_category,
      eventStart: selectedEvent?.startStr ? formatDate(new Date(selectedEvent.startStr)) : selectedEvent?.event_start ? formatDate(new Date(selectedEvent.event_start)) : "",
      eventEnd: selectedEvent?.endStr ? formatDate(new Date(selectedEvent.endStr)) : selectedEvent?.event_end ? formatDate(new Date(selectedEvent.event_end)) : "",
    });
    FetchEventsAssigned();
  }, [selectedEvent]);

  const squadCarOptions = squadCarData?.map((squad_Car: SquadCar) => ({
    label: squad_Car.squad_car_registration,
    id: squad_Car.squad_car_id,
  }));

  const vehicleOptions = vehicleData.map((vehicle) => ({
    label: vehicle.vehicle_registration,
    id: vehicle.vehicle_id,
  }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEventModalClose = () => {
    setIsEditing(false);
    setEventModal(false);
  };

  const crossEventId = selectedEvent.id ? Number(selectedEvent.id) : selectedEvent.event_id

  const filteredEventsAssigned = eventsAssigned?.filter(
    (event) => event.event_id === crossEventId
  );

  const handleAutocompleteChange =
    (type: 'squad_car' | 'vehicle') =>
      (
        _event: any,
        newValue: { label: string; id: string } | null
      ) => {
        if (newValue) {
          setAssignForm((prev) => ({
            ...prev,
            [type]: { car: newValue.label, id: newValue.id }
          }));
        }
      };

  const handleEventAssignedCommunication = () => {
    setEventComm(true)
  }

  // EVENT API
  const handleEventUpdate = async () => {
    try {
      await EventsUtils.UpdateEventsDetails(formData, sessionUser);
      toast.success("Event updated successfully!");
      fetchEventData();
      setEventModal(false);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const FetchEventsAssigned = async () => {
    try {
      const eventsAssigned = await EventsUtils.EventsAssigned(sessionUser);
      setEventsAssigned(eventsAssigned);
    } catch (error) {
      console.log(error);
    }
  };

  // API CALLS

  const handleVehicleAssign = async () => {
    // Check if the vehicle is already assigned
    const isVehicleAssigned = filteredEventsAssigned.some(
      (event) => event.event_assign_vehicle === assignForm.vehicle
    );

    if (isVehicleAssigned) {
      toast.error("This vehicle is already assigned to this event.");
      return;
    }

    let squadDriver = null;
    let vehicleDriver = null;

    // Check for squad car assignment
    if (assignForm.squad_car) {
      squadDriver = squadMembersData.find(
        (member: SquadMember) => member.squad_member_vehicle === assignForm.squad_car.id
      );
    }

    // Check for vehicle assignment
    if (assignForm.vehicle) {
      vehicleDriver = driverData.find(
        (driver: Driver) => driver.driver_vehicle === assignForm.vehicle.id
      );
    }

    try {
      const driverInfo = squadDriver ? squadDriver : vehicleDriver;
      if (!driverInfo) {
        toast.error("No driver found for the selected vehicle or squad car.");
        return;
      }

      // Assign event
      await EventsUtils.AssignToEvents(assignForm, driverInfo, selectedEvent.id, sessionUser);

      fetchEventData();
      toast.success("Event assigned successfully!");

      await FetchEventsAssigned();
      setIsEditing(false);
    } catch (error) {
      console.error("Error assigning event:", error);
      toast.error("An error occurred while assigning the event.");
    } finally {

    }
  };


  return (
    <div className="mediumModal ">

      <div className="modalHeader">
        <h4 className="modalHeading">Event Information</h4>
      </div>

      <div className="modalContent">

        {/* MODAL HEADER */}
        <div className="flex flex-col justify-between h-[500px]">
          <div className="flex flex-col md:flex-row justify-between ">
            <div className="flex flex-col w-[37%] rounded-md m-[2px]">

              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col">
                  <p className="modaltext">Event Name</p>
                  <input className="longTextInput md:normalTextInput"
                    name="eventName"
                    value={formData.eventName}
                    placeholder="Event Name"
                    disabled
                  />
                </div>
                <div className="">
                  <p className="modaltext">Event Category</p>
                  <select className="longTextInput md:normalTextInput"
                    name="eventCategory"
                    disabled
                    onChange={handleInputChange}>
                    <option value="">Select Category</option>
                    <option value="funeral">Funeral</option>
                    <option value="special">Special</option>
                    <option value="Meeting">Meeting</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col">
                  <p className="modaltext">Start Date</p>
                  <input className="longTextInput md:normalTextInput"
                    type="date"
                    name="eventStart"
                    value={formData.eventStart}
                    disabled
                  />
                </div>
                <div className="">
                  <p className="modaltext">Event Date</p>
                  <input className="longTextInput md:normalTextInput"
                    type="date"
                    name="eventEnd"
                    value={formData.eventEnd}
                    disabled
                  />
                </div>
              </div>
              <div className="formSection">
                <div className="flex flex-col">
                  <p className="modaltext">Event Description</p>
                  <textarea
                    name="eventDesc"
                    value={formData.eventDesc}
                    placeholder="Event Description"
                    className="textAreaSmall"
                    disabled
                  />
                </div>
              </div>
            </div>

            {/* EVENTS ASSIGNED SECTION */}
            <div className="flex flex-col h-[80%] ">

              <Suspense fallback={<div>Loading...</div>}>
                <div className="h-[350px] w-full borderThemed overflow-y-auto rounded-md m-2">
                  {filteredEventsAssigned?.length > 0 ? (
                    filteredEventsAssigned.map((event, index) => (
                      <AssignedEventTile key={index} event={event}
                        FetchEventsAssigned={FetchEventsAssigned}
                      />
                    ))
                  ) : (
                    <div className="text-center p-4">Nothing Assigned Yet</div>
                  )}
                </div>
              </Suspense>
            </div>

          </div>
        </div>
      </div>

      <div className="modalBtnFooter">

        <button
          color="success"
          onClick={handleEventModalClose}
          className="pageBtncancel">
          Close
        </button>

      </div>

    </div>
  );
};

export default EventModal;
