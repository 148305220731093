import React, { useState, ChangeEvent } from "react";
import EventsFinanceUtils from "../../../../../../utils/EventsFinanceUtils";
import toast from "react-hot-toast";
import useEventFinanceStore from "../../../../../../stores/EventFinanceStore";
import useAuthStore from "../../../../../../stores/AuthStore";

interface CreateDriverProps {
  handleAddExpenseModal: (state: boolean) => void;
  selectedEvent: { id: number; title: string };
}

interface FormData {
  event_finance_name: string;
  event_finance_desc: string;
  event_finance_amount: string;
}

const ExpenseAdd: React.FC<CreateDriverProps> = ({ handleAddExpenseModal, selectedEvent }) => {
  const { sessionUser } = useAuthStore()
  const { updateEventFinanceData } = useEventFinanceStore();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState<FormData>({
    event_finance_name: "",
    event_finance_desc: "",
    event_finance_amount: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    if (!formData.event_finance_name || !formData.event_finance_amount) {
      toast.error("Please fill in all required fields.");
      return false;
    }
    return true;
  }


  const handleEventFinanceSubmit = async () => {
    if (!validateForm() || !sessionUser) {
      return;
    }
    setIsSubmitting(true);
    try {
      await EventsFinanceUtils.CreateEventFinance(sessionUser, selectedEvent.id, formData);
      updateEventFinanceData(sessionUser);
      toast.success("Expense created successfully!");
      handleAddExpenseModal(false);
    } catch (error) {
      console.error("Failed to create expense:", error);
      toast.error("Failed to create expense. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="smallModal">
      <div className="modalHeader">
        <h1 className="text-grey-800 text-2xl font-normal">
          Record An Expense for &apos;{selectedEvent.title}&apos;
        </h1>
      </div>

      <div className="modalContent">
        <div className="flex flex-col">
          <div className="flex md:flex-row flex-col">
            <div className="m-2">
              <p className="modaltext">Expense Name</p>
              <input
                className="longTextInput"
                type="text"
                name="event_finance_name"
                value={formData.event_finance_name}
                onChange={handleChange}
                placeholder="Expense Name"
              />
            </div>
            <div className="m-2">
              <p className="modaltext">Amount</p>
              <input
                className="longTextInput"
                type="number"
                name="event_finance_amount"
                value={formData.event_finance_amount}
                onChange={handleChange}
                placeholder="Amount"
              />
            </div>
          </div>
          <div className="m-2">
            <p className="modaltext">Description</p>
            <input
              className="textAreaSmall"
              type="text"
              name="event_finance_desc"
              value={formData.event_finance_desc}
              onChange={handleChange}
              placeholder="Description"
            />
          </div>
        </div>
      </div>

      <div className="modalBtnFooter">
        <button
          onClick={handleEventFinanceSubmit}
          className="pageBtngood"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting.." : "Submit"}
        </button>
        <button
          onClick={() => handleAddExpenseModal(false)}
          className="pageBtncancel"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ExpenseAdd;
