import React, { useEffect } from 'react'
import UserInfo from '../components/UserInfo'
import SecSecurity from '../components/SecSecurity';
import { useOwner } from '../../../../components/OwnerContext';

const Profile = () => {
  const { sessionUser, userData } = useOwner();
  const [currentUser, setCurrentUser] = React.useState<any>(null);

  useEffect(() => {
    if (sessionUser && userData) {
      const findCurrentUser = userData.find((user) => user.user_id === sessionUser.id);
      setCurrentUser(findCurrentUser);
    }
  }, [userData]);
  return (
    <div>
      <UserInfo currentUser={currentUser} />
      <SecSecurity />
    </div>
  )
}

export default Profile