import React from 'react';
import { Coms } from '../../../../../../types/Types';
import ComsTileModal from './modals/ComsTileModal';
import useUserStore from '../../../../../../stores/UserStore';
import { useFetchData } from '../../../../../../hooks/useFetchData';

interface ComTIleProps {
  com: Coms;
}

const ComTIle: React.FC<ComTIleProps> = ({ com }) => {
  const [comModal, setComModal] = React.useState(false);
  const { userData, fetchUserData } = useUserStore();

  useFetchData(fetchUserData);

  const sender = userData.find((user) => user.user_id === (com.com_sender).toString());
  const comDate = new Date(com.com_date).toDateString();

  const handleComTileClick = () => {
    setComModal(!comModal);
  };

  return (
    <div onClick={handleComTileClick} className="flex flex-row borderThemed justify-between p-2 rounded-md mb-2 cursor-pointer hover:shadow-xl duration-200">
      <div className="">
        <p className="modaltext">From: {sender?.user_name} ({sender?.user_role})</p>
        <h3 className="text-md font-bold">{com.com_subject}</h3>
        <p className="modaltext">{com.com_message}</p>
      </div>
      <p className="modaltext">{comDate}</p>
      {comModal &&
        <div className="modalBackground">
          <ComsTileModal com={com} comModal={comModal} handleComTileClick={handleComTileClick} />
        </div>
      }
    </div>
  );
};

export default ComTIle;
