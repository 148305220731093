import React, { ChangeEvent, useState } from 'react';
import CommunicationUtils from '../../../../../../../utils/CommunicationUtils';
import toast from 'react-hot-toast';
import ScheduleComModal from './submodal/ScheduleComModal';
import useAuthStore from '../../../../../../../stores/AuthStore';
import useComsStore from '../../../../../../../stores/ComsStore';
import { useFetchData } from '../../../../../../../hooks/useFetchData';

interface EmailCommsProps {
}

const EmailComms: React.FC<EmailCommsProps> = () => {
  const { sessionUser } = useAuthStore();
  const { contactData, fetchComsData} = useComsStore();
  const [isSending, setIsSending] = useState(false);
  const [scheduleCom, setScheduleCom] = useState(false)
  const [formData, setFormData] = useState({
    emailSubject: '',
    emailBody: '',
    ownerEmail: false,
    financeEmail: false,
    driverEmail: false,
    marshalEmail: false,
    commuterEmail: false,
    StaffEmail: false,
    SquadEmail: false,
  });

  useFetchData(fetchComsData);


  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, type, value } = e.target;
    const checked = 'checked' in e.target ? (e.target as HTMLInputElement).checked : false;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleScheduleComModal = () => {
    setScheduleCom(!scheduleCom)
  };

  const filteredEmails = contactData.filter(phoneEntry => {
    if (formData.ownerEmail && phoneEntry.role === 'Owner') return true;
    // if (formData.SquadSms && phoneEntry.role === 'Finance') return true;
    if (formData.marshalEmail && phoneEntry.role === 'Marshal') return true;
    if (formData.commuterEmail && phoneEntry.role === 'Commuters') return true;
    if (formData.StaffEmail && phoneEntry.role === 'Staff') return true;
    if (formData.SquadEmail && phoneEntry.role === 'Squad') return true;
    if (formData.driverEmail && phoneEntry.role === 'Driver') return true;
    return false;
  }).map(emailEntry => emailEntry.email).filter(email => email !== null);

  const selectedRoles = {
    com_owner: formData.ownerEmail ? 1 : 0,
    com_marshal: formData.marshalEmail ? 1 : 0,
    com_driver: formData.driverEmail ? 1 : 0,
    com_commuter: formData.commuterEmail ? 1 : 0,
    com_staff: formData.StaffEmail ? 1 : 0,
    com_squad: formData.SquadEmail ? 1 : 0,
    com_finance: formData.financeEmail ? 1 : 0
  };

  const handleEmailSend = async () => {
    setIsSending(true);
    try {
      await CommunicationUtils.SendEmails(sessionUser, {
        subject: formData.emailSubject,
        body: formData.emailBody,
        recipients: filteredEmails,
        selectedRoles: selectedRoles
      });
      toast.success('Email sent successfully');

    } catch (error) {
      toast.error('Error sending email' + error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="">
      <h1 className="modalHeading">Send Email</h1>

      <div className="flex flex-col items-center justify-around p-2 h-[60%] w-[90%] borderThemed rounded-xl">

        <div className="flex flex-col">
          <input
            className='h-[50px] m-2 w-[500px] rounded-xl p-2 borderThemed'
            id="emailSubject"
            placeholder="Email Subject"
            name="emailSubject"
            value={formData.emailSubject}
            onChange={handleChange}
          />
          <textarea
            className='h-[200px] m-2 w-[500px] rounded-xl p-2 borderThemed'
            id="emailBody"
            placeholder="Email Body"
            name="emailBody"
            value={formData.emailBody}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col w-[64%] items-center m-2 borderThemed rounded-lg">
          <div className="flex flex-row justify-between items-center w-[500px]">
            <div className="flex flex-col items-center">
              <label htmlFor="ownerEmail" className="text-sm font-semibold">Owners</label>
              <input
                checked={formData.ownerEmail}
                onChange={handleChange}
                type="checkbox"
                id="ownerEmail"
                name="ownerEmail"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            {/* <div className="flex flex-col items-center">
                <label htmlFor="financeEmail" className="text-sm font-semibold">Finance</label>
                <input
                  checked={formData.financeEmail}
                  onChange={handleChange}
                  type="checkbox"
                  id="financeEmail"
                  name="financeEmail"
                  className="mr-2 h-[25px] w-[25px]"
                />
              </div> */}
            <div className="flex flex-col items-center">
              <label htmlFor="StaffEmail" className="text-md font-semibold">Staff</label>
              <input
                checked={formData.StaffEmail}
                onChange={handleChange}
                type="checkbox"
                id="StaffEmail"
                name="StaffEmail"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="driverEmail" className="text-md font-semibold">Drivers</label>
              <input
                checked={formData.driverEmail}
                onChange={handleChange}
                type="checkbox"
                id="driverEmail"
                name="driverEmail"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="marshalEmail" className="text-md font-semibold">Marshals</label>
              <input
                checked={formData.marshalEmail}
                onChange={handleChange}
                type="checkbox"
                id="marshalEmail"
                name="marshalEmail"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="SquadEmail" className="text-md font-semibold">Squads</label>
              <input
                checked={formData.SquadEmail}
                onChange={handleChange}
                type="checkbox"
                id="SquadEmail"
                name="SquadEmail"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="commuterEmail" className="text-md font-semibold">Commuters</label>
              <input
                checked={formData.commuterEmail}
                onChange={handleChange}
                type="checkbox"
                id="commuterEmail"
                name="commuterEmail"
                className="mr-2 h-[25px] w-[25px]"
              />
            </div>
          </div>
          <div className="flex">
            <div className="pageBtnBlue" onClick={handleScheduleComModal}>Schedule email</div>
            <button className="pageBtngood" onClick={handleEmailSend} disabled={isSending}>
              {isSending ? 'Sending...' : 'Send Email'}
            </button>

          </div>

        </div>


      </div>
      {scheduleCom && sessionUser && (
        <div className="modalBackground">
          <ScheduleComModal
            onClose={handleScheduleComModal}
            type="Email"
            com={formData}
            body={formData.emailBody}
            recipient={filteredEmails}
            sender={sessionUser.id}
            title={formData.emailSubject}
            selectedRoles={selectedRoles}
          />
        </div>
      )}
    </div>
  );
};

export default EmailComms;
